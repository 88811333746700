import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../utils/authProvider";
import { Navigate, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Dropdown,
  Accordion,
  Button,
  Offcanvas,
  ListGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuIcon from "@mui/icons-material/Menu";
import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import "./topNavStyle.css";

const TopNav = () => {
  const { logout, getAuth } = useAuthContext();
  const navigate = useNavigate();

  const [loggedIn, setLoggedIn] = useState(false);
  const [timer, setTimer] = useState(null);
  const [show, setShow] = useState(false);

  const resetTimer = () => {
    clearTimeout(timer); // Clear the existing timer
    const newTimer = setTimeout(checkToken, 1 * 60 * 1000); // Set a new timer
    setTimer(newTimer); // Update the timer state
  };
  const checkToken = async () => {
    const auth = await getAuth();
    // console.log("checking token topnav");
    if (auth !== false) {
      // console.log("topnav true");
      setLoggedIn(true);
      resetTimer(); // Reset the timer if the token is valid
    } else {
      // Handle token expiration
      // console.log("topnav false");
      setLoggedIn(false);
      resetTimer();
    }
  };
  useEffect(() => {
    checkToken(); // Initial token check when the component mounts
    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);
  // useEffect(() => {
  //   const auth = getAuth();
  //   if (auth !== false) {
  //     console.log("top nav true");
  //     setLoggedIn(true);
  //   }
  //   console.log("top nav false");
  //   setLoggedIn(false);
  // }, []);
  // useEffect(() => {
  //   const auth = getAuth();
  //   if (auth) {
  //     setLoggedIn(true);
  //   }
  // }, [getAuth]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const logoutButton = () => {
    setLoggedIn(false);
    logout();
  };
  // State to track whether the submenu should be shown
  // const [showDataReviewSubMenu, setDataReviewSubMenu] = useState("hidden");
  // Handler to toggle the submenu visibility
  // const handleMouseEnter = () => {
  //   setDataReviewSubMenu("visible");
  // };
  // const handleMouseLeave = () => {
  //   setDataReviewSubMenu("hidden");
  // };
  const handleGoToMeeting = () => {
    const gotoMeetingLink = `https://www.goto.com/meeting/join`;
    window.open(gotoMeetingLink, "_blank");
  };
  return (
    <>
      {loggedIn ? (
        <Row id="top-nav-row">
          <Col id="left-column">
            <Row style={{ justifyContent: "start" }}>
              <Button
                variant="primary"
                onClick={handleShow}
                style={{
                  width: "135px",
                  marginTop: "10px",
                  marginLeft: "10px",
                  zIndex: "1",
                }}
              >
                <MenuIcon
                  style={{
                    width: "25px",
                    height: "25px",
                    marginRight: "10px",
                    marginBottom: "1px",
                  }}
                />
                <span>Menu</span>
              </Button>
              <Offcanvas
                id="offCanvas-topNav"
                show={show}
                onHide={handleClose}
                style={{ width: "400px" }}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body style={{ padding: "0px" }}>
                  <ListGroup variant="flush">
                    <ListGroup.Item
                      action
                      onClick={() => {
                        navigate("/home");
                        handleClose();
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <HomeIcon
                          style={{
                            width: "35px",
                            height: "35px",
                            paddingBottom: "5px",
                          }}
                        />
                        <h5 id="menu-header">Home</h5>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Accordion defaultActiveKey="0" alwaysOpen flush>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <DataThresholdingIcon
                                style={{
                                  width: "25px",
                                  height: "25px",
                                }}
                              />
                              <h5 id="menu-header">Data Review</h5>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <ListGroup variant="flush">
                              <ListGroup.Item
                                action
                                onClick={() => {
                                  navigate("/dataView");
                                  handleClose();
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <FiberManualRecordIcon id="menu-subheader-dot" />
                                  <h5 id="menu-subheader">Data View</h5>
                                </div>
                              </ListGroup.Item>
                              {/* <ListGroup.Item
                                action
                                onClick={() => {
                                  navigate("/stackedView");
                                  handleClose();
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <FiberManualRecordIcon id="menu-subheader-dot" />
                                  <h5 id="menu-subheader">Stacked View</h5>
                                </div>
                              </ListGroup.Item> */}
                              <ListGroup.Item
                                action
                                onClick={() => {
                                  navigate("/drillDown");
                                  handleClose();
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <FiberManualRecordIcon id="menu-subheader-dot" />
                                  <h5 id="menu-subheader">Drill Down</h5>
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item
                                action
                                onClick={() => {
                                  navigate("/drReports");
                                  handleClose();
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <FiberManualRecordIcon id="menu-subheader-dot" />
                                  <h5 id="menu-subheader">Reports</h5>
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item
                                action
                                onClick={() => {
                                  navigate("/groupMaintenance");
                                  handleClose();
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <FiberManualRecordIcon id="menu-subheader-dot" />
                                  <h5 id="menu-subheader">Group Maintenance</h5>
                                </div>
                              </ListGroup.Item>
                            </ListGroup>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </ListGroup.Item>
                    {/* <ListGroup.Item
                      action
                      onClick={() => {
                        navigate("/meterCal");
                        handleClose();
                      }}
                    >
                      <h4>Meter Calibration</h4>
                    </ListGroup.Item>
                    <ListGroup.Item
                      action
                      onClick={() => {
                        navigate("/testForm");
                        handleClose();
                      }}
                    >
                      <h4>Test MC</h4>
                    </ListGroup.Item>
                    <ListGroup.Item
                      action
                      onClick={() => {
                        navigate("/testFree");
                        handleClose();
                      }}
                    >
                      <h4>Test Free</h4>
                    </ListGroup.Item> */}
                  </ListGroup>
                </Offcanvas.Body>
              </Offcanvas>
            </Row>
          </Col>
          <Col id="middle-column">
            <Row style={{ justifyContent: "center" }}>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="tooltip-disabled">Home</Tooltip>}
              >
                <Button
                  variant="link"
                  onClick={() => {
                    console.log("clicked");
                  }}
                >
                  <img
                    src="PP_logo_192.png"
                    alt="Prams Logo"
                    style={{
                      width: "auto",
                      height: "50px",
                      pointerEvents: "none",
                    }}
                  />
                </Button>
              </OverlayTrigger>
            </Row>
          </Col>
          <Col id="right-column">
            <Row style={{ justifyContent: "end" }}>
              <Col xs="auto">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="primary"
                    id="dropdown-basic"
                    style={{ marginTop: "10px" }}
                  >
                    <SettingsIcon
                      style={{ width: "25px", height: "25px", color: "white" }}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>Actions</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item>
                      <Button
                        variant="primary"
                        onClick={handleGoToMeeting}
                        style={{ width: "130px" }}
                      >
                        GoTo Meeting
                      </Button>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item eventKey="4">
                      <Button
                        variant="primary"
                        onClick={logoutButton}
                        style={{ width: "130px" }}
                      >
                        Logout
                      </Button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};

export default TopNav;
