import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuthContext } from "../utils/authProvider";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  ButtonGroup,
  Dropdown,
  Table,
  InputGroup,
  Form,
  ListGroup,
  Alert,
} from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import StaticSiteSearchToolGroupMain from "../components/GroupMaintenance/utils/StaticSiteSearchToolGroupMain";
import { hierarchyOptions } from "../utils/initHierarchyOptions";
import { fieldOptions } from "../utils/initDynamicOptions";
import DynamicApiCalls from "../components/Cards/Card_Types/dynamicApiCalls";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";
import "../components/GroupMaintenance/groupMainStyle.css";
import TopNav from "../components/navbars/Top_NavBar/topNav";

const GroupMaintenance = () => {
  const { getAuth, refreshCheck } = useAuthContext();
  const navigate = useNavigate();

  // const [initHierarchyOptions, setInitHierarchyOptions] = useState([]);
  const [userId, setUserId] = useState();
  const [groups, setGroups] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [groupsLoaded, setGroupsLoaded] = useState(false);
  const [otherUserGroups, setOtherUserGroups] = useState([]);
  const [hiddenGroups, setHiddenGroups] = useState([]);
  const [hiddenGroupsLoaded, setHiddenGroupsLoaded] = useState(false);

  const [selectedGroupType, setSelectedGroupType] = useState("none");
  const typeMapping = {
    D: "Dynamic",
    W: "Static",
    H: "Hierarchy",
  };
  const [switches, setSwitches] = useState({
    selectedShared: false,
    hiddenSwitch: false,
  });
  const [groupName, setGroupName] = useState("");
  const [groupId, setGroupId] = useState();
  const [hierarchyCombiOptions, setHierarchyCombiOptions] = useState([]);
  const [xref, setXref] = useState([]);
  const [editGroupName, setEditGroupName] = useState("");
  const [selectedGroupTypeDropdown, setSelectedGroupTypeDropdown] =
    useState("");
  const [siteIds, setSiteIds] = useState([]);

  const groupShareMapping = {
    true: "Yes",
    false: "No",
  };
  const [rows, setRows] = useState([
    {
      fieldText: "",
      fieldValue: "",
      comparison: "",
      optionValue: "",
      optionText: "",
    },
  ]);
  const [dynamicRows, setDynamicRows] = useState([]);
  const [sendRows, setSendRows] = useState([
    {
      fieldValue: "",
      comparison: "",
      optionValue: "",
    },
  ]);
  const [valueOptions, setValueOptions] = useState({});
  const [dynamicCombiOptions, setDynamicCombiOptions] = useState([
    { value: "Loading", key: "Loading" },
  ]);
  const comparisonOptions = ["=", "<>"];
  const [dynViewSiteList, setDynViewSiteList] = useState(false);
  const [dynSiteCount, setDynSiteCount] = useState(0);
  const [urOrSn, setUrOrSn] = useState("userRef");
  const [dynSites, setDynSites] = useState([]);
  const [dynSiteIds, setDynSiteIds] = useState([]);
  const [filteredDynamicOptions, setFilteredDynamicOptions] = useState([]);
  const [siteIdsStaticGroup, setSiteIdsStaticGroup] = useState([]);
  const [siteIdsToSend, setSiteIdsToSend] = useState([]);
  const [getGroupInfoLoading, setGetGroupInfoLoading] = useState(false);
  const [showSavedAlert, setShowSavedAlert] = useState(false);
  const [showDeletedAlert, setShowDeletedAlert] = useState(false);
  const [hierarchyFieldOptionsMain, setHierarchyFieldOptionsMain] = useState(
    []
  );
  const [viewOtherUser, setViewOtherUser] = useState(false);
  //* Main UEs ---------------------------------------------
  useEffect(() => {
    // setInitHierarchyOptions(hierarchyOptions);
    setUserId(localStorage.getItem("userId"));
    getGroups();
  }, []);
  useEffect(() => {
    console.log("groups loaded", groupsLoaded);
    if (groupsLoaded === true) {
      return;
    } else if (groupsLoaded === false) {
      getGroups();
    }
  }, [groupsLoaded]);
  useEffect(() => {
    if (groups.length > 0) {
      // console.log("user ID groups UE", userId);
      setUserGroups(groups.filter((group) => group.ownerId == userId));
      setOtherUserGroups(groups.filter((group) => group.ownerId != userId));
    }
    return;
  }, [groups]);
  useEffect(() => {
    console.log("userGroups", userGroups);
    getHiddenGroups();
  }, [userGroups]);
  useEffect(() => {
    console.log("otherUserGroups", otherUserGroups);
  }, [otherUserGroups]);
  useEffect(() => {
    console.log("hiddenGroups", hiddenGroups);
  }, [hiddenGroups]);
  useEffect(() => {
    if (groupId === null) {
      return;
    }
    getGroupInfo();
  }, [groupId]);
  useEffect(() => {
    if (showSavedAlert) {
      setTimeout(() => {
        setShowSavedAlert(false);
      }, 3000);
    }
    return;
  }, [showSavedAlert]);
  useEffect(() => {
    setTimeout(() => {
      setShowDeletedAlert(false);
    }, 3000);
  }, [showDeletedAlert]);
  useEffect(() => {
    console.log("switches", switches);
  }, [switches]);
  //* Dynamic UEs ---------------------------------------------
  useEffect(() => {
    console.log("rows", rows);
    const rowsToSend = rows.map((row) => {
      return {
        field: row.fieldId,
        operator: row.comparison,
        optionValue: row.optionValue,
      };
    });
    setSendRows(rowsToSend);
  }, [rows]);
  useEffect(() => {
    // console.log("dynamicCombiOptions", dynamicCombiOptions);
    const xrefUpdated = filteredDynamicOptions.map((option) => {
      const xrefItem = dynamicCombiOptions.find(
        (item) => item.key === option.fieldId
      );
      if (xrefItem) {
        return {
          ...option,
          fieldText: xrefItem.value,
          fieldId: xrefItem.key,
        };
      }
      return option;
    });
    const userDynamicOptionsSet = xrefUpdated.map((option) => {
      return {
        fieldId: option.fieldId,
        fieldText: option.fieldText,
        comparison: option.operator,
        optionValue: option.value,
        optionText: option.text,
      };
    });
    setRows(userDynamicOptionsSet);
  }, [dynamicCombiOptions]);
  useEffect(() => {
    // console.log("dynamicRows", dynamicRows);
    if (dynamicRows === undefined) {
      return;
    } else if (dynamicRows.length === 0) {
      return;
    } else {
      const rowsFromResponse = dynamicRows.map((item) => {
        return {
          fieldId: item.field,
          comparison: item.comparison,
          optionValue: item.value,
          optionText: item.value,
        };
      });
      setRows(rowsFromResponse);
    }
  }, [dynamicRows]);
  //* Hierarchy UEs ---------------------------------------------
  useEffect(() => {
    // console.log("hierarchyOptions", hierarchyOptions);
    if (hierarchyOptions.length > 0) {
      getXref();
    }
  }, [hierarchyOptions]);
  //* Main Functions ---------------------------------------------
  const getGroups = async (props) => {
    if (props === "reload") {
      console.log("reloading groups");
      setGroupsLoaded(false);
      return;
    }
    // if (groupsLoaded) {
    //   console.log("object already loaded");
    //   return;
    // }
    const authT = await getAuth();
    try {
      const res = await axios.get("https://api.pramsplus.com/groups", {
        headers: { Authorization: `Bearer ${authT}` },
      });
      setGroups(res.data);
      setGroupsLoaded(true);
    } catch (err) {
      console.log(err.response.status);
      if (err.response.status === 401) {
        const valid = await refreshCheck("getGroups");
        if (valid === true) {
          console.log("Retrying getGroups api call");
          getGroups();
        } else if (valid === false) {
          console.log("all retries failed getGroups");
          navigate("/login");
        }
      } else {
        console.log("Redirecting to login something went wrong in getGroups");
        navigate("/login");
      }
      return;
    }
  };
  const getHiddenGroups = async () => {
    if (hiddenGroupsLoaded) {
      return;
    }
    const authT = await getAuth();
    if (authT) {
      try {
        setHiddenGroupsLoaded(false);
        const res = await axios.get(
          "https://api.pramsplus.com/groups-visibility",
          {
            headers: { Authorization: `Bearer ${authT}` },
          }
        );
        // console.log("groups-visibility api call success");
        setHiddenGroups(res.data);
        console.log("hidden groups", res.data);
        setHiddenGroupsLoaded(true);
        return res.data;
      } catch (err) {
        console.log(err.response.status);
        if (err.response.status === 401) {
          const valid = await refreshCheck("getHiddenGroups");
          if (valid === true) {
            console.log("Retrying getHiddenGroups api call");
            getHiddenGroups();
          } else if (valid === false) {
            console.log("all retries failed getHiddenGroups");
            navigate("/login");
          }
        } else {
          console.log(
            "Redirecting to login something went wrong in getHiddenGroups"
          );
          navigate("/login");
        }
        return;
      }
    }
  };
  const handleGroupClick = ({
    groupId,
    name,
    type,
    share,
    isOtherUser,
    hidden,
  }) => {
    console.log("group_id", groupId);
    console.log("name", name);
    console.log("type", type);
    console.log("share", share);
    console.log("isOtherUser", isOtherUser);
    console.log("hidden", hidden);
    if (isOtherUser === true) {
      console.log("hgc other user true");
      setViewOtherUser(true);
      setSelectedGroupType(typeMapping[type]);
      setSwitches((prevSwitches) => ({
        ...prevSwitches,
        hiddenSwitch: hidden,
      }));
      setGroupName(name);
      setGroupId(groupId);
      return;
    } else {
      console.log("hgc other user false");
      setViewOtherUser(false);
      setSelectedGroupType(typeMapping[type]);
      setSwitches((prevSwitches) => ({
        ...prevSwitches,
        selectedShared: share,
      }));
      setGroupName(name);
      setGroupId(groupId);
      return;
    }
  };
  const editGroup = (selectedType) => {
    // console.log("editGroup selectedType", selectedType);
    // console.log("editGroup groupId", viewOtherUser);
    if (viewOtherUser === true) {
      // console.log("viewOtherUser true");
      if (selectedType === "none") {
        return null;
      }
      return <GroupShared />;
    } else if (viewOtherUser === false) {
      // console.log("viewOtherUser false");
      if (selectedType === "none") {
        return null;
      }
      if (selectedType === "Static") {
        if (getGroupInfoLoading) {
          return <h5>Loading...</h5>;
        }
        return <StaticGroup />;
      }
      if (selectedType === "Dynamic") {
        if (getGroupInfoLoading) {
          return <h5>Loading...</h5>;
        }
        return <DynamicGroup />;
      }
      if (selectedType === "Hierarchy") {
        if (getGroupInfoLoading) {
          return <h5>Loading...</h5>;
        }
        return <HierarchyGroup />;
      }
    }
  };
  const getGroupInfo = async () => {
    // console.log("getGroupInfo groupId", groupId);
    // console.log("getGroupInfo selectedGroupType", selectedGroupType);
    setGetGroupInfoLoading(true);
    if (selectedGroupType === "") {
      console.log("selectedGroupType is empty");
      return;
    }
    if (selectedGroupType === "Static") {
      console.log("selectedGroupType is static");
      const authT = await getAuth();
      if (authT === null) {
        return;
      }
      const options = {
        method: "GET",
        url: `https://api.pramsplus.com/insight-site-groups/${groupId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authT}`,
        },
      };
      console.log("options s", options);
      try {
        const response = await axios.request(options);
        console.log("response", response.data);
        setSiteIdsStaticGroup(response.data);
        setGetGroupInfoLoading(false);
      } catch (err) {
        console.log(err.response.status);
        if (err.response.status === 404) {
          setSiteIdsStaticGroup([]);
          setGetGroupInfoLoading(false);
          return;
        }
        if (err.response.status === 401) {
          const valid = await refreshCheck("getGroupInfo");
          if (valid === true) {
            console.log("Retrying getGroupInfo api call");
            getGroupInfo();
          } else if (valid === false) {
            console.log("all retries failed getGroupInfo");
            navigate("/login");
          }
        } else {
          console.log(
            "Redirecting to login something went wrong in getGroupInfo"
          );
          navigate("/login");
        }
        return;
      }
    }
    if (selectedGroupType === "Dynamic") {
      console.log("selectedGroupType is dynamic");
      const authT = await getAuth();
      const options = {
        method: "GET",
        url: `https://api.pramsplus.com/insight-dynamic-groups/${groupId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authT}`,
        },
      };
      console.log("options D", options);
      try {
        const response = await axios.request(options);
        // console.log("response from get group Info", response.data);
        setDynamicRows(response.data);
        setGetGroupInfoLoading(false);
      } catch (err) {
        console.log(err.response.status);
        if (err.response.status === 404) {
          setGetGroupInfoLoading(false);
          return;
        }
        if (err.response.status === 401) {
          const valid = await refreshCheck("getGroupInfo");
          if (valid === true) {
            console.log("Retrying getGroupInfo api call");
            getGroupInfo();
          } else if (valid === false) {
            console.log("all retries failed getGroupInfo");
            navigate("/login");
          }
        } else {
          console.log(
            "Redirecting to login something went wrong in getGroupInfo"
          );
          navigate("/login");
        }
        return;
      }
    }
    if (selectedGroupType === "Hierarchy") {
      console.log("selectedGroupType is Hierarchy");
      const authT = await getAuth();
      const options = {
        method: "GET",
        url: `https://api.pramsplus.com/insight-hierarchy-groups/${groupId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authT}`,
        },
      };
      console.log("options H", options);
      try {
        const response = await axios.request(options);
        // console.log("response", response.data);
        setHierarchyFieldOptionsMain(response.data);
        setGetGroupInfoLoading(false);
      } catch (err) {
        console.log(err.response.status);
        if (err.response.status === 404) {
          setGetGroupInfoLoading(false);
          return;
        }
        if (err.response.status === 401) {
          const valid = await refreshCheck("getGroupInfo");
          if (valid === true) {
            console.log("Retrying getGroupInfo api call");
            getGroupInfo();
          } else if (valid === false) {
            console.log("all retries failed getGroupInfo");
            navigate("/login");
          }
        } else {
          console.log(
            "Redirecting to login something went wrong in getGroupInfo"
          );
          navigate("/login");
        }
        return;
      }
    }
  };
  const deleteGroup = async () => {
    console.log("delete group id", groupId);
    const auth = await getAuth();
    const options = {
      method: "DELETE",
      url: `https://api.pramsplus.com/groups/${groupId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    console.log("object to delete", options);
    try {
      const response = await axios.request(options);
      console.log("delete group response", response.data);
      setShowDeletedAlert(true);
      setGroupsLoaded(false);
      setSelectedGroupType("none");
      setSelectedGroupTypeDropdown("");
    } catch (err) {
      console.log(err.response.status);
      if (err.response.status === 401) {
        const valid = await refreshCheck("deleteGroup");
        if (valid === true) {
          console.log("Retrying deleteGroup api call");
          deleteGroup();
        } else if (valid === false) {
          console.log("all retries failed deleteGroup");
          navigate("/login");
        }
      } else {
        console.log("Redirecting to login something went wrong in deleteGroup");
        navigate("/login");
      }
      return;
    }
  };
  const cancelGroup = () => {
    setGroupName("");
    setSelectedGroupType("none");
    setViewOtherUser(false);
  };
  const handleNewGroup = () => {
    if (selectedGroupTypeDropdown === "") {
      return;
    }
    if (selectedGroupTypeDropdown === "Static") {
      setSelectedGroupType("Static");
      const newStaticGroupInfo = {
        type: "W",
        name: "New Static Group",
        shared: false,
      };
      createNewGroup(newStaticGroupInfo);
    }
    if (selectedGroupTypeDropdown === "Dynamic") {
      setSelectedGroupType("Dynamic");
      const newDynamicGroupInfo = {
        type: "D",
        name: "New Dynamic Group",
        shared: false,
      };
      createNewGroup(newDynamicGroupInfo);
    }
    if (selectedGroupTypeDropdown === "Hierarchy") {
      setSelectedGroupType("Hierarchy");
      const newHierarchyGroupInfo = {
        type: "H",
        name: "New Hierarchy Group",
        shared: false,
      };
      createNewGroup(newHierarchyGroupInfo);
    }
  };
  const createNewGroup = async (newGroupInfo) => {
    const auth = await getAuth();
    const options = {
      method: "POST",
      url: "https://api.pramsplus.com/groups",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
      data: newGroupInfo,
    };
    try {
      const response = await axios.request(options);
      // console.log("createNewGroup response", response.data);
      setGroupId(response.data.id);
      setGroupName(response.data.name);
      setSelectedGroupTypeDropdown("");
      setGroupsLoaded(false);
      getGroups();
    } catch (err) {
      console.log(err.response.status);
      if (err.response.status === 401) {
        const valid = await refreshCheck("createNewGroup");
        if (valid === true) {
          console.log("Retrying createNewGroup api call");
          createNewGroup();
        } else if (valid === false) {
          console.log("all retries failed createNewGroup");
          navigate("/login");
        }
      } else {
        console.log(
          "Redirecting to login something went wrong in createNewGroup"
        );
        navigate("/login");
      }
      return;
    }
  };
  const ShowAlert = () => {
    if (showSavedAlert) {
      return (
        <Alert key={"success"} variant="success" dismissible>
          Saved Group
        </Alert>
      );
    } else {
      return null;
    }
  };
  const ShowAlertDeleted = () => {
    if (showDeletedAlert) {
      return (
        <Alert key={"danger"} variant="danger" dismissible>
          Deleted Group
        </Alert>
      );
    } else {
      return null;
    }
  };
  // * Other User Group ---------------------------------------------
  const GroupShared = () => {
    const [otherSwitches, setOtherSwitches] = useState({
      hiddenSwitch: false,
    });
    useEffect(() => {
      console.log("switches in ue other", switches);
      setOtherSwitches((prevSwitches) => ({
        ...prevSwitches,
        hiddenSwitch: switches.hiddenSwitch,
      }));
    }, []);
    const handleOtherSwitchChange = () => {
      setOtherSwitches((prevSwitches) => ({
        ...prevSwitches,
        hiddenSwitch: !prevSwitches.hiddenSwitch,
      }));
    };
    const saveOtherData = async () => {
      const auth = await getAuth();
      const dataPrep = {
        groupId: groupId,
        hidden: otherSwitches.hiddenSwitch,
      };
      if (otherSwitches.hiddenSwitch === true) {
        // console.log("switch hidden", otherSwitches.hiddenSwitch);
        const options = {
          method: "POST",
          url: `https://api.pramsplus.com/groups-visibility/hide/${groupId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
          },
          data: dataPrep,
        };
        // console.log("save Other data prep", dataPrep);
        try {
          const response = await axios.request(options);
          // console.log("saveOtherData response", response.data);
          setGroupsLoaded(false);
          // getGroups();
          setGroupId(null);
          setSelectedGroupType("none");
          setEditGroupName("");
          setSwitches((prevSwitches) => ({
            ...prevSwitches,
            hiddenSwitch: false,
          }));
          setViewOtherUser(false);
          setShowSavedAlert(true);
        } catch (err) {
          console.log(err.response.status);
          if (err.response.status === 401) {
            const valid = await refreshCheck("saveOtherData");
            if (valid === true) {
              console.log("Retrying saveOtherData api call");
              saveOtherData();
            } else if (valid === false) {
              console.log("all retries failed saveOtherData");
              navigate("/login");
            }
          } else {
            console.log(
              "Redirecting to login something went wrong in saveOtherData"
            );
            navigate("/login");
          }
          return;
        }
      } else {
        // console.log("switch not hidden", otherSwitches.hiddenSwitch);
        const options = {
          method: "POST",
          url: `https://api.pramsplus.com/groups-visibility/unhide/${groupId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
          },
          data: dataPrep,
        };
        console.log("save Other data prep", dataPrep);
        try {
          const response = await axios.request(options);
          console.log("saveOtherData response", response.data);
          setGroupsLoaded(false);
          setGroupId(null);
          setSelectedGroupType("none");
          setEditGroupName("");
          setOtherSwitches((prevSwitches) => ({
            ...prevSwitches,
            hiddenSwitch: false,
          }));
          setShowSavedAlert(true);
          setViewOtherUser(false);
        } catch (err) {
          console.log(err.response.status);
          if (err.response.status === 401) {
            const valid = await refreshCheck("saveOtherData");
            if (valid === true) {
              console.log("Retrying saveOtherData api call");
              saveOtherData();
            } else if (valid === false) {
              console.log("all retries failed saveOtherData");
              navigate("/login");
            }
          } else {
            console.log(
              "Redirecting to login something went wrong in saveOtherData"
            );
            navigate("/login");
          }
          return;
        }
      }
    };
    return (
      <Row id="switch_table">
        <Col>
          <Form>
            <Form.Check
              type="switch"
              id="custom-switch"
              checked={otherSwitches.hiddenSwitch}
              onChange={() => handleOtherSwitchChange()}
              label="Hide"
            />
          </Form>
        </Col>
        <Col>
          <ButtonGroup>
            <Button variant="outline-primary" onClick={() => saveOtherData()}>
              <SaveIcon />
              Save
            </Button>
            <Button variant="outline-success" onClick={() => cancelGroup()}>
              <CancelIcon />
              Cancel
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    );
  };
  // * Static Group ---------------------------------------------
  const saveSitesTree = (sites) => {
    saveStaticGroup(sites);
  };
  const saveStaticGroup = async (sites) => {
    const dataPrep = {
      groupId: groupId,
      siteIds: sites,
    };
    const auth = await getAuth();
    const options = {
      method: "POST",
      url: `https://api.pramsplus.com/insight-site-groups`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
      data: dataPrep,
    };
    console.log("data prep for save static group", dataPrep);
    try {
      const response = await axios.request(options);
      console.log("saveStaticGroup response", response.data);
      setGroupsLoaded(false);
      setGroupId(null);
      setSelectedGroupType("none");
      setEditGroupName("");
      setShowSavedAlert(true);
      setSiteIdsStaticGroup([]);
    } catch (err) {
      console.log(err.response.status);
      if (err.response.status === 401) {
        const valid = await refreshCheck("saveStaticGroup");
        if (valid === true) {
          console.log("Retrying saveStaticGroup api call");
          saveStaticGroup();
        } else if (valid === false) {
          console.log("all retries failed saveStaticGroup");
          navigate("/login");
        }
      } else {
        console.log(
          "Redirecting to login something went wrong in saveStaticGroup"
        );
        navigate("/login");
      }
      return;
    }
  };
  const StaticGroup = () => {
    const [editStaticGroupName, setStaticEditGroupName] = useState("");
    const [staticSwitches, setStaticSwitches] = useState({
      selectedShared: false,
    });
    useEffect(() => {
      setStaticSwitches((prevSwitches) => ({
        ...prevSwitches,
        selectedShared: switches.selectedShared,
      }));
    }, []);
    const handleStaticGroupNameChange = (event) => {
      setStaticEditGroupName(event.target.value);
    };
    const saveShareStatic = async () => {
      const auth = await getAuth();
      const dataPrep = {
        groupId: groupId,
        shared: staticSwitches.selectedShared,
      };
      const options = {
        method: "PATCH",
        url: `https://api.pramsplus.com/groups/${groupId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
        data: dataPrep,
      };
      console.log("save Share Static data prep", dataPrep);
      try {
        const response = await axios.request(options);
        console.log("saveShareStatic response", response.data);
        setGroupsLoaded(false);
        setGroupId(null);
        setSelectedGroupType("none");
        setEditGroupName("");
        setStaticEditGroupName("");
        setStaticSwitches((prevSwitches) => ({
          ...prevSwitches,
          selectedShared: false,
        }));
      } catch (err) {
        console.log(err.response.status);
        if (err.response.status === 401) {
          const valid = await refreshCheck("saveShareStatic");
          if (valid === true) {
            console.log("Retrying saveShareStatic api call");
            saveShareStatic();
          } else if (valid === false) {
            console.log("all retries failed saveShareStatic");
            navigate("/login");
          }
        } else {
          console.log(
            "Redirecting to login something went wrong in saveShareStatic"
          );
          navigate("/login");
        }
        return;
      }
    };
    const updateStaticGroupName = async () => {
      if (editStaticGroupName === "") {
        console.log("empty group name");
        return;
      }
      const auth = await getAuth();
      const dataPrep = {
        name: editStaticGroupName,
      };
      const options = {
        method: "PATCH",
        url: `https://api.pramsplus.com/groups/${groupId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
        data: dataPrep,
      };
      console.log("data prep group name", dataPrep);
      try {
        const response = await axios.request(options);
        console.log("updateGroupName response", response.data);
        setGroupsLoaded(false);
        setSelectedGroupType("none");
        setEditGroupName("");
        setStaticEditGroupName("");
      } catch (err) {
        console.log(err.response.status);
        if (err.response.status === 401) {
          const valid = await refreshCheck("updateStaticGroupName");
          if (valid === true) {
            console.log("Retrying updateStaticGroupName api call");
            updateStaticGroupName();
          } else if (valid === false) {
            console.log("all retries failed updateStaticGroupName");
            navigate("/login");
          }
        } else {
          console.log(
            "Redirecting to login something went wrong in updateStaticGroupName"
          );
          navigate("/login");
        }
        return;
      }
    };
    const handleStaticSwitchChange = () => {
      // setSwitches.selectedShared(!switches.selectedShared);
      setStaticSwitches((prevSwitches) => ({
        ...prevSwitches,
        selectedShared: !prevSwitches.selectedShared,
      }));
      // console.log("switched", switches.selectedShared);
    };
    return (
      <>
        <Row>
          <Col xs={7}>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">Group Name:</InputGroup.Text>
              <Form.Control
                placeholder="Ex. K22 Pad"
                aria-label="group_name"
                aria-describedby="basic-addon1"
                value={editStaticGroupName}
                onChange={handleStaticGroupNameChange}
              />
            </InputGroup>
          </Col>
          <Col xs={2}>
            <InputGroup className="mb-3">
              <Form.Check
                type="switch"
                id="custom-switch"
                checked={staticSwitches.selectedShared}
                onChange={() => handleStaticSwitchChange()}
                label="Share"
              />
            </InputGroup>
          </Col>
          <Col>
            <ButtonGroup>
              <Button variant="outline-success" onClick={() => cancelGroup()}>
                <CancelIcon />
                Cancel
              </Button>
              <Button variant="outline-danger" onClick={() => deleteGroup()}>
                <DeleteIcon />
                Delete
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <Row id="Static-Search-Row-GM">
          <StaticSiteSearchToolGroupMain
            saveSitesTree={saveSitesTree}
            siteIdsOptions={siteIdsStaticGroup}
            saveStaticGroupName={updateStaticGroupName}
            saveShare={saveShareStatic}
          />
        </Row>
      </>
    );
  };
  // * Dynamic Group ---------------------------------------------
  const DynamicGroup = () => {
    const [editDynamicGroupName, setDynamicEditGroupName] = useState("");
    const [dynamicSwitches, setDynamicSwitches] = useState({
      selectedShared: false,
    });
    useEffect(() => {
      setDynamicSwitches((prevSwitches) => ({
        ...prevSwitches,
        selectedShared: switches.selectedShared,
      }));
    }, []);
    const handleDynamicGroupNameChange = (event) => {
      setDynamicEditGroupName(event.target.value);
    };
    const saveDynamicData = () => {
      saveGroup("Dynamic");
      saveDynamicGroupName();
      saveShareDynamic();
    };
    const saveShareDynamic = async () => {
      const auth = await getAuth();
      const dataPrep = {
        groupId: groupId,
        shared: dynamicSwitches.selectedShared,
      };
      const options = {
        method: "PATCH",
        url: `https://api.pramsplus.com/groups/${groupId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
        data: dataPrep,
      };
      console.log("save Share Static data prep", dataPrep);
      try {
        const response = await axios.request(options);
        console.log("saveShareStatic response", response.data);
        setGroupsLoaded(false);
        setGroupId(null);
        setSelectedGroupType("none");
        setEditGroupName("");
        setDynamicEditGroupName("");
        setDynamicSwitches((prevSwitches) => ({
          ...prevSwitches,
          selectedShared: false,
        }));
      } catch (err) {
        console.log(err.response.status);
        if (err.response.status === 401) {
          const valid = await refreshCheck("saveShareDynamic");
          if (valid === true) {
            console.log("Retrying saveShareDynamic api call");
            saveShareDynamic();
          } else if (valid === false) {
            console.log("all retries failed saveShareDynamic");
            navigate("/login");
          }
        } else {
          console.log(
            "Redirecting to login something went wrong in saveShareDynamic"
          );
          navigate("/login");
        }
        return;
      }
    };
    const handleDynamicSwitchChange = () => {
      // setSwitches.selectedShared(!switches.selectedShared);
      setDynamicSwitches((prevSwitches) => ({
        ...prevSwitches,
        selectedShared: !prevSwitches.selectedShared,
      }));
      // console.log("switched", switches.selectedShared);
    };
    const saveDynamicGroupName = async () => {
      if (editDynamicGroupName === "") {
        // console.log("empty group name");
        return;
      }
      const auth = await getAuth();
      const dataPrep = {
        name: editDynamicGroupName,
      };
      const options = {
        method: "PATCH",
        url: `https://api.pramsplus.com/groups/${groupId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
        data: dataPrep,
      };
      try {
        const response = await axios.request(options);
        // console.log("updateGroupName response", response.data);
        setGroupsLoaded(false);
        setSelectedGroupType("none");
        setEditGroupName("");
        setDynamicEditGroupName("");
      } catch (err) {
        console.log(err.response.status);
        if (err.response.status === 401) {
          const valid = await refreshCheck("saveDynamicGroupName");
          if (valid === true) {
            console.log("Retrying saveDynamicGroupName api call");
            saveDynamicGroupName();
          } else if (valid === false) {
            console.log("all retries failed saveDynamicGroupName");
            navigate("/login");
          }
        } else {
          console.log(
            "Redirecting to login something went wrong in saveDynamicGroupName"
          );
          navigate("/login");
        }
        return;
      }
    };
    return (
      <Container>
        <Row>
          <Col xs={6}>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">Group Name:</InputGroup.Text>
              <Form.Control
                placeholder="Ex. K22 Pad"
                aria-label="group_name"
                aria-describedby="basic-addon1"
                value={editDynamicGroupName}
                onChange={handleDynamicGroupNameChange}
              />
            </InputGroup>
          </Col>
          <Col xs={2}>
            <InputGroup className="mb-3">
              <Form.Check
                type="switch"
                id="custom-switch"
                checked={dynamicSwitches.selectedShared}
                onChange={() => handleDynamicSwitchChange()}
                label="Share"
              />
            </InputGroup>
          </Col>
          <Col>
            <ButtonGroup>
              <Button
                variant="outline-primary"
                onClick={() => saveDynamicData()}
              >
                <SaveIcon />
                Save
              </Button>
              <Button variant="outline-success" onClick={() => cancelGroup()}>
                <CancelIcon />
                Cancel
              </Button>
              <Button variant="outline-danger" onClick={() => deleteGroup()}>
                <DeleteIcon />
                Delete
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <>
          <Table hover>
            <thead>
              <tr className="text-center">
                <th>Field</th>
                <th>Comparison</th>
                <th>Value</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr key={index}>
                  <td style={{ padding: "0px" }}>
                    <Form.Select
                      id="field"
                      value={row.fieldId}
                      onChange={(event) =>
                        handleFieldChange(
                          index,
                          event.target.value,
                          event.target.options[event.target.selectedIndex].text
                        )
                      }
                    >
                      <option value={rows[index].fieldValue}>
                        {rows[index].fieldText}
                      </option>
                      {dynamicCombiOptions.map((option, index) => (
                        <option
                          key={index}
                          value={option.key}
                          dynamicvalue={option.gmkey}
                          label={option.value}
                        >
                          {option.value}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                  <td style={{ padding: "0px" }}>
                    <Form.Select
                      id="operator"
                      value={row.comparison}
                      onChange={(event) =>
                        handleComparisonChange(index, event.target.value)
                      }
                    >
                      <option value={rows[index].comparison}>
                        {rows[index].comparison}
                      </option>
                      {comparisonOptions.map((comparison, index) => (
                        <option key={index} value={comparison}>
                          {comparison}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                  <td style={{ padding: "0px" }}>
                    <Form.Select
                      id="value"
                      value={row.value}
                      onChange={(event) =>
                        handleValueChange(
                          index,
                          event.target.value,
                          event.target.options[event.target.selectedIndex].text
                        )
                      }
                    >
                      <option value={rows[index].optionValue}>
                        {rows[index].optionText}
                      </option>
                      {valueOptions[row.fieldId]?.map((valueOptions, index) => (
                        <option
                          key={index}
                          value={valueOptions.value}
                          label={valueOptions.label}
                        >
                          {valueOptions.label}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                  <td className="text-center" style={{ padding: "0px" }}>
                    <Button
                      variant="danger"
                      onClick={(event) => {
                        handleDeleteRow(event, index);
                      }}
                    >
                      <DeleteForeverIcon />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row>
            {dynViewSiteList ? (
              <Card id="Dyn-Card-Sites">
                <Card.Header className="text-end">
                  <Row>
                    <Col>
                      <Row>Site List - {dynSiteCount}</Row>
                      <Row>
                        Viewing by -{" "}
                        {urOrSn === "userRef" ? "User Ref" : "Site Name"}
                      </Row>
                    </Col>
                    <Col>
                      <ButtonGroup>
                        <Button onClick={() => setUrOrSn("userRef")}>
                          User Ref
                        </Button>
                        <Button onClick={() => setUrOrSn("nameNumber")}>
                          Site Name
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => setDynViewSiteList(false)}
                        >
                          <CloseIcon />
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body id="Dyn-Card-Site-List">
                  {urOrSn === "userRef" ? (
                    <ListGroup>
                      {dynSites.map((site, index) => (
                        <ListGroup.Item key={index}>
                          {site.userRef}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  ) : (
                    <ListGroup>
                      {dynSites.map((site, index) => (
                        <ListGroup.Item key={index}>
                          {site.nameNumber}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  )}
                </Card.Body>
              </Card>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            <br />
            <ButtonGroup id="bottom-buttons">
              <Button
                variant="success"
                onClick={() => {
                  addDynamicRow();
                }}
              >
                Add a Filter
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  handleViewSites("button");
                }}
              >
                View Sites
              </Button>
            </ButtonGroup>
          </Row>
        </>
      </Container>
    );
  };
  const callDynamicApi = async (field) => {
    // console.log("callDynamicApi", field);
    const auth = await getAuth();
    const res = await DynamicApiCalls(field, auth);
    // console.log("res", res);
    setValueOptions((prevState) => ({
      ...prevState,
      [field]: res,
    }));
  };
  const handleFieldChange = (index, value, label) => {
    // console.log("index f", index);
    // console.log("value f", value);
    // console.log("label f", label);
    setRows((current) => {
      const newRows = current.map((row, i) => {
        if (i === index) {
          return { ...row, fieldId: value, fieldText: label };
        }
        return row;
      });
      return newRows;
    });
    callDynamicApi(value);
  };
  const handleComparisonChange = (index, value) => {
    setRows((current) => {
      const newRows = current.map((row, i) => {
        if (i === index) {
          return { ...row, comparison: value };
        }
        return row;
      });
      return newRows;
    });
  };
  const handleValueChange = (index, value, label) => {
    setRows((current) => {
      const newRows = current.map((row, i) => {
        if (i === index) {
          return { ...row, optionText: label, optionValue: value };
        }
        return row;
      });
      return newRows;
    });
  };
  const addDynamicRow = () => {
    setRows((current) => [
      ...current,
      {
        fieldId: "",
        fieldText: "",
        comparison: "",
        optionValue: "",
        optionText: "",
      },
    ]);
  };
  const handleDeleteRow = (event, index) => {
    event.preventDefault();
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };
  const handleViewSites = async (props) => {
    if (props === "init") {
      // console.log("init doing nothing");
      return;
    } else {
      // console.log("dynamic doing something");
      if (props === "button") {
        setDynViewSiteList(true);
      } else {
        setDynViewSiteList(false);
      }
      // const xrefRevertMapper = {
      //   "s.acct": "acct",
      //   "s.econ": "econ",
      //   "x.misc_1": "misc_1",
      //   "x.misc_2": "misc_2",
      //   "x.user_1": "user_1",
      //   "x.user_2": "user_2",
      //   "x.user_3": "user_3",
      //   "x.user_4": "user_4",
      //   "x.user_5": "user_5",
      //   "x.user_6": "user_6",
      //   "x.user_7": "user_7",
      //   "x.user_8": "user_8",
      //   "x.user_9": "user_9",
      // };
      const xrefMapper = {
        acct: "s.acct",
        econ: "s.econ",
        misc_1: "x.misc_1",
        misc_2: "x.misc_2",
        user_1: "x.user_1",
        user_2: "x.user_2",
        user_3: "x.user_3",
        user_4: "x.user_4",
        user_5: "x.user_5",
        user_6: "x.user_6",
        user_7: "x.user_7",
        user_8: "x.user_8",
        user_9: "x.user_9",
      };

      console.log(xref, fieldOptions);
      const updatedXref = xref.map((x) => {
        return {
          key: x.key,
          value: x.value,
          gmKey: xrefMapper[x.key],
        };
      });

      const combinedOptions = [...updatedXref, ...fieldOptions];
      console.log("combinedOptions", combinedOptions);
      console.log("send rows", sendRows);
      const updateFieldOptions = sendRows.map((row) => {
        return {
          field: combinedOptions.find((option) => option.gmKey === row.field)
            .key,
          comparison: row.operator,
          value: row.optionValue,
        };
      });
      console.log("updateFieldOptions", updateFieldOptions);
      const finalOptions = updateFieldOptions.map((option) => {
        return {
          field: option.field,
          operator: option.comparison,
          optionValue: option.value,
        };
      });
      const dynList = finalOptions;
      const auth = await getAuth();
      const options = {
        method: "POST",
        url: "https://api.pramsplus.com/site-dynamic",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
        data: dynList,
      };
      console.log("options", options);
      try {
        const response = await axios.request(options);
        setDynSites(response.data);
        setDynSiteCount(response.data.length);
        setDynSiteIds(response.data.map((site) => site.siteId));
        setSiteIds([]);
      } catch (err) {
        console.log(err.response.status);
        if (err.response.status === 401) {
          const valid = await refreshCheck("handleViewSites");
          if (valid === true) {
            console.log("Retrying handleViewSites api call");
            handleViewSites();
          } else if (valid === false) {
            console.log("all retries failed handleViewSites");
            navigate("/login");
          }
        } else {
          console.log(
            "Redirecting to login something went wrong in handleViewSites"
          );
          navigate("/login");
        }
        return;
      }
    }
  };
  const combineHierarchyOptions = (xref, hierarchyOptions) => {
    const xrefMapper = {
      acct: "s.acct",
      econ: "s.econ",
      misc_1: "x.misc_1",
      misc_2: "x.misc_2",
      user_1: "x.user_1",
      user_2: "x.user_2",
      user_3: "x.user_3",
      user_4: "x.user_4",
      user_5: "x.user_5",
      user_6: "x.user_6",
      user_7: "x.user_7",
      user_8: "x.user_8",
      user_9: "x.user_9",
    };
    const xrefUpdated = xref.map((x) => {
      return {
        key: xrefMapper[x.key],
        value: x.value,
      };
    });
    // console.log("xref updated", xrefUpdated);
    const filterXref = xrefUpdated.filter((x) => x.key !== undefined);
    // console.log("filterXref", filterXref);
    const combinedOptions = [...hierarchyOptions, ...filterXref];
    setHierarchyCombiOptions(combinedOptions);
  };
  const combineDynamicOptions = (xref, dynamicOptions) => {
    const xrefMapper = {
      acct: "s.acct",
      econ: "s.econ",
      misc_1: "x.misc_1",
      misc_2: "x.misc_2",
      user_1: "x.user_1",
      user_2: "x.user_2",
      user_3: "x.user_3",
      user_4: "x.user_4",
      user_5: "x.user_5",
      user_6: "x.user_6",
      user_7: "x.user_7",
      user_8: "x.user_8",
      user_9: "x.user_9",
    };
    const xrefUpdated = xref.map((x) => {
      return {
        key: xrefMapper[x.key],
        value: x.value,
      };
    });
    // console.log("xref updated", xrefUpdated);
    const filterXref = xrefUpdated.filter((x) => x.key !== undefined);
    // console.log("filterXref", filterXref);
    const updatedKeysDynamicOptions = dynamicOptions.map((option) => {
      return {
        value: option.value,
        key: option.gmKey,
      };
    });

    const combinedOptions = [...updatedKeysDynamicOptions, ...filterXref];
    setDynamicCombiOptions(combinedOptions);
  };
  const getXref = async () => {
    const auth = await getAuth();
    const initHierarchyOptions = hierarchyOptions;
    const initFieldOptions = fieldOptions;
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/xref-labels",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      // console.log("xref", response.data);
      setXref(response.data);
      // setHierarchyCombiOptions([...response.data, ...initHierarchyOptions]);
      combineHierarchyOptions(response.data, initHierarchyOptions);
      // setFieldCombiOptions([...response.data, ...initFieldOptions]);
      combineDynamicOptions(response.data, initFieldOptions);
    } catch (err) {
      console.log(err.response.status);
      if (err.response.status === 401) {
        const valid = await refreshCheck("getXref");
        if (valid === true) {
          console.log("Retrying getXref api call");
          getXref();
        } else if (valid === false) {
          console.log("all retries failed getXref");
          navigate("/login");
        }
      } else {
        console.log("Redirecting to login something went wrong in getXref");
        navigate("/login");
      }
      return;
    }
  };
  const applyGroupHiddenTrueFalse = (groupId) => {
    if (hiddenGroups.length > 0) {
      const hiddenGroup = hiddenGroups.find(
        (group) => group.groupId === groupId
      );
      if (hiddenGroup) {
        // return `${hiddenGroup.hidden}`;
        return hiddenGroup.hidden;
      }
    }
    return null;
  };
  // const applyGroupHiddenYesNo = (groupId) => {
  //   if (hiddenGroups.length > 0) {
  //     const hiddenGroup = hiddenGroups.find(
  //       (group) => group.groupId === groupId
  //     );
  //     if (hiddenGroup) {
  //       // console.log("hiddenGroup", hiddenGroup.hidden);
  //       return `${groupShareMapping[hiddenGroup.hidden]}`;
  //     }
  //   }
  //   return null;
  // };
  const saveGroup = async (props) => {
    // console.log("save group props", props);
    if (props === "Dynamic") {
      saveDynamicGroup();
    }
    if (props.text === "Hierarchy") {
      if (props.items === undefined) {
        // console.log("empty hierarchy group");
        return;
      }
      saveHierarchyGroupMain(props.items);
    }
  };
  const saveDynamicGroup = async () => {
    // console.log("saved dynamic group");
    const auth = await getAuth();
    const arrayPrep = rows.map((row) => {
      return {
        field: row.fieldId,
        comparison: row.comparison,
        value: row.optionValue,
      };
    });
    const dataPrep = {
      groupId: groupId,
      items: arrayPrep,
    };
    // console.log("data prep", dataPrep);
    try {
      const options = {
        method: "POST",
        url: "https://api.pramsplus.com/insight-dynamic-groups",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
        data: dataPrep,
      };
      const response = await axios.request(options);
      // console.log("saveDynamicGroup response", response.data);
      setGroupsLoaded(false);
      setDynViewSiteList(false);
      getGroups();
      setSelectedGroupType("none");
      setEditGroupName("");
      setRows([
        {
          fieldText: "",
          fieldValue: "",
          comparison: "",
          optionValue: "",
          optionText: "",
        },
      ]);
      setShowSavedAlert(true);
    } catch (err) {
      console.log(err.response.status);
      if (err.response.status === 401) {
        const valid = await refreshCheck("saveDynamicGroup");
        if (valid === true) {
          console.log("Retrying saveDynamicGroup api call");
          saveDynamicGroup();
        } else if (valid === false) {
          console.log("all retries failed saveDynamicGroup");
          navigate("/login");
        }
      } else {
        console.log(
          "Redirecting to login something went wrong in saveDynamicGroup"
        );
        navigate("/login");
      }
      return;
    }
  };
  // * Hierarchy Group ---------------------------------------------
  const saveHierarchyGroupMain = async (props) => {
    // console.log("saved Hierarchy group");
    // console.log("props hierarchy", props);
    const auth = await getAuth();
    const fieldsFilter = props.map((item) => item.key);
    // console.log("fieldsFilter", fieldsFilter);
    const dataPrep = {
      groupId: groupId,
      fields: fieldsFilter,
    };
    // console.log("data prep", dataPrep);
    try {
      const options = {
        method: "POST",
        url: "https://api.pramsplus.com/insight-hierarchy-groups",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
        data: dataPrep,
      };
      const response = await axios.request(options);
      // console.log("saveHierarchyGroup response", response.data);
      setGroupsLoaded(false);
      getGroups();
      setSelectedGroupType("none");
      setEditGroupName("");
      setShowSavedAlert(true);
    } catch (err) {
      console.log(err.response.status);
      if (err.response.status === 401) {
        const valid = await refreshCheck("saveHierarchyGroupMain");
        if (valid === true) {
          console.log("Retrying saveHierarchyGroupMain api call");
          saveHierarchyGroupMain();
        } else if (valid === false) {
          console.log("all retries failed saveHierarchyGroupMain");
          navigate("/login");
        }
      } else {
        console.log(
          "Redirecting to login something went wrong in saveHierarchyGroupMain"
        );
        navigate("/login");
      }
      return;
    }
  };
  const HierarchyGroup = () => {
    const [assignedItemsMain, setAssignedItemsMain] = useState([]);
    const [editHierarchyGroupName, setEditHierarchyGroupName] = useState("");
    const [hierarchySwitches, setHierarchySwitches] = useState({
      selectedShared: false,
    });
    useEffect(() => {
      setHierarchySwitches((prevSwitches) => ({
        ...prevSwitches,
        selectedShared: switches.selectedShared,
      }));
    }, []);
    useEffect(() => {
      // console.log("assignedItemsMain", assignedItemsMain);
    }, [assignedItemsMain]);
    const handleHierarchyGroupNameChange = (event) => {
      setEditHierarchyGroupName(event.target.value);
    };
    const handleHierarchySwitchChange = () => {
      // setSwitches.selectedShared(!switches.selectedShared);
      setHierarchySwitches((prevSwitches) => ({
        ...prevSwitches,
        selectedShared: !prevSwitches.selectedShared,
      }));
      // console.log("switched", switches.selectedShared);
    };
    const saveHierarchyGroup = async (props) => {
      // console.log("saved Hierarchy group");
      saveGroup(props);
      saveHierarchyGroupName();
      saveShareHierarchy();
    };
    const saveHierarchyGroupName = async () => {
      if (editHierarchyGroupName === "") {
        // console.log("empty group name");
        return;
      }
      const auth = await getAuth();
      const dataPrep = {
        name: editHierarchyGroupName,
      };
      const options = {
        method: "PATCH",
        url: `https://api.pramsplus.com/groups/${groupId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
        data: dataPrep,
      };
      try {
        const response = await axios.request(options);
        // console.log("updateGroupName response", response.data);
        setGroupsLoaded(false);
        getGroups();
        setSelectedGroupType("none");
        setEditGroupName("");
        setEditHierarchyGroupName("");
      } catch (err) {
        console.log(err.response.status);
        if (err.response.status === 401) {
          const valid = await refreshCheck("saveHierarchyGroupName");
          if (valid === true) {
            console.log("Retrying saveHierarchyGroupName api call");
            saveHierarchyGroupName();
          } else if (valid === false) {
            console.log("all retries failed saveHierarchyGroupName");
            navigate("/login");
          }
        } else {
          console.log(
            "Redirecting to login something went wrong in saveHierarchyGroupName"
          );
          navigate("/login");
        }
        return;
      }
    };
    const saveShareHierarchy = async () => {
      const auth = await getAuth();
      const dataPrep = {
        groupId: groupId,
        shared: hierarchySwitches.selectedShared,
      };
      const options = {
        method: "PATCH",
        url: `https://api.pramsplus.com/groups/${groupId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
        data: dataPrep,
      };
      console.log("save Share Static data prep", dataPrep);
      try {
        const response = await axios.request(options);
        console.log("saveShareStatic response", response.data);
        setGroupsLoaded(false);
        setGroupId(null);
        setSelectedGroupType("none");
        setEditGroupName("");
      } catch (err) {
        console.log(err.response.status);
        if (err.response.status === 401) {
          const valid = await refreshCheck("saveShareHierarchy");
          if (valid === true) {
            console.log("Retrying saveShareHierarchy api call");
            saveShareHierarchy();
          } else if (valid === false) {
            console.log("all retries failed saveShareHierarchy");
            navigate("/login");
          }
        } else {
          console.log(
            "Redirecting to login something went wrong in saveShareHierarchy"
          );
          navigate("/login");
        }
        return;
      }
    };
    return (
      <>
        <Row>
          <Col xs={6}>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">Group Name:</InputGroup.Text>
              <Form.Control
                placeholder="Ex. K22 Pad"
                aria-label="group_name"
                aria-describedby="basic-addon1"
                value={editHierarchyGroupName}
                onChange={handleHierarchyGroupNameChange}
              />
            </InputGroup>
          </Col>
          <Col xs={2}>
            <InputGroup className="mb-3">
              <Form.Check
                type="switch"
                id="custom-switch"
                checked={hierarchySwitches.selectedShared}
                onChange={() => handleHierarchySwitchChange()}
                label="Share"
              />
            </InputGroup>
          </Col>
          <Col>
            <ButtonGroup>
              <Button
                variant="outline-primary"
                onClick={() =>
                  saveHierarchyGroup({
                    text: "Hierarchy",
                    items: assignedItemsMain,
                  })
                }
              >
                <SaveIcon />
                Save
              </Button>
              <Button variant="outline-success" onClick={() => cancelGroup()}>
                <CancelIcon />
                Cancel
              </Button>
              <Button variant="outline-danger" onClick={() => deleteGroup()}>
                <DeleteIcon />
                Delete
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <HierarchySort setAssignedItemsMain={setAssignedItemsMain} />
      </>
    );
  };
  const HierarchySort = (props) => {
    // console.log("hierarchyCombiOptions", hierarchyCombiOptions);
    // console.log("h sort props", props);
    const [availableItems, setAvailableItems] = useState([]);
    const [assignedItems, setAssignedItems] = useState([]);
    const [hierarchyDataLoaded, setHierarchyDataLoaded] = useState(false);
    useEffect(() => {
      setAvailableItems(hierarchyCombiOptions);
    }, []);
    useEffect(() => {
      // console.log("assignedItems", assignedItems);
      if (assignedItems.length === 0) {
        return;
      }
      props.setAssignedItemsMain(assignedItems);
    }, [assignedItems]);
    useEffect(() => {
      if (availableItems.length !== 0) {
        if (hierarchyDataLoaded === false) {
          if (hierarchyFieldOptionsMain !== undefined) {
            // console.log("hierarchyFieldOptionsMain", hierarchyFieldOptionsMain);
            const packageOptions = hierarchyFieldOptionsMain.map((item) => {
              const itemKey = hierarchyCombiOptions.find(
                (option) => option.key === item
              );
              // console.log("itemKey", itemKey);
              return itemKey;
            });
            // console.log("packageOptions", packageOptions);
            const filterAvailableItems = availableItems.filter((item) => {
              return !packageOptions.includes(item);
            });

            // console.log("filterAvailableItems", filterAvailableItems);
            setAvailableItems(filterAvailableItems);
            setAssignedItems(packageOptions);
            setHierarchyDataLoaded(true);
          }
        }
      }
    }, [availableItems]);
    const handleAssignItem = (item) => {
      setAvailableItems(availableItems.filter((i) => i.key !== item.key));
      setAssignedItems([...assignedItems, item]);
    };
    const handleRemoveItem = (item) => {
      setAssignedItems(assignedItems.filter((i) => i.key !== item.key));
      setAvailableItems([...availableItems, item]);
    };
    return (
      <Container>
        <Row>
          <Col>
            <Row className="text-center">
              <h3>Available</h3>
            </Row>
            <Row>
              <Col id="available-col">
                <div className="list-group">
                  {availableItems.map((item) => (
                    <div
                      key={item.key}
                      className="list-group-item d-flex justify-content-between align-items-center"
                    >
                      {item.value}
                      <Button
                        variant="primary"
                        onClick={() => handleAssignItem(item)}
                      >
                        Assign
                      </Button>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className="text-center">
              <h3>Assigned</h3>
            </Row>
            <Row>
              <Col id="assigned-col">
                <div className="list-group">
                  {assignedItems.map((item) => (
                    <div
                      key={item.key}
                      className="list-group-item d-flex justify-content-between align-items-center"
                    >
                      {item.value}
                      <Button
                        variant="danger"
                        onClick={() => handleRemoveItem(item)}
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  };
  //* Main Return ------------------------------------------------
  return (
    <>
      <TopNav />
      <Container fluid id="gm-container">
        <Row id="main-gm-row">
          <Col>
            <Card id="my-groups-card">
              <Card.Title>
                <h6>My Groups</h6>
              </Card.Title>
              <Card.Body>
                <Row>
                  <Col className="d-flex justify-content-end">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="outline-primary"
                        id="dropdown-type-selection"
                      >
                        {selectedGroupTypeDropdown === ""
                          ? "Select a Group Type to Add"
                          : selectedGroupTypeDropdown}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          id="static"
                          onClick={() => {
                            setSelectedGroupTypeDropdown("Static");
                          }}
                        >
                          Static
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setSelectedGroupTypeDropdown("Dynamic");
                          }}
                        >
                          Dynamic
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setSelectedGroupTypeDropdown("Hierarchy");
                          }}
                        >
                          Hierarchy
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col>
                    <ButtonGroup aria-label="Basic example">
                      <Button
                        variant="outline-primary"
                        onClick={() => {
                          handleNewGroup();
                        }}
                      >
                        <AddIcon />
                        Add
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <br />
                <Row id="userGroups">
                  {/* <StyledTable striped bordered hover> */}
                  <Table striped bordered hover>
                    <thead id="user-groups-thead">
                      <tr>
                        <th>Group Name</th>
                        <th>Type</th>
                        <th>Shared</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userGroups.length > 0 ? (
                        userGroups.map((group) => {
                          return (
                            <tr
                              key={group.id}
                              onClick={() =>
                                handleGroupClick({
                                  groupId: group.id,
                                  name: group.name,
                                  type: group.type,
                                  share: group.shared,
                                  isOtherUser: false,
                                  hidden: applyGroupHiddenTrueFalse(group.id),
                                })
                              }
                            >
                              <td>{group.name}</td>
                              <td>{typeMapping[group.type]}</td>
                              <td>{groupShareMapping[group.shared]}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="3">No groups found</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Row>
              </Card.Body>
            </Card>
            <Card id="shared-group-card">
              <Card.Title>
                <h6>Other Users Shared Groups</h6>
              </Card.Title>
              <Card.Body>
                <Row>
                  <Table id="otherUserGroups" striped bordered hover>
                    <thead>
                      <tr>
                        <th>Group Name</th>
                        <th>Type</th>
                        <th>Hide</th>
                      </tr>
                    </thead>
                    <tbody>
                      {otherUserGroups.length > 0 ? (
                        otherUserGroups.map((otherUserGroups) => {
                          return (
                            <tr
                              key={otherUserGroups.id}
                              onClick={() =>
                                handleGroupClick({
                                  groupId: otherUserGroups.id,
                                  name: otherUserGroups.name,
                                  type: otherUserGroups.type,
                                  share: otherUserGroups.shared,
                                  isOtherUser: true,
                                  hidden: otherUserGroups.isHidden,
                                })
                              }
                            >
                              <td>{otherUserGroups.name}</td>
                              <td>{typeMapping[otherUserGroups.type]}</td>
                              <td>
                                {groupShareMapping[otherUserGroups.isHidden]}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="3">No groups found</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card id="edit-group-card">
              <Card.Title>
                <ShowAlert />
                <ShowAlertDeleted />
                <Row>
                  {selectedGroupType === "none" ? (
                    <Col>
                      <h6>Select a group to edit</h6>
                    </Col>
                  ) : (
                    <>
                      <Col>
                        <h6>Editing Group: {groupName}</h6>
                      </Col>
                      <Col>
                        <h6>Group Type: {selectedGroupType}</h6>
                      </Col>
                    </>
                  )}
                </Row>
              </Card.Title>
              <Card.Body>{editGroup(selectedGroupType)}</Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default GroupMaintenance;
