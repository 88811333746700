import React from "react";

const ErrorPage = () => {
  return (
    <>
    <div>
      OOPS 404
    </div>
    </>
  );
};

export default ErrorPage;