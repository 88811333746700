import React from "react";
import { MaterialReactTable } from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";
import "../MRT/MRTStyle.css";

const columns = [
  { accessorKey: "siteId", header: "Site ID", size: 120 },
  { accessorKey: "date", header: "Date", size: 120 },
  { accessorKey: "userRef", header: "User Ref", size: 120 },
  { accessorKey: "siteName", header: "Site Name", size: 120 },
  { accessorKey: "hoursOnline", header: "Hours On", size: 120 },
  { accessorKey: "hoursDown", header: "Hours Down", size: 120 },
  { accessorKey: "downCode", header: "Down Code", size: 120 },
  { accessorKey: "downcodeDescription", header: "Down Code Desc", size: 120 },
  { accessorKey: "grossOil", header: "Gross Oil", size: 120 },
  { accessorKey: "grossGas", header: "Gross Gas", size: 120 },
  { accessorKey: "grossWater", header: "Gross Water", size: 120 },
  { accessorKey: "netOil", header: "Net Oil", size: 120 },
  { accessorKey: "netGas", header: "Net Gas", size: 120 },
  { accessorKey: "netWater", header: "Net Water", size: 120 },
  { accessorKey: "injectedVolume", header: "Inj Vol", size: 120 },
  { accessorKey: "forecastOil", header: "Forecast Oil", size: 120 },
  { accessorKey: "forecastGas", header: "Forecast Gas", size: 120 },
  { accessorKey: "forecastWater", header: "Forecast Water", size: 120 },
  { accessorKey: "boe", header: "BOE", size: 120 },
  { accessorKey: "netBOE", header: "Net BOE", size: 120 },
  { accessorKey: "oilSales", header: "Oil Sales", size: 120 },
  { accessorKey: "gasSales", header: "Gas Sales", size: 120 },
  { accessorKey: "gasLift", header: "Gas Lift", size: 120 },
  { accessorKey: "leaseUse", header: "Lease Use", size: 120 },
  { accessorKey: "flare", header: "Flare", size: 120 },
  { accessorKey: "casingPressure", header: "Casing Prs", size: 120 },
  { accessorKey: "tubingPressure", header: "Tubing Prs", size: 120 },
  { accessorKey: "linePressure", header: "Line Prs", size: 120 },
  { accessorKey: "pumpInletPressure", header: "Pump Inlet Prs", size: 120 },
  { accessorKey: "gasLiftPressure", header: "Gas Lift Prs", size: 120 },
  { accessorKey: "bradenheadPressure", header: "Bradenhead Prs", size: 120 },
  { accessorKey: "choke", header: "Choke", size: 120 },
  { accessorKey: "casingChoke", header: "Casing Choke", size: 120 },
  { accessorKey: "fluidLevel", header: "Fluid Level", size: 120 },
  { accessorKey: "gor", header: "GOR", size: 120 },
  { accessorKey: "wor", header: "WOR", size: 120 },
  { accessorKey: "mcfbbl", header: "MCFBBL", size: 120 },
  { accessorKey: "mmcfbbl", header: "MMCFBBL", size: 120 },
  { accessorKey: "waterCut", header: "Water Cut", size: 120 },
  { accessorKey: "oilCut", header: "Oil Cut", size: 120 },
  { accessorKey: "chartNote", header: "Chart Note", size: 120 },
  { accessorKey: "remarks", header: "Remarks", size: 120 },
  { accessorKey: "userData1", header: "User Data 1", size: 120 },
  { accessorKey: "userData2", header: "User Data 2", size: 120 },
  { accessorKey: "userData3", header: "User Data 3", size: 120 },
  { accessorKey: "userData4", header: "User Data 4", size: 120 },
  { accessorKey: "userData5", header: "User Data 5", size: 120 },
  { accessorKey: "userData6", header: "User Data 6", size: 120 },
  { accessorKey: "userData7", header: "User Data 7", size: 120 },
  { accessorKey: "userData8", header: "User Data 8", size: 120 },
  { accessorKey: "userData9", header: "User Data 9", size: 120 },
  { accessorKey: "nri", header: "NRI", size: 120 },
  { accessorKey: "average7Oil", header: "Avg 7Day Oil", size: 120 },
  { accessorKey: "average7Gas", header: "Avg 7Day Gas", size: 120 },
  { accessorKey: "average7Water", header: "Avg 7Day Water", size: 120 },
];

const csvOptions = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
};

const MRT = ({ ddInfo }) => {
  const handleExportRows = (rows, columns) => {
    const visibleColumns = columns.filter((col) => col.getIsVisible());
    const csvOptionsWithHeaders = {
      ...csvOptions,
      headers: visibleColumns.map((col) => col.columnDef.header),
    };
    const csvExporter = new ExportToCsv(csvOptionsWithHeaders);
    const exportData = rows.map((row) =>
      visibleColumns.reduce((acc, col) => {
        acc[col.id] = row.original[col.id];
        return acc;
      }, {})
    );
    csvExporter.generateCsv(exportData);
  };

  return (
    <MaterialReactTable
      columns={columns}
      data={ddInfo}
      initialState={{
        density: "compact",
        columnVisibility: {
          siteId: false,
          siteName: false,
          downCode: false,
          downcodeDescription: false,
          netOil: false,
          netGas: false,
          netWater: false,
          forecastOil: false,
          forecastGas: false,
          forecastWater: false,
          boe: false,
          netBOE: false,
          casingPressure: false,
          tubingPressure: false,
          linePressure: false,
          pumpInletPressure: false,
          gasLiftPressure: false,
          bradenheadPressure: false,
          choke: false,
          casingChoke: false,
          fluidLevel: false,
          gor: false,
          wor: false,
          mcfbbl: false,
          mmcfbbl: false,
          waterCut: false,
          oilCut: false,
          chartNote: false,
          remarks: false,
          userData1: false,
          userData2: false,
          userData3: false,
          userData4: false,
          userData5: false,
          userData6: false,
          userData7: false,
          userData8: false,
          userData9: false,
          nri: false,
        },
      }}
      enableRowSelection
      enableStickyHeader
      enablePagination={false}
      enableBottomToolbar={false}
      positionToolbarAlertBanner="bottom"
      muiTableContainerProps={{ sx: { maxHeight: "325px", width: "100%" } }}
      renderTopToolbarCustomActions={({ table }) => (
        <Box
          sx={{ display: "flex", gap: "1rem", p: "0.5rem", flexWrap: "wrap" }}
        >
          <Button
            color="primary"
            onClick={() =>
              handleExportRows(table.getRowModel().rows, table.getAllColumns())
            }
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export Page Rows
          </Button>
          <Button
            disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            }
            onClick={() =>
              handleExportRows(
                table.getSelectedRowModel().rows,
                table.getAllColumns()
              )
            }
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export Selected Rows
          </Button>
        </Box>
      )}
    />
  );
};

export default MRT;

// import React, { useState, useEffect } from "react";
// import { MaterialReactTable } from "material-react-table";
// import { Box, Button } from "@mui/material";
// import FileDownloadIcon from "@mui/icons-material/FileDownload";
// import { ExportToCsv } from "export-to-csv";
// import "../MRT/MRTStyle.css";

// const columns = [
//   {
//     accessorKey: "siteId",
//     header: "Site ID",
//     size: 120,
//   },
//   {
//     accessorKey: "date",
//     header: "Date",
//     size: 120,
//   },
//   {
//     accessorKey: "userRef",
//     header: "User Ref",
//     size: 120,
//   },
//   {
//     accessorKey: "siteName",
//     header: "Site Name",
//     size: 120,
//   },
//   {
//     accessorKey: "hoursOnline",
//     header: "Hours On",
//     size: 120,
//   },
//   {
//     accessorKey: "hoursDown",
//     header: "Hours Down",
//     size: 120,
//   },
//   {
//     accessorKey: "downCode",
//     header: "Down Code",
//     size: 120,
//   },
//   {
//     accessorKey: "downcodeDescription",
//     header: "Down Code Desc",
//     size: 120,
//   },
//   {
//     accessorKey: "grossOil",
//     header: "Gross Oil",
//     size: 120,
//   },
//   {
//     accessorKey: "grossGas",
//     header: "Gross Gas",
//     size: 120,
//   },
//   {
//     accessorKey: "grossWater",
//     header: "Gross Water",
//     size: 120,
//   },
//   {
//     accessorKey: "netOil",
//     header: "Net Oil",
//     size: 120,
//   },
//   {
//     accessorKey: "netGas",
//     header: "Net Gas",
//     size: 120,
//   },
//   {
//     accessorKey: "netWater",
//     header: "Net Water",
//     size: 120,
//   },
//   {
//     accessorKey: "injectedVolume",
//     header: "Inj Vol",
//     size: 120,
//   },
//   {
//     accessorKey: "forecastOil",
//     header: "Forecast Oil",
//     size: 120,
//   },
//   {
//     accessorKey: "forecastGas",
//     header: "Forecast Gas",
//     size: 120,
//   },
//   {
//     accessorKey: "forecastWater",
//     header: "Forecast Water",
//     size: 120,
//   },
//   {
//     accessorKey: "boe",
//     header: "BOE",
//     size: 120,
//   },
//   {
//     accessorKey: "netBOE",
//     header: "Net BOE",
//     size: 120,
//   },
//   {
//     accessorKey: "oilSales",
//     header: "Oil Sales",
//     size: 120,
//   },
//   {
//     accessorKey: "gasSales",
//     header: "Gas Sales",
//     size: 120,
//   },
//   {
//     accessorKey: "gasLift",
//     header: "Gas Lift",
//     size: 120,
//   },
//   {
//     accessorKey: "leaseUse",
//     header: "Lease Use",
//     size: 120,
//   },
//   {
//     accessorKey: "flare",
//     header: "Flare",
//     size: 120,
//   },
//   {
//     accessorKey: "casingPressure",
//     header: "Casing Prs",
//     size: 120,
//   },
//   {
//     accessorKey: "tubingPressure",
//     header: "Tubing Prs",
//     size: 120,
//   },
//   {
//     accessorKey: "linePressure",
//     header: "Line Prs",
//     size: 120,
//   },
//   {
//     accessorKey: "pumpInletPressure",
//     header: "Pump Inlet Prs",
//     size: 120,
//   },
//   {
//     accessorKey: "gasLiftPressure",
//     header: "Gas Lift Prs",
//     size: 120,
//   },
//   {
//     accessorKey: "bradenheadPressure",
//     header: "Bradenhead Prs",
//     size: 120,
//   },
//   {
//     accessorKey: "choke",
//     header: "Choke",
//     size: 120,
//   },
//   {
//     accessorKey: "casingChoke",
//     header: "Casing Choke",
//     size: 120,
//   },
//   {
//     accessorKey: "fluidLevel",
//     header: "Fluid Level",
//     size: 120,
//   },
//   {
//     accessorKey: "gor",
//     header: "GOR",
//     size: 120,
//   },
//   {
//     accessorKey: "wor",
//     header: "WOR",
//     size: 120,
//   },
//   {
//     accessorKey: "mcfbbl",
//     header: "MCFBBL",
//     size: 120,
//   },
//   {
//     accessorKey: "mmcfbbl",
//     header: "MMCFBBL",
//     size: 120,
//   },
//   {
//     accessorKey: "waterCut",
//     header: "Water Cut",
//     size: 120,
//   },
//   {
//     accessorKey: "oilCut",
//     header: "Oil Cut",
//     size: 120,
//   },
//   {
//     accessorKey: "chartNote",
//     header: "Chart Note",
//     size: 120,
//   },
//   {
//     accessorKey: "remarks",
//     header: "Remarks",
//     size: 120,
//   },
//   {
//     accessorKey: "userData1",
//     header: "User Data 1",
//     size: 120,
//   },
//   {
//     accessorKey: "userData2",
//     header: "User Data 2",
//     size: 120,
//   },
//   {
//     accessorKey: "userData3",
//     header: "User Data 3",
//     size: 120,
//   },
//   {
//     accessorKey: "userData4",
//     header: "User Data 4",
//     size: 120,
//   },
//   {
//     accessorKey: "userData5",
//     header: "User Data 5",
//     size: 120,
//   },
//   {
//     accessorKey: "userData6",
//     header: "User Data 6",
//     size: 120,
//   },
//   {
//     accessorKey: "userData7",
//     header: "User Data 7",
//     size: 120,
//   },
//   {
//     accessorKey: "userData8",
//     header: "User Data 8",
//     size: 120,
//   },
//   {
//     accessorKey: "userData9",
//     header: "User Data 9",
//     size: 120,
//   },
//   {
//     accessorKey: "nri",
//     header: "NRI",
//     size: 120,
//   },
//   {
//     accessorKey: "average7Oil",
//     header: "Avg 7Day Oil",
//     size: 120,
//   },
//   {
//     accessorKey: "average7Gas",
//     header: "Avg 7Day Gas",
//     size: 120,
//   },
//   {
//     accessorKey: "average7Water",
//     header: "Avg 7Day Water",
//     size: 120,
//   },
// ];
// const csvOptions = {
//   fieldSeparator: ",",
//   quoteStrings: '"',
//   decimalSeparator: ".",
//   showLabels: true,
//   useBom: true,
//   useKeysAsHeaders: false,
//   headers: columns.map((c) => c.header),
// };
// const csvExporter = new ExportToCsv(csvOptions);
// const MRT = ({ ddInfo }) => {
//   // const [progress, setProgress] = useState(true);
//   const handleExportRows = (rows) => {
//     csvExporter.generateCsv(rows.map((row) => row.original));
//   };
//   const handleExportData = () => {
//     csvExporter.generateCsv(ddInfo);
//   };
//   // useEffect(() => {
//   //   if (ddInfo.length === 0) {
//   //     setProgress(true);
//   //   } else {
//   //     setProgress(false);
//   //   }
//   // }, [ddInfo]);

//   // useEffect(() => {
//   //   const interval = setInterval(() => {
//   //     setProgress((oldProgress) => {
//   //       const newProgress = Math.random() * 20;
//   //       return Math.min(oldProgress + newProgress, 100);
//   //     });
//   //   }, 1000);
//   //   return () => clearInterval(interval);
//   // }, []);

//   return (
//     <MaterialReactTable
//       columns={columns}
//       data={ddInfo}
//       initialState={{
//         density: "compact",
//         columnVisibility: {
//           siteId: false,
//           siteName: false,
//           downCode: false,
//           downcodeDescription: false,
//           netOil: false,
//           netGas: false,
//           netWater: false,
//           forecastOil: false,
//           forecastGas: false,
//           forecastWater: false,
//           boe: false,
//           netBOE: false,
//           casingPressure: false,
//           tubingPressure: false,
//           linePressure: false,
//           pumpInletPressure: false,
//           gasLiftPressure: false,
//           bradenheadPressure: false,
//           choke: false,
//           casingChoke: false,
//           fluidLevel: false,
//           gor: false,
//           wor: false,
//           mcfbbl: false,
//           mmcfbbl: false,
//           waterCut: false,
//           oilCut: false,
//           chartNote: false,
//           remarks: false,
//           userData1: false,
//           userData2: false,
//           userData3: false,
//           userData4: false,
//           userData5: false,
//           userData6: false,
//           userData7: false,
//           userData8: false,
//           userData9: false,
//           nri: false,
//         },
//       }}
//       // muiLinearProgressProps={({ isTopToolbar }) => ({
//       //   color: 'secondary',
//       //   variant: 'determinate', //if you want to show exact progress value
//       //   value: progress, //value between 0 and 100
//       //   sx: {
//       //     display: isTopToolbar ? 'block' : 'none', //hide bottom progress bar
//       //   },
//       // })}
//       // state={{ showProgressBars: progress }}
//       enableRowSelection
//       enableStickyHeader
//       enablePagination={false}
//       enableBottomToolbar= {false}
//       positionToolbarAlertBanner="bottom"
//       muiTableContainerProps={{ sx: { maxHeight: "325px", width: "100%"} }}
//       renderTopToolbarCustomActions={({ table }) => (
//         <Box
//           sx={{ display: "flex", gap: "1rem", p: "0.5rem", flexWrap: "wrap",}}
//         >
//           <Button
//             color="primary"
//             //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
//             onClick={handleExportData}
//             startIcon={<FileDownloadIcon />}
//             variant="contained"
//           >
//             Export All Data
//           </Button>
//           {/* <Button
//             disabled={table.getPrePaginationRowModel().rows.length === 0}
//             //export all rows, including from the next page, (still respects filtering and sorting)
//             onClick={() =>
//               handleExportRows(table.getPrePaginationRowModel().rows)
//             }
//             startIcon={<FileDownloadIcon />}
//             variant="contained"
//           >
//             Export All Filtered Rows
//           </Button> */}
//           <Button
//             disabled={table.getRowModel().rows.length === 0}
//             //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
//             onClick={() => handleExportRows(table.getRowModel().rows)}
//             startIcon={<FileDownloadIcon />}
//             variant="contained"
//           >
//             Export Page Rows
//           </Button>
//           <Button
//             disabled={
//               !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
//             }
//             //only export selected rows
//             onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
//             startIcon={<FileDownloadIcon />}
//             variant="contained"
//           >
//             Export Selected Rows
//           </Button>
//         </Box>
//       )}
//     />
//   );
// };

// export default MRT;
