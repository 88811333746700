import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Modal,
  ButtonGroup,
  Spinner,
} from "react-bootstrap";
import axios from "axios";
import { useAuthContext } from "../../utils/authProvider";
import OilCardToday from "./Card_Types/OilCardToday";
import GasCardToday from "./Card_Types/GasCardToday";
import WtrCardToday from "./Card_Types/WtrCardToday";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AddIcon from "@mui/icons-material/Add";
import "./card.css";

const initialCardsTest1 = [
  {
    instance: 1,
    class: "McOilCardToday",
    user_id: 1,
    position: 0,
  },
  // {
  //   instance: 2,
  //   class: "McGasCardToday",
  //   user_id: 1,
  //   position: 1,
  // },
  // {
  //   instance: 3,
  //   class: "McWaterCardToday",
  //   user_id: 1,
  //   position: 2,
  // },
];
// const initialCardsTest2 = [
//   {
//     instance_id: 1,
//     option_id: "userDefName",
//     value: "My first Oil",
//   },
//   {
//     instance_id: 1,
//     option_id: "initText",
//     value: "Oil Production Today",
//   },
//   {
//     instance_id: 1,
//     option_id: "site_ids",
//     value: [7245, 7246],
//   },
//   {
//     instance_id: 2,
//     option_id: "userDefName",
//     value: "",
//   },
//   {
//     instance_id: 2,
//     option_id: "initText",
//     value: "Gas Production Today",
//   },
//   {
//     instance_id: 2,
//     option_id: "site_ids",
//     value: [7245, 7246],
//   },
//   {
//     instance_id: 3,
//     option_id: "userDefName",
//     value: "",
//   },
//   {
//     instance_id: 3,
//     option_id: "initText",
//     value: "Water Production Today",
//   },
//   {
//     instance_id: 3,
//     option_id: "site_ids",
//     value: [7245, 7246],
//   },
// ];
// const initialCards = [
//   {
//     type: `OIL`,
//     initText: "Today's Oil",
//     userText: "",
//     groupID: 1178,
//     groupName: "Web Test",
//     position: 1,
//   },
//   {
//     type: `GAS`,
//     initText: "Today's Gas",
//     userText: "",
//     groupID: 1178,
//     groupName: "Web Test",
//     position: 2,
//   },
//   {
//     type: `WTR`,
//     initText: "Today's Water",
//     userText: "",
//     groupID: 1178,
//     groupName: "Web Test",
//     position: 3,
//   },
//   {
//     type: `OIL`,
//     initText: "7 Day Oil",
//     // ! 23 char long
//     userText: "88888 88888 88888 88888",
//     groupID: 1178,
//     groupName: "Web Test",
//     position: 6,
//   },
//   {
//     type: `GAS`,
//     initText: "7 Day Gas",
//     userText: "Web Test Gas",
//     groupID: 1178,
//     groupName: "Web Test",
//     position: 4,
//   },
//   {
//     type: `WTR`,
//     initText: "Today's Water",
//     userText: "Water Title",
//     groupID: 1178,
//     groupName: "Web Test",
//     position: 5,
//   },
// ];

const MiniCards = (props) => {
  const navigate = useNavigate();
  const { getAuth, refreshCheck } = useAuthContext();

  let content;

  const [userCardsInit, setUserCardsInit] = useState([]);

  const [userCards, setUserCards] = useState([]);

  const [extractedCards, setExtractedCards] = useState([]);
  const [extractedCardOptions, setExtractedCardOptions] = useState([]);
  const [extractedDynamicOptions, setExtractedDynamicOptions] = useState([]);

  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);

  const getUserCards = async () => {
    setDataLoaded(false);
    const authT = await getAuth();
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/web-cards",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authT}`,
      },
    };
    if (authT !== false) {
      try {
        const res = await axios.request(options);
        setUserCardsInit(res.data);
        setDataLoaded(true);
      } catch (err) {
        console.log("getUserCards api call failed");
        console.log(err.response.status);
        if (err.response.status === 401) {
          const valid = await refreshCheck("getUserCards");
          if (valid === true) {
            console.log("Retrying getUserCards api call");
            getUserCards();
          } else if (valid === false) {
            console.log("all retries failed getUserCards");
            navigate("/login");
          }
        } else {
          console.log(
            "Redirecting to login, something went wrong in  getUserCards"
          );
          navigate("/login");
        }
        return;
      }
    }
    return;
  };
  const extractCards = (userCards) => {
    return userCards.map((obj) => ({
      instance: obj.instanceId,
      class: obj.class,
      user_id: obj.userId,
      position: obj.position,
    }));
  };
  const extractCardOptions = (userCards) => {
    return userCards.flatMap((obj) =>
      obj.options.map((option) => ({
        instance_id: obj.instanceId,
        option_id: option.optionId,
        value: option.optionValue,
      }))
    );
  };
  const extractCardDynamicOptions = (userCards) => {
    return userCards.flatMap((obj) =>
      obj.dynamicOptions.map((option) => ({
        instance_id: obj.instanceId,
        opt_id: option.optionId,
        fieldId: option.field,
        operator: option.operator,
        value: option.optionValue,
        text: option.optionText,
      }))
    );
  };
  const cardType = (cardClass, instance) => {
    switch (cardClass) {
      case "McOilCardToday":
        return (
          <OilCardToday
            userCardOptions={extractedCardOptions}
            userDynamicOptions={extractedDynamicOptions}
            instance={instance}
            reload={props.reload}
          />
        );
      case "McGasCardToday":
        return (
          <GasCardToday
            userCardOptions={extractedCardOptions}
            userDynamicOptions={extractedDynamicOptions}
            instance={instance}
            reload={props.reload}
          />
        );
      case "McWaterCardToday":
        return (
          <WtrCardToday
            userCardOptions={extractedCardOptions}
            userDynamicOptions={extractedDynamicOptions}
            instance={instance}
            reload={props.reload}
          />
        );
      default:
        return <h6>Error: No Type</h6>;
    }
  };
  const borderStyle = (cardClass) => {
    switch (cardClass) {
      case "McOilCardToday":
        return `success`;
      case "McGasCardToday":
        return `danger`;
      case "McWaterCardToday":
        return `info`;
      default:
        return `dark`;
    }
  };
  const userCardText = (instance) => {
    // console.log("userCardText instance", instance);
    const cardOptions = extractedCardOptions.filter(
      (option) => option.instance_id === instance
    );
    // console.log("userCardText cardOptions", cardOptions);
    const cardNameOptions = cardOptions.filter(
      (option) => option.option_id === "userDefName"
    );
    if (cardNameOptions.length === 0) {
      return "Set-Up Required click '...' for options.";
    }
    if (cardNameOptions.length === 0 || cardNameOptions[0].value === "") {
      const cardNameOptions = cardOptions.filter(
        (option) => option.option_id === "initText"
      );
      // console.log("userCardText cardNameOptions 0", cardNameOptions);
      return cardNameOptions[0].value;
    }
    // console.log(" userCardText cardNameOptions 1", cardNameOptions[0].value);
    return cardNameOptions[0].value;
  };
  useEffect(() => {
    // console.log("getting cards");
    getUserCards();
  }, []);

  // useEffect(() => {
  //   console.log("loading", loading);
  // }, [loading]);
  useEffect(() => {
    setExtractedCards(extractCards(userCardsInit));
    setExtractedCardOptions(extractCardOptions(userCardsInit));
    setExtractedDynamicOptions(extractCardDynamicOptions(userCardsInit));
  }, [userCardsInit]);

  useEffect(() => {
    if (dataLoaded === true) {
      // console.log("extractedCards", extractedCards);
      setUserCards(extractedCards);
    }
    return;
  }, [extractedCards]);

  useEffect(() => {
    if (userCards.length === 0 && dataLoaded === true) {
      // console.log("no cards");
      setLoading(false);
    } else if (userCards.length > 0 && dataLoaded === true) {
      // console.log("userCards", userCards);
      setLoading(false);
    }
    return;
  }, [userCards]);

  useEffect(() => {
    // console.log("userCardsOptions", extractedCardOptions);
  }, [extractedCardOptions]);

  useEffect(() => {
    // console.log("userCardsDynamicOptions", extractedDynamicOptions);
  }, [extractedDynamicOptions]);

  useEffect(() => {
    // console.log("dataLoaded", dataLoaded);
  }, [dataLoaded]);

  // return (
  //       <Container fluid>
  //         <Row>
  //           <Col>
  //             <Row>
  //               {userCards.map((userCards, index) => (
  //                 <Col
  //                   id={`Column: ${index}`}
  //                   key={index}
  //                   xs={4}
  //                   style={{
  //                     order: userCards.position,
  //                     boxSizing: "border-box",
  //                     padding: "10px",
  //                     height: "200px",
  //                     maxHeight: "200px",
  //                     minWidth: "295px",
  //                     width: "295px",
  //                   }}
  //                 >
  //                   <Card
  //                     id="mini-card"
  //                     border={borderStyle(userCards.class)}
  //                     className="inner-card-style"
  //                     style={{
  //                       padding: "0px",
  //                       height: "100%",
  //                       maxHeight: "100%",
  //                       width: "100%",
  //                       maxWidth: "100%",
  //                     }}
  //                   >
  //                     <Card.Body style={{ padding: "5px" }}>
  //                       <Card.Title>
  //                         <Row>
  //                           <Col
  //                             xs={12}
  //                             className="text-start"
  //                             style={{ maxWidth: "280px" }}
  //                           >
  //                             {userCardText(userCards.instance)}
  //                           </Col>
  //                         </Row>
  //                       </Card.Title>
  //                       {cardType(userCards.class, userCards.instance)}
  //                     </Card.Body>
  //                   </Card>
  //                 </Col>
  //               ))}
  //             </Row>
  //           </Col>
  //         </Row>
  //       </Container>
  // );
  if (loading) {
    content = (
      <>
        <Spinner animation="border" />
      </>
    );
  } else if (userCards.length === 0) {
    content = (
      <Row>
        <Col>
          <h5>To get started please click the '+' button to add cards.</h5>
        </Col>
      </Row>
    );
  } else {
    content = (
      <Container fluid>
        <Row>
          <Col>
            <Row>
              {userCards.map((userCards, index) => (
                <Col
                  id={`Column: ${index}`}
                  key={index}
                  xs={4}
                  style={{
                    order: userCards.position,
                    boxSizing: "border-box",
                    padding: "10px",
                    height: "200px",
                    maxHeight: "200px",
                    minWidth: "295px",
                    width: "295px",
                  }}
                >
                  <Card
                    id="mini-card"
                    border={borderStyle(userCards.class)}
                    className="inner-card-style"
                    style={{
                      padding: "0px",
                      height: "100%",
                      maxHeight: "100%",
                      width: "100%",
                      maxWidth: "100%",
                    }}
                  >
                    <Card.Body style={{ padding: "5px" }}>
                      <Card.Title>
                        <Row>
                          <Col
                            xs={12}
                            className="text-start"
                            style={{ maxWidth: "280px" }}
                          >
                            <h6>{userCardText(userCards.instance)}</h6>
                          </Col>
                        </Row>
                      </Card.Title>
                      {cardType(userCards.class, userCards.instance)}
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }

  return <>{content}</>;
};
export default MiniCards;
