import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../utils/authProvider";
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Spinner,
  ButtonGroup,
  Form,
  FloatingLabel,
  Dropdown,
} from "react-bootstrap";
import axios from "axios";
import TopNav from "../components/navbars/Top_NavBar/topNav";
import StackedAreaChart from "../components/stackedView/stackedAreaChart";
import StackedSiteTree from "../components/stackedView/stackedSiteTree";
import GroupContainer from "../components/dataView/Groups/groupsNew";
import "../components/stackedView/stackedStyle.css";
import { use } from "echarts";

const StackedView = () => {
  const navigate = useNavigate();
  const { getAuth, refreshCheck, authCheck } = useAuthContext();

  const [lastKnownGroup, setLastKnownGroup] = useState(0);
  const [groupId, setGroupId] = useState(0);
  const [groupPicked, setGroupPicked] = useState(false);

  const [noDataMessage, setNoDataMessage] = useState(
    "Please select at least one site to display data."
  );

  const [selectedRadioTypeOption, setSelectedRadioTypeOption] =
    useState("date_range");
  const [selectedRadioGroupOption, setSelectedRadioGroupOption] =
    useState("site_list");
  const [selectedRadioProductOption, setSelectedRadioProductOption] =
    useState("show_gas");
  const [selectedRadioTotalOption, setSelectedRadioTotalOption] =
    useState("show_cumulative");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [validDates, setValidDates] = useState(false);

  const [dataLoading, setDataLoading] = useState(false);
  const [ddInfo, setDdInfo] = useState({});
  const [stackDataLoaded, setStackDataLoaded] = useState(false);

  // const [lastKnownSiteArray, setLastKnownSiteArray] = useState([]);
  // const [lastKnownGroupID, setLastKnownGroup] = useState(0);
  const [daysBack, setDaysBack] = useState(30);
  const [groupName, setGroupName] = useState("Select a Group");

  const [siteArray, setSiteArray] = useState([]);

  useEffect(() => {
    const lastGroupID = localStorage.getItem("LastGroupID_DataView");
    const lastKnownGroupName = localStorage.getItem("LastGroupName_DataView");
    if (lastGroupID) {
      setLastKnownGroup(lastGroupID);
    }
    if (lastKnownGroupName) {
      setGroupName(lastKnownGroupName);
    }
    getCurrentDate();
  }, []);
  useEffect(() => {
    console.log("lastKnownGroup", lastKnownGroup);
    setGroupPicked(false);
    setGroupId(lastKnownGroup);
  }, [lastKnownGroup]);
  useEffect(() => {
    console.log("groupId", groupId);
    setGroupPicked(true);
  }, [groupId]);
  useEffect(() => {
    console.log("groupPicked", groupPicked);
  }, [groupPicked]);
  useEffect(() => {
    if (selectedRadioTypeOption === "day_zero") {
      if (siteArray.length > 0) {
        console.log("run api with day zero");
        siteListPull();
      } else {
        setStackDataLoaded(false);
      }
    } else {
      if (validDates && siteArray.length > 0) {
        console.log("run api with dates");
        siteListPull();
      } else {
        setStackDataLoaded(false);
      }
    }
  }, [siteArray]);
  useEffect(() => {
    console.log("currentDate", currentDate);
  }, [currentDate]);
  // ! This did the weird thing where it doesn't listen to the if statement and forces else if
  useEffect(() => {
    if (ddInfo && Array.isArray(ddInfo.items)) {
      console.log("ddInfo items", ddInfo.items);
      console.log("ddInfo items length", ddInfo.items.length);

      if (ddInfo.items.length > 0) {
        console.log("ddInfo good", ddInfo.items.length);
        setNoDataMessage("Please select at least one site to display data.");
        setStackDataLoaded(true);
      } else {
        console.log("ddInfo bad", ddInfo.items.length);
        setNoDataMessage(
          "No data available for the selected date range or site(s)."
        );
        setStackDataLoaded(false);
      }
    } else {
      console.log(
        "ddInfo is not properly defined or ddInfo.items is not an array"
      );
    }
  }, [ddInfo]);
  const getCurrentDate = () => {
    const date = new Date();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so add 1
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    setCurrentDate(`${year}-${month}-${day}`);
  };
  const updateStageGroupName = (dataChild) => {
    localStorage.setItem("LastGroupName_StackView", dataChild);
    setGroupName(dataChild);
  };
  const updateLastKnownGroupID = (gid) => {
    localStorage.setItem("LastGroupID_StackView", gid);
    setLastKnownGroup(gid);
  };
  const handleRadioTypeChange = (e) => {
    setSelectedRadioTypeOption(e.target.value);
  };
  const handleRadioGroupChange = (e) => {
    setSelectedRadioGroupOption(e.target.value);
  };
  const handleRadioProductChange = (e) => {
    setSelectedRadioProductOption(e.target.value);
  };
  const handleFromDateChange = (e) => {
    const newFromDate = e.target.value;
    setFromDate(newFromDate);
    validateDates(newFromDate, toDate);
  };
  const handleTotalChange = (e) => {
    setSelectedRadioTotalOption(e.target.value);
  };
  const handleToDateChange = (e) => {
    const newToDate = e.target.value;
    setToDate(newToDate);
    validateDates(fromDate, newToDate);
  };
  const validateDates = (from, to) => {
    if (from && to) {
      const fromDateObj = new Date(from);
      const toDateObj = new Date(to);
      if (fromDateObj > toDateObj) {
        alert("From date must be before or equal to the To date.");
        setToDate("");
        setValidDates(false);
      } else {
        setValidDates(true);
      }
    }
  };
  const siteListPull = async () => {
    setDataLoading(true);
    const authT = await getAuth();
    let siteArrayData = {};
    if (selectedRadioTypeOption === "date_range") {
      console.log("radio type date range options");
      siteArrayData = {
        siteIds: siteArray,
        from: fromDate,
        to: toDate,
      };
    } else {
      console.log("radio type day zero options");
      siteArrayData = {
        siteIds: siteArray,
        from: "1980-01-01",
        to: currentDate,
      };
    }
    const options = {
      method: "POST",
      url: "https://api.pramsplus.com/daily_site",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authT}`,
      },
      data: siteArrayData,
    };

    if (authT !== false) {
      console.log("options", options);
      try {
        const res = await axios.request(options);
        console.log("site list api call success");
        console.log("data", res.data.items);
        setDataLoading(false);
        console.log("ds pull res", res.data);
        setDdInfo(res.data);
        return;
      } catch (err) {
        console.log(err.response.status);
        if (err.response.status === 401) {
          const valid = await refreshCheck("siteListStackedViewPull");
          if (valid === true) {
            console.log("Retrying siteListStackedViewPull api call");
            siteListPull();
          } else if (valid === false) {
            console.log("all retries failed siteListStackedViewPull");
            navigate("/login");
          }
        } else {
          console.log(
            "Redirecting to login something went wrong in siteListStackedViewPull"
          );
          navigate("/login");
        }
        return;
      }
    }
    return;
  };

  return (
    <>
      <TopNav />
      <Container fluid id="StackedChartContainer">
        <Row id="MainStackRow">
          <Col md={3} id="SiteTreeSV">
            <Row>
              <Form>
                <Row>
                  <Col>
                    <Form.Check
                      type={"radio"}
                      id={`date_range_radio`}
                      label={`Date Range`}
                      checked={selectedRadioTypeOption === "date_range"}
                      value={"date_range"}
                      onChange={handleRadioTypeChange}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      // disabled
                      type={"radio"}
                      id={`day_zero_radio`}
                      label={`Day Zero`}
                      checked={selectedRadioTypeOption === "day_zero"}
                      value={"day_zero"}
                      onChange={handleRadioTypeChange}
                    />
                  </Col>
                </Row>
              </Form>
            </Row>
            <Row>
              <Form>
                <Row>
                  <Col>
                    <Form.Check
                      type={"radio"}
                      id={`site_list_radio`}
                      label={`By Site List`}
                      checked={selectedRadioGroupOption === "site_list"}
                      value={"site_list"}
                      onChange={handleRadioGroupChange}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      // disabled
                      type={"radio"}
                      id={`group_list_radio`}
                      label={`By Group`}
                      checked={selectedRadioGroupOption === "group_list"}
                      value={"group_list"}
                      onChange={handleRadioGroupChange}
                    />
                  </Col>
                </Row>
              </Form>
            </Row>
            <Row>
              <Form>
                <Row>
                  <Col>
                    <Form.Check
                      type={"radio"}
                      id={`gas_radio`}
                      label={`Gas`}
                      checked={selectedRadioProductOption === "show_gas"}
                      value={"show_gas"}
                      onChange={handleRadioProductChange}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type={"radio"}
                      id={`oil_radio`}
                      label={`Oil`}
                      checked={selectedRadioProductOption === "show_oil"}
                      value={"show_oil"}
                      onChange={handleRadioProductChange}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type={"radio"}
                      id={`water_radio`}
                      label={`Water`}
                      checked={selectedRadioProductOption === "show_water"}
                      value={"show_water"}
                      onChange={handleRadioProductChange}
                    />
                  </Col>
                </Row>
              </Form>
            </Row>
            {selectedRadioGroupOption === "site_list" ? (
              <></>
            ) : (
              <Row id="group_button" style={{ marginBottom: "5px" }}>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    style={{
                      width: "285px",
                    }}
                  >
                    {groupName}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{
                      width: "275px",
                    }}
                  >
                    <GroupContainer
                      updateStageGroupName={updateStageGroupName}
                      updateLastKnownGroupID={updateLastKnownGroupID}
                      getAuth={getAuth}
                      refreshCheck={refreshCheck}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Row>
            )}
            {selectedRadioTypeOption === "date_range" ? (
              <>
                <Row>
                  <Col>
                    <ButtonGroup>
                      <FloatingLabel controlId="floatingInput" label="From:">
                        <Form.Control
                          type="date"
                          value={fromDate}
                          onChange={handleFromDateChange}
                        />
                      </FloatingLabel>
                      <FloatingLabel controlId="floatingInput" label="To:">
                        <Form.Control
                          type="date"
                          value={toDate}
                          onChange={handleToDateChange}
                        />
                      </FloatingLabel>
                    </ButtonGroup>
                  </Col>
                </Row>
                <br />
                {selectedRadioGroupOption === "site_list" ? (
                  <StackedSiteTree
                    setSiteArray={setSiteArray}
                    siteOrGroup={selectedRadioGroupOption}
                    groupId={groupId}
                  />
                ) : (
                  <>
                    {groupPicked === true ? (
                      <>
                        {groupId === 0 ? (
                          <></>
                        ) : (
                          <StackedSiteTree
                            setSiteArray={setSiteArray}
                            siteOrGroup={selectedRadioGroupOption}
                            groupId={groupId}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <StackedSiteTree
                          setSiteArray={setSiteArray}
                          siteOrGroup={selectedRadioGroupOption}
                          groupId={groupId}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {selectedRadioGroupOption === "site_list" ? (
                  <StackedSiteTree
                    setSiteArray={setSiteArray}
                    siteOrGroup={selectedRadioGroupOption}
                    groupId={groupId}
                  />
                ) : (
                  <>
                    {groupPicked === true ? (
                      <>
                        {groupId === 0 ? (
                          <></>
                        ) : (
                          <StackedSiteTree
                            setSiteArray={setSiteArray}
                            siteOrGroup={selectedRadioGroupOption}
                            groupId={groupId}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <StackedSiteTree
                          setSiteArray={setSiteArray}
                          siteOrGroup={selectedRadioGroupOption}
                          groupId={groupId}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Col>
          <Col md={9} id="StackedChartSV">
            <Row>
              <Col>
                <Form.Check
                  type={"radio"}
                  id={`show_totals_radio`}
                  label={`By Cumulative`}
                  checked={selectedRadioTotalOption === "show_cumulative"}
                  value={"show_cumulative"}
                  onChange={handleTotalChange}
                />
                <Form.Check
                  type={"radio"}
                  id={`show_totals_radio`}
                  label={`By Daily Sum`}
                  checked={selectedRadioTotalOption === "show_total"}
                  value={"show_total"}
                  onChange={handleTotalChange}
                />
              </Col>
              <Col>
                <Form.Check
                  type={"radio"}
                  id={`date_range_radio`}
                  label={`Date Range`}
                  checked={selectedRadioTypeOption === "date_range"}
                  value={"date_range"}
                  onChange={handleRadioTypeChange}
                />
                <Form.Check
                  type={"radio"}
                  id={`date_range_radio`}
                  label={`Date Range`}
                  checked={selectedRadioTypeOption === "date_range"}
                  value={"date_range"}
                  onChange={handleRadioTypeChange}
                />
              </Col>
              <Col>
                <Form.Check
                  type={"radio"}
                  id={`date_range_radio`}
                  label={`Date Range`}
                  checked={selectedRadioTypeOption === "date_range"}
                  value={"date_range"}
                  onChange={handleRadioTypeChange}
                />
              </Col>
            </Row>
            <br />
            {stackDataLoaded ? (
              <StackedAreaChart
                ddInfo={ddInfo}
                totalOrCum={selectedRadioTotalOption}
                type={selectedRadioTypeOption}
                product={selectedRadioProductOption}
              />
            ) : (
              <>
                <br />
                <h6
                  style={{
                    color: "Black",
                  }}
                >
                  {noDataMessage}
                </h6>
                <br />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default StackedView;
