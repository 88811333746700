const fieldOptions = [
  {
    value: "Analyst",
    key: "analyst_id",
    gmKey: "u2.username",
  },
  {
    value: "Area",
    key: "area",
    gmKey: "s.area",
  },
  {
    value: "API #",
    key: "api",
    gmKey: "s.api",
  },
  {
    value: "County",
    key: "county",
    gmKey: "s.county",
  },
  {
    value: "District",
    key: "district",
    gmKey: "s.district",
  },
  {
    value: "District Code",
    key: "district",
    gmKey: "s.district_code",
  },
  {
    value: "District Name",
    key: "district",
    gmKey: "s.district_name",
  },
  {
    value: "Engineer",
    key: "engineer_id",
    gmKey: "u.username",
  },
  {
    value: "Engineer Assistant",
    key: "engineer_asst",
    gmKey: "missing1",
  },
  {
    value: "Federal Lease/Agree#",
    key: "agency_lease",
    gmKey: "s.lease",
  },
  {
    value: "Site Type",
    key: "site_type",
    gmKey: "s.site_type",
  },
  {
    value: "Site Class",
    key: "site_class",
    gmKey: "s.[class]",
  },
  {
    value: "Field",
    key: "field_id",
    gmKey: "f.name",
  },
  {
    value: "Field Office",
    key: "field_office",
    gmKey: "s.field_office",
  },
  {
    value: "Foreman",
    key: "foreman",
    gmKey: "s.foreman",
  },
  {
    value: "Formation",
    key: "formation_id",
    gmKey: "m.name",
  },
  {
    value: "Lease",
    key: "lease",
    gmKey: "s.lease",
  },
  {
    value: "MMS Lease",
    key: "mms_lease",
    gmKey: "s.lease",
  },
  {
    value: "Name",
    key: "name",
    gmKey: "missing2",
  },
  {
    value: "Number",
    key: "number",
    gmKey: "missing3",
  },
  {
    value: "Operator",
    key: "operator_id",
    gmKey: "o.name",
  },
  {
    value: "Participating Area",
    key: "participating_area",
    gmKey: "missing4",
  },
  {
    value: "Prospect",
    key: "prospect",
    gmKey: "s.prospect",
  },
  {
    value: "Pad Name",
    key: "pad_name",
    gmKey: "s.pad_name",
  },
  {
    value: "Route",
    key: "route_id",
    gmKey: "r.name",
  },
  {
    value: "Report Federal Start",
    key: "fed_start",
    gmKey: "s.fed_start",
  },
  {
    value: "Report Federal End",
    key: "fed_end",
    gmKey: "s.fed_end",
  },
  {
    value: "Report Internal Start",
    key: "internal_start",
    gmKey: "s.internal_start",
  },
  {
    value: "Report Internal End",
    key: "internal_end",
    gmKey: "s.internal_end",
  },
  {
    value: "Report State Start",
    key: "state_start",
    gmKey: "s.state_start",
  },
  {
    value: "Report State End",
    key: "state_end",
    gmKey: "s.state_end",
  },
  {
    value: "State",
    key: "state",
    gmKey: "s.state",
  },
  {
    value: "State ID",
    key: "state_id",
    gmKey: "missing5",
  },
  {
    value: "State Permit",
    key: "state_permit",
    gmKey: "missing6",
  },
  {
    value: "Status",
    key: "status",
    gmKey: "s.status",
  },
  {
    value: "Unit",
    key: "unit",
    gmKey: "missing7",
  },
  {
    value: "User Ref",
    key: "user_ref",
    gmKey: "s.user_ref",
  },
];
const status = [
  {
    text: "Drilling",
    value: "DRG",
  },
  {
    text: "OIL: Flowing",
    value: "OFL",
  },
  {
    text: "OIL: Pumping - Rods (Beam Pump)",
    value: "OPR",
  },
  {
    text: "OIL: Pumping - Rods (Jack Pump)",
    value: "OJR",
  },
  {
    text: "OIL: Pumping - Rods (Hydraulic)",
    value: "OHR",
  },
  {
    text: "OIL: Pumping - Submersible",
    value: "OPS",
  },
  {
    text: "OIL: Pumping - Hydraulic (Fluid)",
    value: "OPH",
  },
  {
    text: "OIL: Pumping - Gas Lift",
    value: "OGL",
  },
  {
    text: "OIL: Shut-In",
    value: "OSI",
  },
  {
    text: "OIL: Temporarily Abandoned",
    value: "OTA",
  },
  {
    text: "OIL: Permanently Abandoned",
    value: "OPA",
  },
  {
    text: "OIL: Storage - Active",
    value: "OSA",
  },
  {
    text: "OIL: Storage - Shut-In",
    value: "SSI",
  },
  {
    text: "OIL: Storage - Withdrawal",
    value: "OSW",
  },
  {
    text: "CND: Flowing",
    value: "CFL",
  },
  {
    text: "CND: Pumping - Rods (Beam Pump)",
    value: "CPR",
  },
  {
    text: "CND: Pumping - Rods (Jack Pump)",
    value: "CJR",
  },
  {
    text: "CND: Pumping - Rods (Hydraulic)",
    value: "CHS",
  },
  {
    text: "CND: Pumping - Submersible",
    value: "CPS",
  },
  {
    text: "CND: Pumping - Hydraulic (Fluid)",
    value: "CPH",
  },
  {
    text: "CND: Pumping - Gas Lift",
    value: "CGL",
  },
  {
    text: "CND: Shut-In",
    value: "CSI",
  },
  {
    text: "CND: Temporarily Abandoned",
    value: "CTA",
  },
  {
    text: "CND: Permanently Abandoned",
    value: "CPA",
  },
  {
    text: "CND: Storage - Active",
    value: "CSA",
  },
  {
    text: "CND: Storage - Shut-In",
    value: "SSI",
  },
  {
    text: "CND: Storage - Withdrawal",
    value: "CSW",
  },
  {
    text: "GAS: Flowing",
    value: "GFL",
  },
  {
    text: "GAS: Pumping - Rods (Beam Pump)",
    value: "GPR",
  },
  {
    text: "GAS: Pumping - Rods (Jack Pump)",
    value: "GJR",
  },
  {
    text: "GAS: Pumping - Rods (Hydraulic)",
    value: "GHR",
  },
  {
    text: "GAS: Pumping - Submersible",
    value: "GPS",
  },
  {
    text: "GAS: Pumping - Hydraulic (Fluid)",
    value: "GPH",
  },
  {
    text: "GAS: Pumping - Gas Lift",
    value: "GGL",
  },
  {
    text: "GAS: Shut-In",
    value: "GSI",
  },
  {
    text: "GAS: Temporarily Abandoned",
    value: "GTA",
  },
  {
    text: "GAS: Permanently Abandoned",
    value: "GPA",
  },
  {
    text: "WTR: Flowing",
    value: "WFL",
  },
  {
    text: "WTR: Pumping - Rods (Beam Pump)",
    value: "WPR",
  },
  {
    text: "WTR: Pumping - Rods (Jack Pump)",
    value: "WJR",
  },
  {
    text: "WTR: Pumping - Rods (Hydraulic)",
    value: "WHR",
  },
  {
    text: "WTR: Pumping - Submersible",
    value: "WPS",
  },
  {
    text: "WTR: Pumping - Hydraulic (Fluid)",
    value: "WPH",
  },
  {
    text: "WTR: Pumping - Gas Lift",
    value: "WGL",
  },
  {
    text: "WTR: Shut-In",
    value: "WSI",
  },
  {
    text: "WTR: Temporarily Abandoned",
    value: "WTA",
  },
  {
    text: "WTR: Permanently Abandoned",
    value: "WPA",
  },
  {
    text: "LPG: Storage - Active",
    value: "LPG",
  },
  {
    text: "LPG: Storage - Shut-In",
    value: "LSI",
  },
  {
    text: "LPG: Storage - Withdrawal",
    value: "LSW",
  },
  {
    text: "GAS: Injection - Active",
    value: "GAI",
  },
  {
    text: "GAS: Injection - Shut-In",
    value: "ISI",
  },
  {
    text: "GAS: Injection - TA Site",
    value: "ITA",
  },
  {
    text: "GAS: Injection - PA Site",
    value: "IPA",
  },
  {
    text: "GAS: Injection - Air Active",
    value: "AIR",
  },
  {
    text: "GAS: Injection - Air Active Shut-In",
    value: "ASI",
  },
  {
    text: "GAS: Storage - Active",
    value: "GSA",
  },
  {
    text: "GAS: Storage - Shut-In",
    value: "SSI",
  },
  {
    text: "GAS: Storage - Withdrawal",
    value: "GSW",
  },
  {
    text: "WTR: Injection - Active",
    value: "WAI",
  },
  {
    text: "WTR: Injection - Shut-In",
    value: "ISI",
  },
  {
    text: "WTR: Injection - TA Site",
    value: "ITA",
  },
  {
    text: "WTR: Injection - PA Site",
    value: "IPA",
  },
  {
    text: "WTR: Injection - Cyclic Steam",
    value: "CSS",
  },
  {
    text: "WTR: Injection - Cyclic Steam Shut-In",
    value: "CSI",
  },
  {
    text: "WTR: Injection - Steam Flood",
    value: "SFD",
  },
  {
    text: "WTR: Injection - Steam Flood Shut-In",
    value: "SIS",
  },
  {
    text: "WTR: Disposal - Active",
    value: "DAI",
  },
  {
    text: "WTR: Disposal - Shut-In",
    value: "DSI",
  },
  {
    text: "WTR: Disposal - TA Site",
    value: "DTA",
  },
  {
    text: "WTR: Disposal - PA Site",
    value: "DPA",
  },
];
const state = [
  {
    text: "Alabama",
    value: "AL",
  },
  {
    text: "Alaska",
    value: "AK",
  },
  {
    text: "Arizona",
    value: "AZ",
  },
  {
    text: "Arkansas",
    value: "AR",
  },
  {
    text: "California",
    value: "CA",
  },
  {
    text: "Colorado",
    value: "CO",
  },
  {
    text: "Connecticut",
    value: "CT",
  },
  {
    text: "Delaware",
    value: "DE",
  },
  {
    text: "Florida",
    value: "FL",
  },
  {
    text: "Georgia",
    value: "GA",
  },
  {
    text: "Hawaii",
    value: "HI",
  },
  {
    text: "Idaho",
    value: "ID",
  },
  {
    text: "Illinois",
    value: "IL",
  },
  {
    text: "Indiana",
    value: "IN",
  },
  {
    text: "Iowa",
    value: "IA",
  },
  {
    text: "Kansas",
    value: "KS",
  },
  {
    text: "Kentucky",
    value: "KY",
  },
  {
    text: "Louisiana",
    value: "LA",
  },
  {
    text: "Maine",
    value: "ME",
  },
  {
    text: "Maryland",
    value: "MD",
  },
  {
    text: "Massachusetts",
    value: "MA",
  },
  {
    text: "Michigan",
    value: "MI",
  },
  {
    text: "Minnesota",
    value: "MN",
  },
  {
    text: "Mississippi",
    value: "MS",
  },
  {
    text: "Missouri",
    value: "MO",
  },
  {
    text: "Montana",
    value: "MT",
  },
  {
    text: "Nebraska",
    value: "NE",
  },
  {
    text: "Nevada",
    value: "NV",
  },
  {
    text: "New Hampshire",
    value: "NH",
  },
  {
    text: "New Jersey",
    value: "NJ",
  },
  {
    text: "New Mexico",
    value: "NM",
  },
  {
    text: "New York",
    value: "NY",
  },
  {
    text: "North Carolina",
    value: "NC",
  },
  {
    text: "North Dakota",
    value: "ND",
  },
  {
    text: "Ohio",
    value: "OH",
  },
  {
    text: "Oklahoma",
    value: "OK",
  },
  {
    text: "Oregon",
    value: "OR",
  },
  {
    text: "Pennsylvania",
    value: "PA",
  },
  {
    text: "Rhode Island",
    value: "RI",
  },
  {
    text: "South Carolina",
    value: "SC",
  },
  {
    text: "South Dakota",
    value: "SD",
  },
  {
    text: "Tennessee",
    value: "TN",
  },
  {
    text: "Texas",
    value: "TX",
  },
  {
    text: "Utah",
    value: "UT",
  },
  {
    text: "Vermont",
    value: "VT",
  },
  {
    text: "Virginia",
    value: "VA",
  },
  {
    text: "Washington",
    value: "WA",
  },
  {
    text: "West Virginia",
    value: "WV",
  },
  {
    text: "Wisconsin",
    value: "WI",
  },
  {
    text: "Wyoming",
    value: "WY",
  },
  {
    text: "American Samoa",
    value: "AS",
  },
  {
    text: "District of Columbia",
    value: "DC",
  },
  {
    text: "Federated States of Micronesia",
    value: "FM",
  },
  {
    text: "Guam",
    value: "GU",
  },
  {
    text: "Marshall Islands",
    value: "MH",
  },
  {
    text: "Northern Mariana Islands",
    value: "MP",
  },
  {
    text: "Palau",
    value: "PW",
  },
  {
    text: "Puerto Rico",
    value: "PR",
  },
  {
    text: "Virgin Islands",
    value: "VI",
  },
];
const comparisons = ["=", "<>"];

export { fieldOptions, state, status, comparisons };
