import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";

const DdChart = ({ ddInfo }) => {
  const chartRef = useRef(null);
  const [chart, setChart] = useState(null);

  useEffect(() => {
    console.log("data in DDCHART", ddInfo);
    if (!ddInfo || ddInfo.length === 0) {
      console.log("data is empty");
      return;
    }

    const chart = echarts.init(chartRef.current);

    const xAxisData = ddInfo.map((entry) => entry.date);
    const grossOilData = ddInfo.map((entry) => entry.grossOil || 0);
    const grossGasData = ddInfo.map((entry) => entry.grossGas || 0);
    const grossWaterData = ddInfo.map((entry) => entry.grossWater || 0);

    const option = {
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: ["Gross Oil", "Gross Gas", "Gross Water"],
      },
      xAxis: {
        type: "category",
        data: xAxisData,
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Gross Oil",
          type: "line",
          data: grossOilData,
          color: "green",
        },
        {
          name: "Gross Gas",
          type: "line",
          data: grossGasData,
          color: "red",
        },
        {
          name: "Gross Water",
          type: "line",
          data: grossWaterData,
          color: "blue",
        },
      ],
    };

    chart.setOption(option);

    const resizeChart = () => {
      chart.resize();
    };

    window.addEventListener('resize', resizeChart);

    // Clean up
    return () => {
      chart.dispose();
    };
  }, [ddInfo]);

  return <div id="ddChart" style={{ width: "100%", height: "400px" }} ref={chartRef} />;
};

export default DdChart;
