import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";

const StackedAreaChart = ({ ddInfo, totalOrCum, type, product }) => {
  const chartRef = useRef(null);
  const [chartTitle, setChartTitle] = useState("");
  const [chartProduct, setChartProduct] = useState("");
  const [chart, setChart] = useState(null);

  useEffect(() => {
    console.log("data in stacked chart", ddInfo);
    console.log("totals in stacked chart", totalOrCum);
    console.log("type in stacked chart", type);
    console.log("product in stacked chart", product);

    if (!ddInfo || ddInfo.length === 0) {
      console.log("data is empty");
      return;
    }
    if (type === "date_range") {
      setChartTitle("Production By Date");
    } else if (type === "day_zero") {
      setChartTitle("Production By Day Zero");
    }
    if (product === "show_oil") {
      setChartProduct(`grossOil`);
    } else if (product === "show_gas") {
      setChartProduct(`grossGas`);
    } else if (product === "show_water") {
      setChartProduct(`grossWater`);
    }

    const chart = echarts.init(chartRef.current);

    let xAxisData = [];
    let seriesData = [];
    let legendData = [];

    if (type === "date_range") {
      xAxisData = [...new Set(ddInfo.items.map((item) => item.date))];
      const groupedData = ddInfo.items.reduce((acc, item) => {
        if (!acc[item.siteId]) {
          acc[item.siteId] = [];
        }
        acc[item.siteId].push(item);
        return acc;
      }, {});
      console.log("grouped data", groupedData);
      legendData = Object.keys(groupedData).map((siteId) => {
        return groupedData[siteId][0].userRef;
      });
      seriesData = Object.keys(groupedData).map((siteId) => {
        return {
          name: `${groupedData[siteId][0].userRef}`,
          type: "line",
          stack: "Total",
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: groupedData[siteId].map((item) => item[chartProduct]),
        };
      });
    } else if (type === "day_zero") {
      if (totalOrCum === "show_total") {
        const dropLeadingZeros = (arr) => {
          let firstNonZeroIndex = arr.findIndex((num) => num > 0);
          if (firstNonZeroIndex === -1) {
            return [];
          }
          return arr.slice(firstNonZeroIndex);
        };

        const groupedData = ddInfo.items.reduce((acc, item) => {
          if (!acc[item.siteId]) {
            acc[item.siteId] = [];
          }
          acc[item.siteId].push(item);
          return acc;
        }, {});
        console.log("grouped data", groupedData);
        legendData = Object.keys(groupedData).map((siteId) => {
          return groupedData[siteId][0].userRef;
        });

        seriesData = Object.keys(groupedData).map((siteId) => {
          const siteData = groupedData[siteId];
          const firstDate = new Date(siteData[0].date);
          const daysSinceStart = siteData.map((item) => {
            const currentDate = new Date(item.date);
            const dayDiff = Math.floor(
              (currentDate - firstDate) / (1000 * 60 * 60 * 24)
            );
            return { day: dayDiff, value: item[chartProduct] };
          });
          const dataWithLeadingZerosDropped = dropLeadingZeros(
            daysSinceStart.map((d) => d.value)
          );
          return {
            name: `${groupedData[siteId][0].userRef}`,
            type: "line",
            stack: "Total",
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: dataWithLeadingZerosDropped,
          };
        });

        xAxisData = Array.from(
          { length: Math.max(...seriesData.map((s) => s.data.length)) },
          (_, i) => i
        );
      } else if (totalOrCum === "show_cumulative") {
        const dropLeadingZeros = (arr) => {
          let firstNonZeroIndex = arr.findIndex((num) => num > 0);
          if (firstNonZeroIndex === -1) {
            return [];
          }
          return arr.slice(firstNonZeroIndex);
        };

        const computeCumulativeSum = (arr) => {
          return arr.map(
            (
              (sum) => (value) =>
                (sum += value)
            )(0)
          );
        };

        const groupedData = ddInfo.items.reduce((acc, item) => {
          if (!acc[item.siteId]) {
            acc[item.siteId] = [];
          }
          acc[item.siteId].push(item);
          return acc;
        }, {});
        console.log("grouped data", groupedData);

        legendData = Object.keys(groupedData).map((siteId) => {
          return groupedData[siteId][0].userRef;
        });

        seriesData = Object.keys(groupedData).map((siteId) => {
          const siteData = groupedData[siteId];
          const firstDate = new Date(siteData[0].date);
          const daysSinceStart = siteData.map((item) => {
            const currentDate = new Date(item.date);
            const dayDiff = Math.floor(
              (currentDate - firstDate) / (1000 * 60 * 60 * 24)
            );
            return { day: dayDiff, value: item[chartProduct] };
          });

          const dataWithLeadingZerosDropped = dropLeadingZeros(
            daysSinceStart.map((d) => d.value)
          );
          const cumulativeData = computeCumulativeSum(
            dataWithLeadingZerosDropped
          );

          return {
            name: `${groupedData[siteId][0].userRef}`,
            type: "line",
            stack: "Total",
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: cumulativeData,
          };
        });

        xAxisData = Array.from(
          { length: Math.max(...seriesData.map((s) => s.data.length)) },
          (_, i) => i
        );
      }
    }

    console.log("series", seriesData);

    const option = {
      title: {
        text: chartTitle,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {
        data: legendData,
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: xAxisData,
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
      series: seriesData,
    };
    // Compute the total for each category
    const totals = xAxisData.map((_, index) => {
      return seriesData.reduce(
        (sum, series) => sum + (series.data[index] || 0),
        0
      );
    });

    // Add total labels on top of each stack
    seriesData.forEach((series) => {
      series.label = {
        show: false,
        position: "insideRight",
      };
    });

    // Adding a new series for totals just for showing labels on top
    option.series.push({
      name: "Total",
      type: "line",
      stack: "Total",
      label: {
        show: false,
        position: "insideTop",
        // distance: 65,
        formatter: function (params) {
          return totals[params.dataIndex];
        },
      },
      data: totals,
      itemStyle: {
        color: "transparent",
      },
    });

    chart.setOption(option);
    const resizeChart = () => {
      chart.resize();
    };
    window.addEventListener("resize", resizeChart);
    return () => {
      chart.dispose();
      window.removeEventListener("resize", resizeChart);
    };
  }, [ddInfo, type, product, chartProduct]);

  return (
    <div
      id="StackedAreaChart"
      // style={{ width: "100%", height: "100%" }}
      ref={chartRef}
    />
  );
};

export default StackedAreaChart;

// import React, { useEffect, useRef, useState } from "react";
// import * as echarts from "echarts";

// const StackedAreaChart = ({ ddInfo, type, product }) => {
//   const chartRef = useRef(null);
//   const [chartTitle, setChartTitle] = useState("");
//   const [chartProduct, setChartProduct] = useState("");
//   const [chart, setChart] = useState(null);

//   useEffect(() => {
//     console.log("data in stacked chart", ddInfo);
//     console.log("type in stacked chart", type);
//     console.log("product in stacked chart", product);

//     if (!ddInfo || ddInfo.length === 0) {
//       console.log("data is empty");
//       return;
//     }

//     if (type === "date_range") {
//       setChartTitle("Production By Date");
//     } else if (type === "day_zero") {
//       setChartTitle("Production By Day Zero");
//     }

//     if (product === "show_oil") {
//       setChartProduct(`grossOil`);
//     } else if (product === "show_gas") {
//       setChartProduct(`grossGas`);
//     } else if (product === "show_water") {
//       setChartProduct(`grossWater`);
//     }

//     const chart = echarts.init(chartRef.current);

//     const xAxisData = [...new Set(ddInfo.items.map((item) => item.date))];
//     const groupedData = ddInfo.items.reduce((acc, item) => {
//       if (!acc[item.siteId]) {
//         acc[item.siteId] = [];
//       }
//       acc[item.siteId].push(item);
//       return acc;
//     }, {});
//     console.log("grouped data", groupedData);
//     const legendData = Object.keys(groupedData).map((siteId) => {
//       return groupedData[siteId][0].userRef;
//     });
//     const seriesData = Object.keys(groupedData).map((siteId) => {
//       return {
//         name: `${groupedData[siteId][0].userRef}`,
//         type: "line",
//         stack: "Total",
//         areaStyle: {},
//         emphasis: {
//           focus: "series",
//         },
//         data: groupedData[siteId].map((item) => item[chartProduct]),
//       };
//     });

//     console.log("series", seriesData);

//     const option = {
//       title: {
//         text: chartTitle,
//       },
//       tooltip: {
//         trigger: "axis",
//         axisPointer: {
//           type: "cross",
//           label: {
//             backgroundColor: "#6a7985",
//           },
//         },
//       },
//       legend: {
//         data: legendData,
//       },
//       toolbox: {
//         feature: {
//           saveAsImage: {},
//         },
//       },
//       grid: {
//         left: "3%",
//         right: "4%",
//         bottom: "3%",
//         containLabel: true,
//       },
//       xAxis: [
//         {
//           type: "category",
//           boundaryGap: false,
//           data: xAxisData,
//         },
//       ],
//       yAxis: [
//         {
//           type: "value",
//         },
//       ],
//       series: seriesData,
//     };
//     // Compute the total for each category
//     const totals = xAxisData.map((_, index) => {
//       return seriesData.reduce(
//         (sum, series) => sum + (series.data[index] || 0),
//         0
//       );
//     });

//     // Add total labels on top of each stack
//     seriesData.forEach((series) => {
//       series.label = {
//         show: true,
//         position: "insideTop",
//       };
//     });

//     // Adding a new series for totals just for showing labels on top
//     option.series.push({
//       name: "Total",
//       type: "line",
//       stack: "Total",
//       label: {
//         show: true,
//         position: "insideTop",
//         // distance: 65,
//         formatter: function (params) {
//           return totals[params.dataIndex];
//         },
//       },
//       data: totals,
//       itemStyle: {
//         color: "transparent",
//       },
//     });

//     chart.setOption(option);
//     const resizeChart = () => {
//       chart.resize();
//     };
//     window.addEventListener("resize", resizeChart);
//     return () => {
//       chart.dispose();
//     };
//   }, [ddInfo, type, product, chartProduct]);

//   return (
//     <div
//       id="stackedAreaChart"
//       style={{ width: "100%", height: "400px" }}
//       ref={chartRef}
//     />
//   );
// };

// export default StackedAreaChart;
