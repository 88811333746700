import React, { useState, useEffect } from "react";
import { useAuthContext } from "../../../utils/authProvider";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import {
  Row,
  Col,
  Accordion,
  Form,
  InputGroup,
  Button,
  Container,
} from "react-bootstrap";
import TopNav from "../../navbars/Top_NavBar/topNav";

const styleBorder = {
  borderLeft: "1px solid #000",
  textAlign: "center",
  padding: "0",
};

const DrillDown = () => {
  const { getAuth, refreshCheck } = useAuthContext();
  const navigate = useNavigate();

  const [groupArray, setGroupArray] = useState([]);
  const [groupId, setGroupId] = useState(0);
  const [siteArray, setSiteArray] = useState([]);
  const [siteIdArray, setSiteIdArray] = useState([]);
  const [siteData, setSiteData] = useState([]);
  const [date, setDate] = useState("");
  const [instructions, setInstructions] =
    useState(`Select data to display by selecting a group and date then
  clicking the "Get Data" button.`);

  useEffect(() => {
    callGroups();
  }, []);

  useEffect(() => {
    setSiteData([]);
    if (groupId === 0) {
      return;
    } else {
      getSiteList(groupId);
    }
  }, [groupId]);

  useEffect(() => {
    console.log("site array", siteArray);
    setSiteIdArray(findValuesByValue(siteArray, "value"));
  }, [siteArray]);

  useEffect(() => {
    console.log("site id array", siteIdArray);
    if (siteIdArray.length > 0 && date !== "") {
      getSiteData();
    }
  }, [siteIdArray]);

  useEffect(() => {
    console.log(siteData);
    if (siteData.length === 0) {
      setInstructions(
        `Select a group and date then click the "Get Data" button to display data.`
      );
      return;
    }
  }, [siteData]);

  const groupChildren = (children) => {
    return children.map((item, index) => {
      const isParent = item.value;
      // console.log("isParent", isParent);
      if (isParent.includes("P")) {
        const totalGrossGas = item.children.reduce((totalGrossGas, child) => {
          return reduceItem(totalGrossGas, child, "grossGas");
        }, 0);
        const totalGrossOil = item.children.reduce((totalGrossOil, child) => {
          return reduceItem(totalGrossOil, child, "grossOil");
        }, 0);
        const totalGrossWater = item.children.reduce(
          (totalGrossWater, child) => {
            return reduceItem(totalGrossWater, child, "grossWater");
          },
          0
        );
        const totalGasAvg = item.children.reduce((totalGasAvg, child) => {
          return reduceItem(totalGasAvg, child, "average7Gas");
        }, 0);
        const totalOilVar = item.children.reduce((totalOilVar, child) => {
          return reduceItem(totalOilVar, child, "average7Oil");
        }, 0);
        const totalWaterVar = item.children.reduce((totalWaterVar, child) => {
          return reduceItem(totalWaterVar, child, "average7Water");
        }, 0);

        const totalGrossGasFix = totalGrossGas.toFixed(2);
        const totalGrossOilFix = totalGrossOil.toFixed(2);
        const totalGrossWaterFix = totalGrossWater.toFixed(2);
        const totalGasAvgFix = totalGasAvg.toFixed(2);
        const totalOilVarFix = totalOilVar.toFixed(2);
        const totalWaterVarFix = totalWaterVar.toFixed(2);

        return (
          <Accordion flush key={index}>
            <Accordion.Item eventKey="0">
              <Row
                style={{
                  borderBottom: "1px solid #000",
                  backgroundColor: "#dce9fc",
                  margin: "0",
                }}
              >
                <Col xs={3}>
                  <Accordion.Header style={{ paddingLeft: "20px" }}>
                    {item.label}
                  </Accordion.Header>
                </Col>
                <Col>{totalGrossOilFix}</Col>
                <Col>{totalOilVarFix}</Col>
                <Col>{(totalGrossOil - totalOilVarFix).toFixed(2)}</Col>
                <Col>{totalGrossGasFix}</Col>
                <Col>{totalGasAvgFix}</Col>
                <Col>{(totalGrossGas - totalGasAvgFix).toFixed(2)}</Col>
                <Col>{totalGrossWaterFix}</Col>
                <Col>{totalWaterVarFix}</Col>
                <Col>{(totalGrossWater - totalWaterVarFix).toFixed(2)}</Col>
                <Col>-</Col>
              </Row>
              <Accordion.Body
                style={{
                  padding: "0px",
                  borderStyle: "none",
                }}
              >
                {groupChildren(item.children)}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        );
      } else {
        // console.log("item", item);
        // console.log("siteData", siteData);
        const site = siteData.find(
          (site) => site.siteId === Number(item.value)
        );
        if (site == null) {
          return (
            <Row style={{ borderBottom: "1px solid #000", margin: "0" }}>
              <Col
                xs={3}
                style={{ textAlign: "center", styleBorder }}
                value={item.value}
              >
                {item.title}
              </Col>
              <Col style={styleBorder}>-</Col>
              <Col style={styleBorder}>-</Col>
              <Col style={styleBorder}>-</Col>
              <Col style={styleBorder}>-</Col>
              <Col style={styleBorder}>-</Col>
              <Col style={styleBorder}>-</Col>
              <Col style={styleBorder}>-</Col>
              <Col style={styleBorder}>-</Col>
              <Col style={styleBorder}>-</Col>
              <Col style={styleBorder}>-</Col>
            </Row>
          );
        }
        return (
          <Row style={{ borderBottom: "1px solid #000", margin: "0" }}>
            <Col
              // * Value is the User_Ref. Title is the Name + Number.
              xs={3}
              style={{ textAlign: "center", styleBorder }}
              value={item.value}
            >
              {item.title}
            </Col>
            {siteData ? (
              <>
                <Col style={styleBorder}>
                  {site.grossOil == null ? 0 : site.grossOil}
                </Col>
                <Col style={styleBorder}>
                  {site.average7Oil == null ? 0 : site.average7Oil.toFixed(2)}
                </Col>
                <Col style={styleBorder}>
                  {(site.grossOil - site.average7Oil).toFixed(2)}
                </Col>
                <Col style={styleBorder}>
                  {site.grossGas == null ? 0 : site.grossGas}
                </Col>
                <Col style={styleBorder}>
                  {site.average7Gas == null ? 0 : site.average7Gas.toFixed(2)}
                </Col>
                <Col style={styleBorder}>
                  {(site.grossGas - site.average7Gas).toFixed(2)}
                </Col>
                <Col style={styleBorder}>
                  {site.grossWater == null ? 0 : site.grossWater}
                </Col>
                <Col style={styleBorder}>
                  {site.average7Water == null
                    ? 0
                    : site.average7Water.toFixed(2)}
                </Col>
                <Col style={styleBorder}>
                  {(site.grossWater - site.average7Water).toFixed(2)}
                </Col>
                <Col style={styleBorder}>
                  {site.remarks == null || site.remarks === `""`
                    ? "-"
                    : site.remarks}
                </Col>
              </>
            ) : (
              <>
                <Col style={styleBorder}>-</Col>
                <Col style={styleBorder}>-</Col>
                <Col style={styleBorder}>-</Col>
                <Col style={styleBorder}>-</Col>
                <Col style={styleBorder}>-</Col>
                <Col style={styleBorder}>-</Col>
                <Col style={styleBorder}>-</Col>
                <Col style={styleBorder}>-</Col>
                <Col style={styleBorder}>-</Col>
              </>
            )}
          </Row>
        );
      }
    });
  };
  function findSite(siteId) {
    const site = siteData.find((site) => site.siteId === Number(siteId));
    // if (site == null) {
    //   const message = `Site with id ${siteId} not found`;
    //   throw new Error(message);
    // }
    return site;
  }

  function reduceItem(total, item, valueKey) {
    const isParent = item.value;
    if (isParent.includes("P")) {
      const childTotal = item.children.reduce((childTotal, child) => {
        return reduceItem(childTotal, child, valueKey);
      }, 0);
      return total + childTotal;
    } else {
      const site = findSite(item.value);
      if (site == null) {
        return total;
      }
      return total + site[valueKey];
    }
  }

  const displayData = () => {
    const rowsData = siteArray.map((item, index) => {
      const isParent = item.value;
      console.log("isParent", isParent);
      if (isParent.includes("P")) {
        const totalGrossGas = item.children.reduce((totalGrossGas, child) => {
          return reduceItem(totalGrossGas, child, "grossGas");
        }, 0);
        const totalGrossOil = item.children.reduce((totalGrossOil, child) => {
          return reduceItem(totalGrossOil, child, "grossOil");
        }, 0);
        const totalGrossWater = item.children.reduce(
          (totalGrossWater, child) => {
            return reduceItem(totalGrossWater, child, "grossWater");
          },
          0
        );
        const totalGasAvg = item.children.reduce((totalGasAvg, child) => {
          return reduceItem(totalGasAvg, child, "average7Gas");
        }, 0);
        const totalOilVar = item.children.reduce((totalOilVar, child) => {
          return reduceItem(totalOilVar, child, "average7Oil");
        }, 0);
        const totalWaterVar = item.children.reduce((totalWaterVar, child) => {
          return reduceItem(totalWaterVar, child, "average7Water");
        }, 0);

        const totalGrossGasFix = totalGrossGas.toFixed(2);
        const totalGrossOilFix = totalGrossOil.toFixed(2);
        const totalGrossWaterFix = totalGrossWater.toFixed(2);
        const totalGasAvgFix = totalGasAvg.toFixed(2);
        const totalOilVarFix = totalOilVar.toFixed(2);
        const totalWaterVarFix = totalWaterVar.toFixed(2);

        return (
          <Row key={index} style={{ padding: 0, margin: 0 }}>
            <Accordion flush index={index}>
              <Accordion.Item eventKey="0">
                <Row
                  style={{
                    borderBottom: "2px solid #000",
                    backgroundColor: "#cfe2ff",
                    textAlign: "center",
                    margin: "0",
                  }}
                >
                  <Col xs={3}>
                    <Accordion.Header>{item.label}</Accordion.Header>
                  </Col>
                  <Col>{totalGrossOilFix}</Col>
                  <Col>{totalOilVarFix}</Col>
                  <Col>{(totalGrossOilFix - totalOilVarFix).toFixed(2)}</Col>
                  <Col>{totalGrossGasFix}</Col>
                  <Col>{totalGasAvgFix}</Col>
                  <Col>{(totalGrossGas - totalGasAvgFix).toFixed(2)}</Col>
                  <Col>{totalGrossWaterFix}</Col>
                  <Col>{totalWaterVarFix}</Col>
                  <Col>{(totalGrossWater - totalWaterVarFix).toFixed(2)}</Col>
                  <Col>-</Col>
                </Row>
                <Accordion.Body
                  style={{
                    padding: "0px",
                    borderStyle: "none",
                  }}
                >
                  {groupChildren(item.children)}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Row>
        );
      } else {
        return null;
      }
    });
    return rowsData;
  };
  const findValuesByValue = (items, value) => {
    const values = [];
    items.forEach((item) => {
      if (!item[value].includes("P")) {
        values.push(item[value]);
      }
      if (item.children) {
        values.push(...findValuesByValue(item.children, value));
      }
    });
    return values;
  };
  const getSiteData = async () => {
    setInstructions(`Loading data for ${dayjs(date).format("MM/DD/YYYY")}...`);
    const authT = await getAuth();

    const siteArrayData = {
      siteIds: siteIdArray,
      from: date,
      to: date,
    };

    const options = {
      method: "POST",
      url: "https://api.pramsplus.com/daily_site",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authT}`,
      },
      data: siteArrayData,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.items.length === 0) {
          setInstructions(
            `No data found for that date/group, try another date.`
          );
        } else {
          setSiteData(response.data.items);
          // console.log(response.data.items);
          // console.log(options.data);
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  const getSiteList = async (groupId) => {
    const authT = await getAuth();
    // console.log(authT);
    if (authT) {
      try {
        const res = await axios.get("https://api.pramsplus.com/group-sites", {
          headers: { Authorization: `Bearer ${authT}` },
          params: { group: groupId },
        });
        // console.log("group-sites api call success");
        setSiteArray(res.data);
      } catch (err) {
        console.log(err.response.status);
        if (err.response.status === 401) {
          const valid = await refreshCheck("getSiteList");
          if (valid === true) {
            console.log("Retrying getSiteList api call");
            getSiteList();
          } else if (valid === false) {
            console.log("all retries failed getSiteList");
            navigate("/login");
          }
        } else {
          console.log(
            "Redirecting to login something went wrong in getSiteList"
          );
          navigate("/login");
        }
        return;
      }
    }
  };

  const callGroups = async () => {
    const authT = await getAuth();
    if (authT) {
      try {
        const res = await axios.get("https://api.pramsplus.com/groups", {
          headers: { Authorization: `Bearer ${authT}` },
        });
        // console.log("groups api call success");
        setGroupArray(res.data);
        // console.log(res.data);
        return res.data;
      } catch (err) {
        console.log(err.response.status);
        if (err.response.status === 401) {
          const valid = await refreshCheck("callGroups");
          if (valid === true) {
            console.log("Retrying callGroups api call");
            callGroups();
          } else if (valid === false) {
            console.log("all retries failed callGroups");
            navigate("/login");
          }
        } else {
          console.log(
            "Redirecting to login something went wrong in callGroups"
          );
          navigate("/login");
        }
        return;
      }
    }
  };

  const runFunction = (event) => {
    setDate(event.target.value);
  };

  const handleGroupChange = (event) => {
    setGroupId(event.target.value);
  };

  return (
    <>
      <TopNav />
      <Container fluid>
        <Row
          className="justify-content-md-center"
          style={{ marginTop: "10px" }}
        >
          <Col md="auto">
            <Form.Select onChange={handleGroupChange}>
              <option value={0}>Select a Group</option>
              {setGroupArray ? (
                groupArray.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))
              ) : (
                <option>No Groups</option>
              )}
            </Form.Select>
          </Col>
          <Col md="auto">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">Date</InputGroup.Text>
              <Form.Control type="date" onChange={runFunction} />
            </InputGroup>
          </Col>
          <Col md="auto">
            {groupId !== 0 && date !== "" ? (
              <Button onClick={getSiteData}>Get Data</Button>
            ) : (
              <Button disabled>Get Data</Button>
            )}
          </Col>
        </Row>
        <Row id="data-rows" style={{ marginTop: "10px", textAlign: "center" }}>
          <Row>
            {siteData.length !== 0 ? (
              <>
                <Col xs={3} style={{ fontWeight: "bold" }}>
                  Group/Site
                </Col>
                <Col style={{ fontWeight: "bold" }}>Oil</Col>
                <Col style={{ fontWeight: "bold" }}>Oil Avg</Col>
                <Col style={{ fontWeight: "bold" }}>Oil Var</Col>
                <Col style={{ fontWeight: "bold" }}>Gas</Col>
                <Col style={{ fontWeight: "bold" }}>Gas Avg</Col>
                <Col style={{ fontWeight: "bold" }}>Gas Var</Col>
                <Col style={{ fontWeight: "bold" }}>Water</Col>
                <Col style={{ fontWeight: "bold" }}>Water Avg</Col>
                <Col style={{ fontWeight: "bold" }}>Water Var</Col>
                <Col style={{ fontWeight: "bold" }}>Remarks</Col>
                {displayData()}
              </>
            ) : (
              <Col
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  fontSize: "18px",
                }}
              >
                {instructions}
              </Col>
            )}
          </Row>
        </Row>
      </Container>
    </>
  );
};

export default DrillDown;
