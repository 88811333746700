import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Login from "./pages/login";
import TopNav from "./components/navbars/Top_NavBar/topNav";
import ErrorPage from "./pages/404Page";
// import { MeterCalibrationTest } from "./pages/MeterCalibrationTest";
// import MeterCalibration from "./pages/MeterCalibration";
import { DataViewStage } from "./components/dataView/Staging/StageDataViewNew";
import PrivateRoutes from "./utils/PrivateRoutesNew";
import { AuthProvider } from "./utils/authProvider";
import TestFree from "./pages/testFree";
import QuoteApp from "./components/dnd/dndExamp";
// import TestFreeTwo from "./pages/testFreeTwo";
import DrillDown from "./components/dataView/DrillDown/drilldown";
import Reports from "./components/dataView/Reports/Reports";
import LandingPage from "./pages/Landing-Page/landing";
import GroupMaintenance from "./pages/groupMaintenance";
// import MockHome from "./components/MockHome/mockHome";
import "./App.css";
import StackedView from "./pages/stackedView";

function App() {
  useEffect(() => {
    console.log("app loaded");
  }, []);

  return (
    <Router>
      <AuthProvider>
        {/* <TopNav /> */}
        {/* <div
          id="containerInApp"
          className="container-fluid gx-0"
          style={{
            // position: "absolute",
            // top: "60px",
            // left: "0",
            // padding: 0,
            // width: "100vw",
          }}
        > */}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/testFree" element={<TestFree />} />
          <Route path="/home" element={<LandingPage />} />
          <Route path="/" element={<PrivateRoutes />}>
            <Route index element={<DataViewStage />} />
            <Route path="/dataView" element={<DataViewStage />} />
            <Route path="/stackedView" element={<StackedView />} />
            <Route path="/drillDown" element={<DrillDown />} />
            <Route path="/drReports" element={<Reports />} />
            <Route path="/groupMaintenance" element={<GroupMaintenance />} />
            <Route path="*" element={<ErrorPage />} />
          </Route>
        </Routes>
        {/* </div> */}
      </AuthProvider>
    </Router>
  );
}

export default App;
