const hierarchyOptions = [
  {
    value: "Analyst",
    key: "a.username",
  },
  {
    value: "Engineer",
    key: "e.username",
  },
  {
    value: "Field",
    key: "f.name",
  },
  {
    value: "Formation",
    key: "m.name",
  },
  {
    value: "Operator",
    key: "o.name",
  },
  {
    value: "Route",
    key: "r.name",
  },
  {
    value: "Area",
    key: "s.area",
  },
  {
    value: "County",
    key: "s.county",
  },
  {
    value: "District",
    key: "s.district",
  },
  {
    value: "District Code",
    key: "s.district_code",
  },
  {
    value: "District Name",
    key: "s.district_name",
  },
  {
    value: "Fed Rpt Stop",
    key: "s.fed_end",
  },
  {
    value: "Fed Rpt Start",
    key: "s.fed_start",
  },
  {
    value: "Field Office",
    key: "s.field_office",
  },
  {
    value: "Foreman",
    key: "s.foreman",
  },
  {
    value: "Internal Rpt Stop",
    key: "s.internal_end",
  },
  {
    value: "Internal Rpt Start",
    key: "s.internal_start",
  },
  {
    value: "Lease Name",
    key: "s.lease",
  },
  {
    value: "Pad Name",
    key: "s.pad_name",
  },
  {
    value: "Prospect",
    key: "s.prospect",
  },
  {
    value: "Site Type",
    key: "s.site_type",
  },
  {
    value: "State",
    key: "s.state",
  },
  {
    value: "State Rpt End",
    key: "s.state_end",
  },
  {
    value: "State Rpt Start",
    key: "s.state_start",
  },
  {
    value: "Status",
    key: "s.status",
  },
  {
    value: "Wellbore Profile",
    key: "s.wellbore_profile",
  },
];

export { hierarchyOptions };
