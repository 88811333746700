import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "../treeView/siteListStyle.css";

const SiteListTree = ({
  groupID,
  getAuth,
  refreshCheck,
  setSiteArray,
  lastChecked,
}) => {
  const navigate = useNavigate();

  const [nodes, setNodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [checked, setChecked] = useState([]);
  const [lastKnownChecked, setLastKnownChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filteredNodes, setFilteredNodes] = useState(nodes);

  useEffect(() => {
    console.log("nodes", nodes);
    console.log("filteredNodes", filteredNodes);
    setLastKnownChecked(lastChecked);
  }, []);
  useEffect(() => {
    console.log("lastChecked", lastChecked);
    setChecked(lastChecked);
  }, [lastChecked]);
  useEffect(() => {
    getSiteList();
  }, [groupID]);
  useEffect(() => {
    console.log("siteList nodes", nodes);
    filterTree();
  }, [nodes]);
  useEffect(() => {
    filterTree();
  }, [filterText]);
  useEffect(() => {
    console.log("Checked", checked);
    checkSiteIds(checked);
    // setSiteArray(checked);
  }, [checked]);

  const getSiteList = async () => {
    const authT = getAuth();
    setIsLoading(true);
    if (groupID == 0) {
      return;
    } else {
      if (authT) {
        try {
          const res = await axios.get(`https://api.pramsplus.com/group-sites`, {
            params: { group: groupID },
            headers: { Authorization: `Bearer ${authT}` },
          });
          console.log("site list api call success");
          setIsLoading(false);
          setNodes(res.data);
          filterTree();
          return res.data;
        } catch (err) {
          console.log(err.response.status);
          if (err.response.status === 401) {
            const valid = await refreshCheck("getSiteList");
            if (valid === true) {
              console.log("Retrying getSiteList api call");
              getSiteList();
            } else if (valid === false) {
              console.log("all retries failed getSiteList");
              navigate("/login");
            }
          } else {
            console.log(
              "Redirecting to login something went wrong in getSiteList"
            );
            navigate("/login");
          }
          return;
        }
      }
    }
  };
  const checkSiteIds = (array) => {
    const filteredItems = array.filter(array => !/[a-zA-Z]/.test(array));
    setSiteArray(filteredItems);
  };
  const onCheck = (checked) => {
    setChecked(checked);
  };
  const onExpand = (expanded) => {
    setExpanded(expanded);
  };
  const onFilterChange = (event) => {
    setFilterText(event.target.value);
  };
  const filterTree = () => {
    if (!filterText) {
      // console.log("no filter text");
      setFilteredNodes(nodes);
      return;
    }
    setFilteredNodes(nodes.reduce(filterNodes, []));
  };
  const filterNodes = (filtered, node) => {
    const children = (node.children || []).reduce(filterNodes, []);
    // console.log("children", children);
    if (
      // Node's label matches the search string
      node.label.toLocaleLowerCase().indexOf(filterText.toLowerCase()) > -1 ||
      // Or a children has a matching node
      children.length
    ) {
      filtered.push({ ...node, children });
    }
    return filtered;
  };
  if (isLoading) {
    return <>Loading...</>;
  }
  return (
    <Row style={{ width: "100%", paddingBottom: "5px" }}>
      <Col style={{ padding: 0 }}>
        <input
          className="filter-text"
          placeholder="Search..."
          type="text"
          value={filterText}
          onChange={onFilterChange}
          style={{ borderRadius: "5px", padding: "7px", width: "285px" }}
        />
        <Row
          id="RowTreeHolder"
          style={{
            overflowY: "scroll",
            maxHeight: "670px",
          }}
        >
          <CheckboxTree
            checked={checked}
            expanded={expanded}
            nodes={filteredNodes}
            showExpandAll
            expandOnClick
            onCheck={onCheck}
            onExpand={onExpand}
            showNodeIcon={false}
            // onClick={onClick}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default SiteListTree;
