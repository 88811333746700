import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CheckboxTree from "react-checkbox-tree";
import axios from "axios";
import { useAuthContext } from "../../utils/authProvider";
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Spinner,
  ButtonGroup,
  Form,
  FloatingLabel,
} from "react-bootstrap";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CloseIcon from "@mui/icons-material/Close";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { get } from "jquery";

const StackedSiteTree = ({ setSiteArray, siteOrGroup, groupId }) => {
  const navigate = useNavigate();
  const { getAuth, refreshCheck } = useAuthContext();

  const [nodes, setNodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState([]);
  const [lastKnownChecked, setLastKnownChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filteredNodes, setFilteredNodes] = useState(nodes);
  const [sidList, setSidList] = useState([]);

  useEffect(() => {
    console.log("siteOrGroup siteTree", siteOrGroup);
    console.log("groupId siteTree", groupId);

    getSiteList();
  }, []);
  // useEffect(() => {
  //   console.log("lastChecked", lastChecked);
  //   setChecked(lastChecked);
  // }, [lastChecked]);
  useEffect(() => {
    if (sidList.length === 0) {
      setSiteArray([]);
      return;
    }
    if (sidList === "undefined") {
      setSiteArray([]);
      return;
    } else if (sidList.length > 0) {
      // console.log("sidList UE", sidList);
      setSiteArray(checked);
    }
  }, [sidList]);
  useEffect(() => {
    filterTree();
  }, [nodes]);
  useEffect(() => {
    filterTree();
  }, [filterText]);
  useEffect(() => {
    setSidList(checked);
  }, [checked]);
  // * Tree functions-------------------------------------
  const onCheck = (checked) => {
    setChecked(checked);
  };
  const onExpand = (expanded) => {
    setExpanded(expanded);
  };
  const onFilterChange = (event) => {
    setFilterText(event.target.value);
  };
  const filterTree = () => {
    if (!filterText) {
      setFilteredNodes(nodes);
      return;
    }
    setFilteredNodes(nodes.reduce(filterNodes, []));
  };
  const filterNodes = (filtered, node) => {
    const children = (node.children || []).reduce(filterNodes, []);
    if (
      // * Node's label matches the search string
      node.label.toLocaleLowerCase().indexOf(filterText.toLowerCase()) > -1 ||
      // * Or a children has a matching node
      children.length
    ) {
      filtered.push({ ...node, children });
    }
    return filtered;
  };
  const getSiteList = async (manual) => {
    const getNodes = async () => {
      setLoading(true);
      const authT = await getAuth();
      let options = {};
      console.log("siteOrGroup in call", siteOrGroup);

      if (siteOrGroup === "site_list") {
        options = {
          method: "POST",
          url: "https://api.pramsplus.com/sites_tree",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authT}`,
          },
        };
      } else if (siteOrGroup === "group_list") {
        options = {
          method: "GET",
          url: "https://api.pramsplus.com/group-sites",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authT}`,
          },
          params: { group: groupId },
        };
      }
      try {
        const response = await axios.request(options);
        console.log("sites done");
        setLoading(false);
        localStorage.setItem("allSites", JSON.stringify(response.data));
        setNodes(response.data);
      } catch (err) {
        console.log(err.response.status);
        if (err.response.status === 401) {
          const valid = await refreshCheck("getSiteList");
          if (valid === true) {
            console.log("Retrying getSiteList api call");
            getSiteList();
          } else if (valid === false) {
            console.log("all retries failed getSiteList");
            navigate("/login");
          }
        } else {
          console.log(
            "Redirecting to login something went wrong in getSiteList"
          );
          navigate("/login");
        }
        return;
      }
    };
    if (manual === "manual") {
      getNodes();
    } else {
      if (siteOrGroup === "group_list") {
        if (groupId === 0) {
          return;
        } else {
          getNodes();
        }
      } else {
        const savedList = localStorage.getItem("allSites");
        if (savedList === "") {
          getNodes();
        } else if (savedList !== "") {
          const siteNodes = JSON.parse(localStorage.getItem("allSites"));
          if (siteNodes === null) {
            getNodes();
          } else if (siteNodes !== null) {
            setNodes(siteNodes);
          }
        }
      }
    }
  };

  return (
    <>
      {loading ? (
        <>
          <Row className="text-center" style={{ marginTop: "15px" }}>
            <Col>
              <Spinner animation="grow" variant="dark" />
              <Spinner animation="grow" variant="primary" />
              <Spinner animation="grow" variant="info" />
              <Spinner animation="grow" variant="secondary" />
            </Col>
          </Row>
          <Row
            className="text-center"
            style={{ marginTop: "15px", marginBottom: "5px" }}
          >
            <h4>Loading Site List</h4>
          </Row>
        </>
      ) : (
        <Container fluid>
          <Card style={{ border: "none" }}>
            <Row>
              <Col>
                <ButtonGroup>
                  <Button
                    style={{ float: "right" }}
                    variant="outline-danger"
                    onClick={() => {
                      setChecked([]);
                    }}
                  >
                    <CloseIcon />
                    Clear All
                  </Button>
                  <Button
                    style={{ float: "right" }}
                    variant="outline-primary"
                    onClick={() => {
                      getSiteList("manual");
                      return;
                    }}
                  >
                    <AutorenewIcon /> Refresh Sites
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Card.Body style={{ maxHeight: "600px", overflowY: "scroll" }}>
              <div className="filter-container">
                <Row align="center">
                  <Col>
                    <input
                      className="filter-text"
                      placeholder="Search..."
                      type="text"
                      value={filterText}
                      onChange={onFilterChange}
                      style={{ borderRadius: "5px", padding: "7px" }}
                    />
                  </Col>
                  <Col>
                    <h6>Sites Selected: {checked.length}</h6>
                  </Col>
                </Row>
                <br />
                <CheckboxTree
                  checked={checked}
                  expanded={expanded}
                  nodes={filteredNodes}
                  showExpandAll
                  expandOnClick
                  onCheck={onCheck}
                  onExpand={onExpand}
                  showNodeIcon={false}
                />
              </div>
            </Card.Body>
          </Card>
        </Container>
      )}
    </>
  );
};
export default StackedSiteTree;
