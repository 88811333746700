import React, { useEffect, useState } from "react";
import { useAuthContext } from "./authProvider";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const PrivateRoutes = () => {
  const location = useLocation();
  const { authCheck } = useAuthContext();

  const [checking, setChecking] = useState(true);
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    console.log("PR - PrivateRoutes loaded");
  }, []);

  useEffect(() => {
    console.log(
      "PR - PrivateRoutes useEffect location.pathname",
      location.pathname
    );
    const check = async () => {
      setChecking(true);
      const result = await authCheck();
      setAuthorized(result);
      setChecking(false);
    };
    check();
  }, [location.pathname]);

  if (checking === true) {
    return <div>Checking...</div>;
  }
  if (authorized === true) {
    console.log("PR - Auth Check True");
    return <Outlet />;
  } else {
    console.log("PR - Auth Check False");
    return <Navigate to="/login" />;
  }
};
export default PrivateRoutes;
