import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// * Initialize context for auth
const AuthContext = createContext();
// * Custom hook to provide immediate usage of the auth context in other components
export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  // * Allow RRD to navigate to other pages
  const navigate = useNavigate();

  const [cookieHolder, setCookieHolder] = useState([]);
  const [authLoading, setAuthLoading] = useState(false);
  const [authError, setAuthError] = useState();

  useEffect(() => {
    // console.log("authProvider useEffect started");
    setCookieHolder((current) => {
      if (current.length === 0) {
        return parseCookie(document.cookie);
      }
      return current;
    });
    // ! This will auto check to see if they are still logged in but I'm going to disable it for now
    // const refreshTimer = async () => {
    //   const validToken = await refreshCheck();
    //   if (validToken) {
    //     return;
    //   }
    // };
    // refreshTimer();
    // const intervalId = setInterval(refreshTimer, 5 * 60 * 1000);
    // return () => {
    //   clearInterval(intervalId);
    // };
  }, []);
  const saveToken = (res) => {
    console.log("Save Token res.data", res.data);
    if (res.data.accessToken === null || res.data.refreshToken === null) {
      return;
    }
    const held = {
      tokenA: res.data.accessToken,
      tokenR: res.data.refreshToken,
      userId: res.data.userId,
    };
    setCookieHolder([held]);
    document.cookie = `tokenA=${res.data.accessToken}`;
    document.cookie = `tokenR=${res.data.refreshToken}`;
    document.cookie = `userId=${res.data.userId}`;
    sessionStorage.setItem("tA", res.data.accessToken);
    sessionStorage.setItem("uId", res.data.userId);
  };
  const login = async (
    company,
    username,
    password,
    rememberUserName,
    rememberPassword
  ) => {
    try {
      setAuthLoading(true);
      setAuthError(undefined);
      const res = await axios.post("https://api.pramsplus.com/auth", {
        company,
        username,
        password: password,
      });
      saveToken(res);
      localStorage.setItem("keepSessionStorage", "true");
      if (
        (rememberUserName == 1 && rememberPassword == null) ||
        rememberPassword == 0
      ) {
        // console.log("Remember User Checked");
        localStorage.setItem("companyName", company);
        localStorage.setItem("userName", username);
        localStorage.setItem("userId", res.data.userId);
      } else if (rememberUserName == 1 && rememberPassword == 1) {
        // console.log("Remember User and Password Checked");
        localStorage.setItem("companyName", company);
        localStorage.setItem("userName", username);
        localStorage.setItem("userId", res.data.userId);
        localStorage.setItem("password", password);
      } else if (
        (rememberUserName == null && rememberPassword == null) ||
        (rememberPassword == 0 && rememberUserName == 0)
      ) {
        // console.log("Nothing Checked");
        localStorage.setItem("companyName", "");
        localStorage.setItem("userName", "");
        localStorage.setItem("password", "");
      }
      setAuthLoading(false);
      navigate("/home");
    } catch (err) {
      setAuthLoading(false);
      console.log(err.response.status);
      if (err.response.status === 401) {
        setAuthError("Incorrect Company, Username, or Password");
        return;
      }
      setAuthError("Internal Error");
      return;
    }
  };
  // * Grabbing entire cookie and splitting it
  const parseCookie = (cookie) => {
    if (cookie) {
      cookie
        .split(";")
        .map((v) => v.split("="))
        .reduce((acc, v) => {
          acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(
            v[1].trim()
          );
          return acc;
        }, {});
    }
    return [];
  };
  // * Grabbing specific cookie by name
  const getCookie = (name) => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith(name))
      ?.split("=")[1];
    return cookieValue;
  };
  const getAuth = () => {
    if (sessionStorage.getItem("tA") === null) {
      return false;
    } else {
      const sessionLength = sessionStorage.getItem("tA").length;
      if (sessionLength > 0) {
        const authT = sessionStorage.getItem("tA");
        return authT;
      }
    }
  };
  const authCheck = async () => {
    const authA = getAuth();
    const sessT = sessionStorage.getItem("tA");
    if (authA === sessT) {
      return true;
    }
    const valid = await refreshCheck("authCheck");
    if (valid) {
      return true;
    }
    return false;
  };
  const refreshCheck = async (props) => {
      console.log(`RC try started due to ${props}`);
      const rTokenCheck = getCookie("tokenR");
      // console.log("refreshCheck rTokenCheck", rTokenCheck);
      if (!rTokenCheck) {
        console.log("RC False S1 - No Token");
        return false;
      } else {
        setAuthLoading(true);
        const auth = getAuth();
        const options = {
          method: "POST",
          url: "https://api.pramsplus.com/refresh",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
          },
          data: {
            refreshToken: `${rTokenCheck}`,
          },
        };
        console.log("RC options", options);
        try {
          const response = await axios.request(options);
          console.log("RC response", response.data);
          console.log("RC response valid", response.data.valid);
          if (response.data.valid === true) {
            if (response.data.newTokensIssued === true) {
              console.log("RC true - new tokens issued");
              saveToken(response);
              setAuthLoading(false);
              return true;
            } else if (response.data.newTokensIssued === false) {
              console.log("RC true - old tokens issued");
              setAuthLoading(false);
              return true;
            }
          }
        } catch (error) {
          setAuthLoading(false);
          console.log("RC error", error);
          logout();
          navigate("/login");
        }
      };
    };
  const redirectLogin = () => {
    navigate("/login");
  };
  const logout = () => {
    const rememberUserName = localStorage.getItem("rememberUser");
    // console.log('rememberUserName', rememberUserName);
    const rememberPassword = localStorage.getItem("rememberPass");
    // console.log('rememberPassword', rememberPassword);
    if (
      (rememberUserName == 1 && rememberPassword == null) ||
      (rememberUserName == 1 && rememberPassword == 0)
    ) {
      // console.log("username checked");
      document.cookie = `tokenR=`;
      document.cookie = `tokenA=`;
      document.cookie = `userId=`;
      localStorage.setItem("rememberPass", "");
      localStorage.setItem("LastGroupID_DataView", "");
      localStorage.setItem("LastGroupName_DataView", "");
      localStorage.setItem("LastSiteArray_DataView", "");
      localStorage.setItem("allSites", "");
      sessionStorage.clear();
      setCookieHolder([]);
      redirectLogin();
    } else if (rememberPassword == 1 && rememberUserName == 1) {
      // console.log("username and password checked");
      document.cookie = `tokenR=`;
      document.cookie = `tokenA=`;
      document.cookie = `userId=`;
      localStorage.setItem("LastGroupID_DataView", "");
      localStorage.setItem("LastGroupName_DataView", "");
      localStorage.setItem("LastSiteArray_DataView", "");
      localStorage.setItem("allSites", "");
      sessionStorage.clear();
      setCookieHolder([]);
      redirectLogin();
    } else {
      // console.log("nothing checked");
      document.cookie = `tokenR=`;
      document.cookie = `tokenA=`;
      document.cookie = `userId=`;
      sessionStorage.clear();
      localStorage.setItem("LastGroupID_DataView", "");
      localStorage.setItem("LastGroupName_DataView", "");
      localStorage.setItem("LastSiteArray_DataView", "");
      localStorage.setItem("rememberUser", "");
      localStorage.setItem("rememberPass", "");
      localStorage.setItem("UserList", "");
      localStorage.setItem("allSites", "");
      localStorage.setItem("companyName", "");
      localStorage.setItem("userName", "");
      localStorage.setItem("password", "");
      localStorage.setItem("allSites", "");
      setCookieHolder([]);
      redirectLogin();
    }
  };
  const clearError = () => {
    setAuthError(undefined);
  };
  const contextFunctions = {
    authLoading,
    authError,
    setAuthError,
    clearError,
    getAuth,
    authCheck,
    refreshCheck,
    redirectLogin,
    logout,
    login,
  };
  return (
    <AuthContext.Provider value={contextFunctions}>
      {children}
    </AuthContext.Provider>
  );
};
