import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuthContext } from "../../../utils/authProvider";
import {
  Container,
  Row,
  Col,
  Spinner,
  Button,
  Modal,
  Table,
  ButtonGroup,
  Form,
  InputGroup,
  ListGroup,
  Card,
  Alert,
} from "react-bootstrap";
import StaticSiteSearchTool from "../../../utils/StaticSiteSearch";
import { fieldOptions } from "../../../utils/initDynamicOptions";
import DynamicApiCalls from "./dynamicApiCalls";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";
import "./SevenDayStyle.css";

const OilCardToday = (props) => {
  const navigate = useNavigate();
  const { getAuth, refreshCheck } = useAuthContext();

  const [todaysDate, setTodaysDate] = useState("");
  const [yesterdaysDate, setYesterdaysDate] = useState("");
  const [userCardOptions, setUserCardOptions] = useState([]);
  const [userDynamicOptions, setUserDynamicOptions] = useState([]);
  const [instance, setInstance] = useState([]);
  const [xref, setXref] = useState([]);
  const [cardNameForm, setCardNameForm] = useState({});
  const [cardName, setCardName] = useState("");

  const [filteredDynamicOptions, setFilteredDynamicOptions] = useState([]);
  const comparisonOptions = ["=", "<>"];
  const [valueOptions, setValueOptions] = useState({});
  const [fieldCombiOptions, setFieldCombiOptions] = useState([
    { value: "Loading", key: "Loading" },
  ]);
  const [statOpts, setStatOpts] = useState([]);

  const [dynViewSiteList, setDynViewSiteList] = useState(false);
  const [dynSites, setDynSites] = useState([]);
  const [dynSiteCount, setDynSiteCount] = useState(0);
  const [dynSiteIds, setDynSiteIds] = useState([]);
  const [urOrSn, setUrOrSn] = useState("userRef");

  const [siteIds, setSiteIds] = useState([]);
  const [treeSave, setTreeSave] = useState(false);
  const [dataToday, setDataToday] = useState([]);
  const [dataYesterday, setDataYesterday] = useState([]);
  const [loading, setLoading] = useState(false);
  const [yestPercent, setYestPercent] = useState(0);
  const [yestPercentStyle, setYestPercentStyle] = useState("");
  const [todayOil, setTodayOil] = useState("");
  const [yesterdayOil, setYesterdayOil] = useState("");

  const [showRemoveCardAlert, setShowRemoveCardAlert] = useState(false);
  const handleShowRemoveCardAlert = () => setShowRemoveCardAlert(true);
  const handleCloseRemoveCardAlert = () => setShowRemoveCardAlert(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (siteIds.length > 0) {
      setShowStaticOrDynamic("static");
      setShow(true);
    } else if (siteIds.length <= 0 && filteredDynamicOptions.length > 0) {
      setShowStaticOrDynamic("dynamic");
      setShow(true);
    } else {
      setShowStaticOrDynamic("dynamic");
      setShow(true);
    }
  };
  const [showStaticOrDynamic, setShowStaticOrDynamic] = useState("static");
  const [rows, setRows] = useState([
    {
      fieldText: "",
      fieldValue: "",
      comparison: "",
      optionValue: "",
      optionText: "",
    },
  ]);
  const [sendRows, setSendRows] = useState([
    {
      fieldValue: "",
      comparison: "",
      optionValue: "",
    },
  ]);
  useEffect(() => {
    setInstance(props.instance);
    getDates();
    getXref();
  }, []);
  useEffect(() => {
    // console.log("instance", instance);
    initStaticOrDynamic();
    setUserCardOptions(props.userCardOptions);
    setUserDynamicOptions(props.userDynamicOptions);
  }, [instance]);
  // useEffect(() => {
  //   console.log("userCardOptions effect", userCardOptions);
  // }, [userCardOptions]);
  useEffect(() => {
    // console.log("userCardOptions effect", userCardOptions);
    const instanceOptions = userCardOptions.filter(
      (option) => option.instance_id === instance
    );
    // console.log("userCardOptions effect instanceOptions", instanceOptions);
    const cardName = instanceOptions.find(
      (option) => option.option_id === "userDefName"
    )?.value;
    if (cardName === undefined) {
      setCardName("No name set");
    } else {
      setCardName(cardName);
    }
  }, [userCardOptions]);
  useEffect(() => {
    // console.log("userDynamicOptions", userDynamicOptions);
    const filteredDynamicOptionsSet = userDynamicOptions.filter(
      (option) => option.instance_id === instance
    );
    // console.log("filteredDynamicOptionsSet", filteredDynamicOptionsSet);
    setFilteredDynamicOptions(filteredDynamicOptionsSet);
    handleViewInitDynSites();
  }, [userDynamicOptions]);
  useEffect(() => {
    // console.log("siteIds", siteIds);
    // console.log("siteIds length", siteIds.length);
    if (siteIds.length > 0) {
      getOilDataToday();
      getOilDataYesterday();
    }
    return;
  }, [siteIds]);

  useEffect(() => {
    // console.log("dynSiteIds", dynSiteIds);
    // console.log("dynSiteIds length", dynSiteIds.length);
    if (dynSiteIds.length > 0) {
      getOilDataToday();
      getOilDataYesterday();
    }
    return;
  }, [dynSiteIds]);
  useEffect(() => {
    if (dataToday.length > 0) {
      setTodayOil(dataAdder(dataToday));
    }
    return;
  }, [dataToday]);
  useEffect(() => {
    if (dataYesterday.length > 0) {
      setYesterdayOil(dataAdder(dataYesterday));
    }
    return;
  }, [dataYesterday]);
  useEffect(() => {
    if (todayOil && yesterdayOil) {
      setYestPercent(todayVsYesterdayPercentage(yesterdayOil, todayOil));
    }
  }, [todayOil, yesterdayOil]);
  useEffect(() => {
    const xrefUpdated = filteredDynamicOptions.map((option) => {
      const xrefItem = fieldCombiOptions.find(
        (item) => item.key === option.fieldId
      );
      if (xrefItem) {
        return {
          ...option,
          fieldText: xrefItem.value,
          fieldId: xrefItem.key,
        };
      }
      return option;
    });
    const userDynamicOptionsSet = xrefUpdated.map((option) => {
      return {
        fieldId: option.fieldId,
        fieldText: option.fieldText,
        comparison: option.operator,
        optionValue: option.value,
        optionText: option.text,
      };
    });
    setRows(userDynamicOptionsSet);
  }, [fieldCombiOptions]);
  useEffect(() => {
    const rowsToSend = rows.map((row) => {
      return {
        field: row.fieldId,
        operator: row.comparison,
        optionValue: row.optionValue,
      };
    });
    setSendRows(rowsToSend);
  }, [rows]);
  useEffect(() => {
    if (
      sendRows.some(
        (row) =>
          row.fieldValue === "" ||
          row.comparison === "" ||
          row.optionValue === ""
      )
    ) {
      return;
    } else {
      handleViewSites("init");
    }
  }, [sendRows]);
  useEffect(() => {
    // console.log("treeSave", treeSave);
    if (treeSave === false) {
      return;
    } else if (treeSave === true) {
      // console.log("running effect");
      cleanUpOptsToSend(userCardOptions);
    }
  }, [treeSave]);
  useEffect(() => {
    // console.log("statOpts", statOpts);
    if (statOpts.length === 0) {
      return;
    } else {
      updateStaticOptions();
    }
  }, [statOpts]);
  // useEffect(() => {
  //   console.log("fieldCombiOptions", fieldCombiOptions);
  // }, [fieldCombiOptions]);
  const getDates = () => {
    const currentDateTest = new Date().toLocaleDateString("en-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    setTodaysDate(currentDateTest);
    const yesterdayDateTest = new Date(
      new Date().setDate(new Date().getDate() - 1)
    ).toLocaleDateString("en-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    setYesterdaysDate(yesterdayDateTest);
  };
  const getXref = async () => {
    // console.log("get xref started");
    const initFieldOptions = fieldOptions;
    const auth = await getAuth();
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/xref-labels",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      // console.log("xref data", response.data);
      setXref(response.data);
      setFieldCombiOptions([...response.data, ...initFieldOptions]);
    } catch (err) {
      console.log(err.response.status);
      if (err.response.status === 401) {
        const valid = await refreshCheck("getXref");
        if (valid === true) {
          console.log("Retrying groups api call");
          getXref();
        } else if (valid === false) {
          console.log("all retries failed getXref");
          navigate("/login");
        }
      } else {
        console.log("Redirecting to login something went wrong in call getXref");
        navigate("/login");
      }
      return;
    }
  };
  const getOilDataToday = async () => {
    setLoading(true);
    setDataToday([]);
    const authT = await getAuth();
    let siteIdsPrep = [];
    if (siteIds.length > 0) {
      siteIdsPrep = siteIds;
      // console.log("siteIdsPrep", siteIdsPrep);
    } else {
      siteIdsPrep = dynSiteIds;
      // console.log("dynSiteIdsPrep", dynSiteIds);
    }
    const siteArrayData = {
      siteIds: siteIdsPrep,
      from: todaysDate,
      to: todaysDate,
    };
    // console.log("site array data", siteArrayData);
    const options = {
      method: "POST",
      url: "https://api.pramsplus.com/daily_site",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authT}`,
      },
      data: siteArrayData,
    };
    if (options && siteArrayData) {
      // console.log("options", options);
      await axios
        .request(options)
        .then(function (response) {
          // console.log("response data Today", response.data.items);
          setDataToday(response.data.items);
          setLoading(false);
          siteIdsPrep = [];
        })
        .catch(function (error) {
          console.error(error);
          refreshCheck();
          // getOilData();
        });
    } else {
      // console.log("no options");
    }
  };
  const getOilDataYesterday = async () => {
    setLoading(true);
    setDataYesterday([]);
    const authT = await getAuth();
    let siteIdsPrep = [];
    if (siteIds.length > 0) {
      siteIdsPrep = siteIds;
      // console.log("siteIdsPrep", siteIdsPrep);
    } else {
      siteIdsPrep = dynSiteIds;
      // console.log("dynSiteIdsPrep", dynSiteIds);
    }
    const siteArrayData = {
      siteIds: siteIdsPrep,
      from: yesterdaysDate,
      to: yesterdaysDate,
    };
    const options = {
      method: "POST",
      url: "https://api.pramsplus.com/daily_site",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authT}`,
      },
      data: siteArrayData,
    };
    if (options && siteArrayData) {
      axios
        .request(options)
        .then(function (response) {
          // console.log("response data yest", response.data.items);
          setDataYesterday(response.data.items);
          setLoading(false);
          siteIdsPrep = [];
        })
        .catch(function (error) {
          console.error(error);
          refreshCheck();
          // getOilData();
        });
    } else {
      console.log("no options");
    }
  };
  const dataAdder = (data) => {
    const sum = data.reduce((total, item) => {
      return total + item.grossOil;
    }, 0);
    return sum.toFixed(2);
  };
  const todayVsYesterdayPercentage = (yesterday, today) => {
    const yesterdayNum = parseFloat(yesterday);
    // console.log("yesterdayNum", yesterdayNum);
    const todayNum = parseFloat(today);
    // console.log("todayNum", todayNum);
    const percentage = ((todayNum - yesterdayNum) / yesterdayNum) * 100;
    // console.log("percentage", percentage);
    if (percentage > 0) {
      setYestPercentStyle(" text-success");
      // console.log("positive");
      return `+${percentage.toFixed(2)}%`;
    } else if (percentage < 0) {
      setYestPercentStyle("text-danger");
      // console.log("negative");
      return `${percentage.toFixed(2)}%`;
    } else {
      // console.log("return 0");
      return "0%";
    }
  };
  const toggleStaticDynamic = (type) => {
    if (type === "static") {
      setShowStaticOrDynamic("static");
    }
    if (type === "dynamic") {
      setShowStaticOrDynamic("dynamic");
    }
  };
  const initStaticOrDynamic = () => {
    console.log("instance in initStaticOrDynamic", instance);
    const dynamicOptions = userDynamicOptions.filter(
      (option) => option.instance_id === instance
    );
    console.log("dynamicOptions", dynamicOptions);
    if (dynamicOptions.length > 0) {
      console.log("initStaticOrDynamic setting to dynamic");
      setShowStaticOrDynamic("dynamic");
    } else {
      const pullInstanceOptions = props.userCardOptions.filter(
        (option) => option.instance_id === instance
      );
      console.log("pullInstanceOptions", pullInstanceOptions);
      const pullStat = pullInstanceOptions.find(
        (option) => option.option_id === "staticSiteList"
      )?.value;
      console.log("initStaticOrDynamic pullStat", pullStat);
      if (pullStat === undefined) {
        console.log("initStaticOrDynamic setting to dynamic");
        setShowStaticOrDynamic("dynamic");
        return;
      } else {
        const staticSiteList = pullStat.split(",");
        if (staticSiteList.length > 0) {
          console.log("initStaticOrDynamic setting to static");
          setShowStaticOrDynamic("static");
          setSiteIds(staticSiteList);
        }
      }
    }
  };
  const handleFieldChange = (index, value, label) => {
    console.log("index f", index);
    console.log("value f", value);
    console.log("label f", label);
    setRows((current) => {
      const newRows = current.map((row, i) => {
        if (i === index) {
          return { ...row, fieldId: value, fieldText: label };
        }
        return row;
      });
      return newRows;
    });
    callDynamicApi(value);
  };
  const handleComparisonChange = (index, value) => {
    setRows((current) => {
      const newRows = current.map((row, i) => {
        if (i === index) {
          return { ...row, comparison: value };
        }
        return row;
      });
      return newRows;
    });
  };
  const handleValueChange = (index, value, label) => {
    setRows((current) => {
      const newRows = current.map((row, i) => {
        if (i === index) {
          return { ...row, optionText: label, optionValue: value };
        }
        return row;
      });
      return newRows;
    });
  };
  const addDynamicRow = () => {
    setRows((current) => [
      ...current,
      {
        fieldId: "",
        fieldText: "",
        comparison: "",
        optionValue: "",
        optionText: "",
      },
    ]);
  };
  const callDynamicApi = async (field) => {
    // console.log("callDynamicApi", field);
    const auth = await getAuth();
    const res = await DynamicApiCalls(field, auth);
    // console.log("res", res);
    setValueOptions((prevState) => ({
      ...prevState,
      [field]: res,
    }));
  };
  const handleDeleteRow = (event, index) => {
    event.preventDefault();
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };
  const handleViewSites = async (props) => {
    if (props === "init") {
      // console.log("init doing nothing");
      return;
    } else {
      // console.log("static or dynamic", showStaticOrDynamic);
      if (showStaticOrDynamic === "static") {
        // console.log("static doing nothing");
        return;
      } else {
        // console.log("dynamic doing something");
        if (props === "button") {
          setDynViewSiteList(true);
        } else {
          setDynViewSiteList(false);
        }
        const dynList = sendRows;
        const auth = await getAuth();
        const options = {
          method: "POST",
          url: "https://api.pramsplus.com/site-dynamic",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
          },
          data: dynList,
        };
        // console.log("options", options);
        try {
          const response = await axios.request(options);
          setDynSites(response.data);
          setDynSiteCount(response.data.length);
          setDynSiteIds(response.data.map((site) => site.siteId));
          setSiteIds([]);
        } catch (err) {
          console.log(err.response.status);
          if (err.response.status === 401) {
            const valid = await refreshCheck("handleViewSites");
            if (valid === true) {
              console.log("Retrying handleViewSites api call");
              handleViewSites();
            } else if (valid === false) {
              console.log("all retries failed handleViewSites");
              navigate("/login");
            }
          } else {
            console.log("Redirecting to login something went wrong in handleViewSites");
            navigate("/login");
          }
          return;
        }
      }
    }
  };
  const handleViewInitDynSites = async () => {
    if (userDynamicOptions.length === 0) {
      return;
    } else {
      const auth = await getAuth();
      // console.log("handleViewInitDynSites instance", instance);
      const initOptions = userDynamicOptions.filter(
        (option) => option.instance_id === instance
      );
      // console.log("handleViewInitDynSites initOptions", initOptions);
      const dynList = initOptions.map((option) => {
        return {
          field: option.fieldId,
          operator: option.operator,
          optionValue: option.value,
        };
      });
      // console.log("handleViewInitDynSites dynList", dynList);
      if (dynList.length === 0) {
        return;
      }
      const options = {
        method: "POST",
        url: "https://api.pramsplus.com/site-dynamic",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
        data: dynList,
      };
      // console.log("handleViewInitDynSites options", options);
      try {
        const response = await axios.request(options);
        setDynSites(response.data);
        setDynSiteCount(response.data.length);
        setDynSiteIds(response.data.map((site) => site.siteId));
        setSiteIds([]);
      } catch (err) {
        console.log(err.response.status);
        if (err.response.status === 401) {
          const valid = await refreshCheck("handleViewInitDynSites");
          if (valid === true) {
            console.log("Retrying handleViewInitDynSites api call");
            handleViewInitDynSites();
          } else if (valid === false) {
            console.log("all retries failed handleViewInitDynSites");
            navigate("/login");
          }
        } else {
          console.log("Redirecting to login something went wrong in handleViewInitDynSites");
          navigate("/login");
        }
        return;
      }
    }
  };
  const saveSitesTree = async (sites) => {
    setSiteIds(sites);
    setShow(false);
    setTreeSave(true);
    clearDynOptions();
  };
  const saveSitesButton = () => {
    if (showStaticOrDynamic === "static") {
      throw new Error("You shouldn't be able to do this!");
    } else {
      // console.log("going to save static sites", dynSites);
      updateDynamicOptions();
      updateStaticOptionsViaDyn();
    }
  };
  const updateDynamicOptions = async () => {
    // console.log("UPDATE STARTED");
    const dynList = rows;
    // console.log("dynList", dynList);
    const optsToSend = dynList.map((option, index) => {
      return {
        optionId: index,
        field: option.fieldId,
        operator: option.comparison,
        optionValue: option.optionValue,
        optionText: option.optionText,
      };
    });
    // console.log(" opts to send", optsToSend);
    const opts = {
      dynamicOptions: optsToSend,
    };
    // console.log("opts", opts);
    // console.log("object dynList update", dynList);
    // console.log("object optInst update", optInst);
    const auth = await getAuth();
    const options = {
      method: "PATCH",
      url: `https://api.pramsplus.com/web-cards/${instance}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
      data: opts,
    };
    console.log("options", options);
    try {
      const response = await axios.request(options);
      console.log("web card patch", response);
      props.reload();
    } catch (err) {
      console.log(err.response.status);
      if (err.response.status === 401) {
        const valid = await refreshCheck("updateDynamicOptions");
        if (valid === true) {
          console.log("Retrying updateDynamicOptions api call");
          updateDynamicOptions();
        } else if (valid === false) {
          console.log("all retries failed updateDynamicOptions");
          navigate("/login");
        }
      } else {
        console.log("Redirecting to login something went wrong in updateDynamicOptions");
        navigate("/login");
      }
      return;
    }
  };
  const updateStaticOptionsViaDyn = async () => {
    console.log("UPDATE static via dyn options STARTED");
    const auth = await getAuth();
    const initOptions = userCardOptions.filter(
      (option) => option.instance_id === instance
    );
    console.log("updateStaticOptionsViaDyn initOpts", initOptions);

    const cleanedUpOpts = initOptions.filter(
      (item) => item.option_id !== "staticSiteList"
    );
    const prepareDataForAxios = (updatedOpts) => {
      const formattedOpts = updatedOpts.map((opt) => ({
        optionId: opt.optionId || opt.option_id,
        optionValue: opt.optionValue || opt.value,
      }));

      return {
        options: formattedOpts,
      };
    };
    const readyToSend = prepareDataForAxios(cleanedUpOpts);
    console.log("readyToSend", readyToSend);

    const opts = readyToSend;

    const optionsToSend = {
      method: "PATCH",
      url: `https://api.pramsplus.com/web-cards/${instance}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
      data: opts,
    };
    console.log("options to Send ", optionsToSend);

    try {
      const response = await axios.request(optionsToSend);
      console.log("web card patch static end res via dyn", response);
      setSiteIds([]);
      initStaticOrDynamic();
    } catch (err) {
      console.log(err.response.status);
      if (err.response.status === 401) {
        const valid = await refreshCheck("updateStaticOptionsViaDyn");
        if (valid === true) {
          console.log("Retrying updateStaticOptionsViaDyn api call");
          updateStaticOptionsViaDyn();
        } else if (valid === false) {
          console.log("all retries failed updateStaticOptionsViaDyn");
          navigate("/login");
        }
      } else {
        console.log("Redirecting to login something went wrong in updateStaticOptionsViaDyn");
        navigate("/login");
      }
      return;
    }
  };
  const cleanUpOptsToSend = (opts) => {
    const initOpts = opts.filter((option) => option.instance_id === instance);
    console.log("cleanUpOptsToSend initOpts", initOpts);
    const cleanedUpOpts = initOpts.filter(
      (item) => item.option_id !== "staticSiteList"
    );
    console.log("cleanUpOptsToSend cleanedUpOpts", cleanedUpOpts);

    const staticList = siteIds;
    console.log("cleanUpOptsToSend staticList", staticList);

    const stringSites = staticList.join(",");
    console.log("cleanUpOptsToSend string", stringSites);

    const updatedOpts = [
      ...cleanedUpOpts,
      {
        optionId: "staticSiteList",
        optionValue: stringSites,
      },
    ];
    console.log("cleanUpOptsToSend updatedOpts", updatedOpts);
    const prepareDataForAxios = (updatedOpts) => {
      const formattedOpts = updatedOpts.map((opt) => ({
        optionId: opt.optionId || opt.option_id,
        optionValue: opt.optionValue || opt.value,
      }));

      return {
        options: formattedOpts,
      };
    };
    const readyToGo = prepareDataForAxios(updatedOpts);
    console.log("readyToGo", readyToGo);

    setStatOpts(readyToGo);
    return;
  };
  const updateStaticOptions = async () => {
    console.log("UPDATE static options STARTED");
    const auth = await getAuth();
    const opts = statOpts;
    const optionsToSend = {
      method: "PATCH",
      url: `https://api.pramsplus.com/web-cards/${instance}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
      data: opts,
    };
    // console.log("options to Send ", optionsToSend);
    try {
      setTreeSave(false);
      setStatOpts([]);
      // console.dir(opts);
      // console.log(JSON.stringify(opts));
      const response = await axios.request(optionsToSend);
      console.log("web card patch end res", response);
      props.reload();
    } catch (err) {
      console.log(err.response.status);
      if (err.response.status === 401) {
        const valid = await refreshCheck("updateStaticOptions");
        if (valid === true) {
          console.log("Retrying updateStaticOptions api call");
          updateStaticOptions();
        } else if (valid === false) {
          console.log("all retries failed updateStaticOptions");
          navigate("/login");
        }
      } else {
        console.log("Redirecting to login something went wrong in updateStaticOptions");
        navigate("/login");
      }
      return;
    }
  };
  const clearDynOptions = async () => {
    const auth = await getAuth();
    const opts = {
      dynamicOptions: [],
    };
    const options = {
      method: "PATCH",
      url: `https://api.pramsplus.com/web-cards/${instance}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
      data: opts,
    };
    // console.log("options", options);
    try {
      const response = await axios.request(options);
      console.log("web card patch", response);
      setRows([
        {
          fieldText: "",
          fieldValue: "",
          comparison: "",
          optionValue: "",
          optionText: "",
        },
      ]);
    } catch (err) {
      console.log(err.response.status);
      if (err.response.status === 401) {
        const valid = await refreshCheck("clearDynOptions");
        if (valid === true) {
          console.log("Retrying clearDynOptions api call");
          clearDynOptions();
        } else if (valid === false) {
          console.log("all retries failed clearDynOptions");
          navigate("/login");
        }
      } else {
        console.log("Redirecting to login something went wrong in clearDynOptions");
        navigate("/login");
      }
      return;
    }
  };
  const removeCard = async () => {
    const auth = await getAuth();
    const options = {
      method: "DELETE",
      url: `https://api.pramsplus.com/web-cards/${instance}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    console.log("options", options);
    try {
      const response = await axios.request(options);
      console.log("web card deleted", response);
      handleClose();
      props.reload();
    } catch (err) {
      console.log(err.response.status);
      if (err.response.status === 401) {
        const valid = await refreshCheck("removeCard");
        if (valid === true) {
          console.log("Retrying removeCard api call");
          removeCard();
        } else if (valid === false) {
          console.log("all retries failed removeCard");
          navigate("/login");
        }
      } else {
        console.log("Redirecting to login something went wrong in removeCard");
        navigate("/login");
      }
      return;
    }
  };
  const handleCardNameChange = (e) => {
    const { name, value } = e.target;
    setCardNameForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const saveCardName = async () => {
    const auth = await getAuth();
    const initOptions = userCardOptions.filter(
      (option) => option.instance_id === instance
    );
    console.log("saveCardName initOpts", initOptions);
    const filteredOpts = initOptions.filter((option) => {
      return option.option_id !== "userDefName";
    });
    console.log(" saveCardName filteredUpOpts", filteredOpts);
    const cleanedUpOpts = filteredOpts.map((option) => {
      return {
        optionId: option.option_id,
        optionValue: option.value,
      };
    });
    console.log(" saveCardName cleanedUpOpts", cleanedUpOpts);
    const optsToSend = [
      ...cleanedUpOpts,
      {
        optionId: "userDefName",
        optionValue: cardNameForm.Card_Name,
      },
    ];
    console.log("optsToSend", optsToSend);
    const opts = {
      options: optsToSend,
    };
    const options = {
      method: "PATCH",
      url: `https://api.pramsplus.com/web-cards/${instance}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
      data: opts,
    };
    console.log("options", options);
    try {
      const response = await axios.request(options);
      console.log("web card patch", response);
      props.reload();
    } catch (err) {
      console.log(err.response.status);
      if (err.response.status === 401) {
        const valid = await refreshCheck("saveCardName");
        if (valid === true) {
          console.log("Retrying saveCardName api call");
          saveCardName();
        } else if (valid === false) {
          console.log("all retries failed saveCardName");
          navigate("/login");
        }
      } else {
        console.log("Redirecting to login something went wrong in saveCardName");
        navigate("/login");
      }
      return;
    }
  };

  return (
    <Container fluid id="oilTodayCard-container" style={{ maxHeight: "175px" }}>
      {/* <Button onClick={runTest}>Test</Button> */}
      {loading ? (
        <Row className="text-center" style={{ paddingTop: "35px" }}>
          <Col>
            <Spinner animation="border" size="lg" variant="primary" />
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col className="text-center">
              {todayOil ? (
                <h1 className={yestPercentStyle} id="today-oil">
                  {todayOil}
                </h1>
              ) : (
                <h1 className={yestPercentStyle}>-</h1>
              )}
            </Col>
          </Row>
          <Row className="text-center">
            <Col style={{ padding: "0px" }}>
              <h6>Yest: {yesterdayOil ? yesterdayOil : "-"}</h6>
            </Col>
            <Col style={{ padding: "0px" }}>
              <h6 className={yestPercentStyle}>
                {yestPercent ? yestPercent : "-%"}
              </h6>
            </Col>
          </Row>
          <Button
            id="card-button-settings"
            variant="secondary"
            onClick={() => {
              handleShow();
            }}
          >
            <MoreHorizIcon id="icon-button" />
          </Button>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Card Options - {cardName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row style={{ paddingTop: "10px" }}>
                <Alert
                  show={showRemoveCardAlert}
                  key="danger"
                  variant="danger"
                  onClose={() => handleCloseRemoveCardAlert()}
                  dismissible
                >
                  <Row>
                    <Col>
                      <h6>Are you sure you want to remove this card?</h6>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <ButtonGroup>
                      <Button variant="danger" onClick={() => removeCard()}>
                        Yes
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => handleCloseRemoveCardAlert()}
                      >
                        No
                      </Button>
                    </ButtonGroup>
                  </Row>
                </Alert>
              </Row>
              <Row>
                <h6>Card settings:</h6>
              </Row>
              <Row>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Card Name: </InputGroup.Text>
                  <Form.Control
                    placeholder=" ex. Oil for K3 pad (24 char limit)"
                    aria-label="Card Name"
                    type="text"
                    name="Card_Name"
                    value={cardNameForm.Card_Name}
                    onChange={handleCardNameChange}
                  />
                  <Button
                    id="save-card-name"
                    variant="primary"
                    onClick={() => saveCardName()}
                  >
                    Save
                  </Button>
                </InputGroup>
              </Row>
              <Row className="justify-content-end">
                <InputGroup className="mb-3">
                  <InputGroup.Text>Delete Card:</InputGroup.Text>
                  <Col sm={4}>
                    <Button
                      variant="danger"
                      onClick={() => handleShowRemoveCardAlert()}
                    >
                      Remove Card
                    </Button>
                  </Col>
                </InputGroup>
              </Row>
            </Modal.Body>
            <Modal.Body>
              <Row>
                <ButtonGroup>
                  <Button onClick={() => toggleStaticDynamic("static")}>
                    Static List
                  </Button>
                  <Button onClick={() => toggleStaticDynamic("dynamic")}>
                    Dynamic List
                  </Button>
                </ButtonGroup>
              </Row>
              <Row>
                {showStaticOrDynamic === "static" ? (
                  <StaticSiteSearchTool
                    saveSitesTree={saveSitesTree}
                    siteIdsOptions={siteIds}
                  />
                ) : (
                  <>
                    <Table hover>
                      <thead>
                        <tr className="text-center">
                          <th>Field</th>
                          <th>Comparison</th>
                          <th>Value</th>
                          <th>Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows.map((row, index) => (
                          <tr key={index}>
                            <td style={{ padding: "0px" }}>
                              <Form.Select
                                id="field"
                                value={row.fieldId}
                                onChange={(event) =>
                                  handleFieldChange(
                                    index,
                                    event.target.value,
                                    event.target.options[
                                      event.target.selectedIndex
                                    ].text
                                  )
                                }
                              >
                                <option value={rows[index].fieldValue}>
                                  {rows[index].fieldText}
                                </option>
                                {fieldCombiOptions.map((option, index) => (
                                  <option
                                    key={index}
                                    value={option.key}
                                    label={option.value}
                                  >
                                    {option.value}
                                  </option>
                                ))}
                              </Form.Select>
                            </td>
                            <td style={{ padding: "0px" }}>
                              <Form.Select
                                id="operator"
                                value={row.comparison}
                                onChange={(event) =>
                                  handleComparisonChange(
                                    index,
                                    event.target.value
                                  )
                                }
                              >
                                <option value={rows[index].comparison}>
                                  {rows[index].comparison}
                                </option>
                                {comparisonOptions.map((comparison, index) => (
                                  <option key={index} value={comparison}>
                                    {comparison}
                                  </option>
                                ))}
                              </Form.Select>
                            </td>
                            <td style={{ padding: "0px" }}>
                              <Form.Select
                                id="value"
                                value={row.value}
                                onChange={(event) =>
                                  handleValueChange(
                                    index,
                                    event.target.value,
                                    event.target.options[
                                      event.target.selectedIndex
                                    ].text
                                  )
                                }
                              >
                                <option value={rows[index].optionValue}>
                                  {rows[index].optionText}
                                </option>
                                {valueOptions[row.fieldId]?.map(
                                  (valueOptions, index) => (
                                    <option
                                      key={index}
                                      value={valueOptions.value}
                                      label={valueOptions.label}
                                    >
                                      {valueOptions.label}
                                    </option>
                                  )
                                )}
                              </Form.Select>
                            </td>
                            <td
                              className="text-center"
                              style={{ padding: "0px" }}
                            >
                              <Button
                                variant="danger"
                                onClick={(event) => {
                                  handleDeleteRow(event, index);
                                }}
                              >
                                <DeleteForeverIcon />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Row>
                      {dynViewSiteList ? (
                        <Card id="Dyn-Card-Sites">
                          <Card.Header className="text-end">
                            <Row>
                              <Col>
                                <Row>Site List - {dynSiteCount}</Row>
                                <Row>
                                  Viewing by -{" "}
                                  {urOrSn === "userRef"
                                    ? "User Ref"
                                    : "Site Name"}
                                </Row>
                              </Col>
                              <Col>
                                <ButtonGroup>
                                  <Button onClick={() => setUrOrSn("userRef")}>
                                    User Ref
                                  </Button>
                                  <Button
                                    onClick={() => setUrOrSn("nameNumber")}
                                  >
                                    Site Name
                                  </Button>
                                  <Button
                                    variant="danger"
                                    onClick={() => setDynViewSiteList(false)}
                                  >
                                    <CloseIcon />
                                  </Button>
                                </ButtonGroup>
                              </Col>
                            </Row>
                          </Card.Header>
                          <Card.Body id="Dyn-Card-Site-List">
                            {urOrSn === "userRef" ? (
                              <ListGroup>
                                {dynSites.map((site, index) => (
                                  <ListGroup.Item key={index}>
                                    {site.userRef}
                                  </ListGroup.Item>
                                ))}
                              </ListGroup>
                            ) : (
                              <ListGroup>
                                {dynSites.map((site, index) => (
                                  <ListGroup.Item key={index}>
                                    {site.nameNumber}
                                  </ListGroup.Item>
                                ))}
                              </ListGroup>
                            )}
                          </Card.Body>
                        </Card>
                      ) : (
                        <></>
                      )}
                    </Row>
                    <Row>
                      <br />
                      <ButtonGroup id="bottom-buttons">
                        {/* <Button variant="success" onClick={showRow}>
                          Test
                        </Button> */}
                        <Button
                          variant="success"
                          onClick={() => {
                            addDynamicRow();
                          }}
                        >
                          Add a Filter
                        </Button>
                        <Button
                          variant="secondary"
                          onClick={() => {
                            handleViewSites("button");
                          }}
                        >
                          View Sites
                        </Button>
                      </ButtonGroup>
                    </Row>
                  </>
                )}
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  handleClose();
                }}
              >
                Close - Discard Changes
              </Button>
              {showStaticOrDynamic === "static" ? (
                <></>
              ) : (
                <Button
                  variant="primary"
                  onClick={() => {
                    saveSitesButton();
                    handleClose();
                  }}
                >
                  Save Changes
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </>
      )}
    </Container>
  );
};
export default OilCardToday;
