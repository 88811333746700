import React from "react";

const PortalNews = () => {
  return (
    <div style={{ fontFamily: "sans-serif" }}>
      <div>
        <h5 style={{ textAlign: "center" }}>
          Welcome to the new Prams Portal!
        </h5>
        <h6 style={{ textAlign: "center", fontFamily: "sans-serif" }}>
          This site is currently in the early stages of development.
        </h6>
        <h6 style={{ textAlign: "center", fontFamily: "sans-serif" }}>
          Check back here to see the new features added as the site grows.
        </h6>
      </div>
      <div>
        <h6>~05/02/2024~</h6>
        Many QOL changes have been made to the site. The site now has a new Group Maintenance page.  
      </div>
      <br />
      <div>
        <h6>~03/05/2024~</h6>
        Added in new charting to Dataview for a smoother experience and ability
        for the chart to resize to the window as its dragged around.
      </div>
      <br />
      <div>
        <h6>~03/01/2024~</h6>
        Added Mini Info Cards for Oil, Gas, and Water. Select a card then go to
        the options to change the card name and select the sites you want to
        see. The site selection can be both static and dynamic. Future
        development will include the ability to reorder the cards.Current
        development is focused on the Data View chart to better handle the data.
      </div>
      <br />
      <div>
        <h6>~11/21/2023~</h6>
        Currently developing the home page to add favorites and info cards.
      </div>
      <div>
        Implemented the Dataview, Drill Down, and Reports from the last version
        of portal.pramsplus.com with new features on the way.
      </div>
    </div>
  );
};

export default PortalNews;
