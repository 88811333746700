import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuthContext } from "../../utils/authProvider";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  ButtonGroup,
  ListGroup,
  Stack,
  Modal,
  Offcanvas,
  // OverlayTrigger,
  // Tooltip,
} from "react-bootstrap";
import PortalNews from "./portalNews";
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import StarIcon from "@mui/icons-material/Star";
// import StarBorderIcon from "@mui/icons-material/StarBorder";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AddIcon from "@mui/icons-material/Add";
import MiniCards from "../../components/Cards/miniCards";
import "./landingStyle.css";
import TopNav from "../../components/navbars/Top_NavBar/topNav";

const HomeOptionsModal = (props) => {
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Home Page Options
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "600px" }}>
        <h4>Centered Modal</h4>
        <p>Coming soon...</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
const AddMiniCardModal = (props) => {
  const handleAdd = (props) => {
    props.handleadd();
    props.onHide();
  };
  const handleSelectedCard = (cardType) => {
    props.setcard(cardType);
  };
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add A Mini Card
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "600px" }}>
        <ListGroup defaultActiveKey="">
          <ListGroup.Item disabled>
            <Row>
              <Col>
                <h5>Card Name</h5>
              </Col>
              <Col xs={1}>|</Col>
              <Col>
                <h6>Description</h6>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item
            action
            onClick={() => handleSelectedCard("McOilCardToday")}
          >
            <Row>
              <Col>
                <h5>Oil Today</h5>
              </Col>
              <Col xs={1}>|</Col>
              <Col>
                Current oil production for today with variance and yesterdays
                volumes.
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item
            action
            onClick={() => handleSelectedCard("McGasCardToday")}
          >
            <Row>
              <Col>
                <h5>Gas Today</h5>
              </Col>
              <Col xs={1}>|</Col>
              <Col>
                Current gas production for today with variance and yesterdays
                volumes.
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item
            action
            onClick={() => handleSelectedCard("McWaterCardToday")}
          >
            <Row>
              <Col>
                <h5>Water Today</h5>
              </Col>
              <Col xs={1}>|</Col>
              <Col>
                Current water production for today with variance and yesterdays
                volumes.
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button variant="secondary" onClick={() => handleAdd(props)}>
            Add Selected Card
          </Button>
          <Button onClick={props.onHide}>Close</Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};
// const renderFavTooltip = (props) => (
//   <Tooltip id="button-tooltip" {...props}>
//     Favorite this page.
//   </Tooltip>
// );
// const renderPageOptTooltip = (props) => (
//   <Tooltip id="button-tooltip" {...props}>
//     Page options.
//   </Tooltip>
// );
const LandingPage = () => {
  const { getAuth } = useAuthContext();

  const [isFavorite, setIsFavorite] = useState(false);
  const [homeOptionsModalShow, setHomeOptionsModalShow] = useState(false);
  const [miniCardModalShow, setMiniCardModalShow] = useState(false);
  const [showFavorites, setShowFavorite] = useState(false);
  const [selectedCard, setSelectedCard] = useState("");
  const [reload, setReload] = useState(true);

  const handleClose = () => setShowFavorite(false);
  const handleShow = () => setShowFavorite(true);
  const handleFavorite = () => {
    setIsFavorite(!isFavorite);
  };
  const handleReload = () => {
    console.log("handleReload");
    setReload(false);
    setTimeout(() => {
      setReload(true);
    }, 1000);
  };
  const handleSelectedMiniCard = (cardType) => {
    setSelectedCard(cardType);
  };
  const handleAddMiniCard = () => {
    console.log("selected card", selectedCard);
    postCard(selectedCard);
  };
  const postCard = async (cardType) => {
    const authT = await getAuth();

    const initCard = {
      class: `${cardType}`,
      position: 0,
      options: [],
      dynamicOptions: [],
    };
    console.log("initCard", initCard);
    const options = {
      method: "POST",
      url: "https://api.pramsplus.com/web-cards",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authT}`,
      },
      data: initCard,
    };
    console.log("options", options);
    axios
      .request(options)
      .then(function (response) {
        console.log("postCard done", response);
        handleReload();
      })
      .catch(function (error) {
        console.error("postCard error", error);
      });
  };
  return (
    <>
      <TopNav />
      <Container fluid>
        <Row id="page-header" className="home-row" style={{ color: "white" }}>
          <Col sm={4} className="text-start" style={{ paddingTop: "5px" }}>
            {/* <Button
            variant="primary"
            style={{ padding: "0px", width: "135px", height: "38px" }}
            onClick={handleShow}
          >
            <StarIcon
              style={{
                width: "25px",
                height: "25px",
                marginRight: "5px",
              }}
            />
            Favorites
          </Button> */}
            <Offcanvas show={showFavorites} onHide={handleClose}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Favorites</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <ListGroup>
                  <Stack gap={2}>
                    <ListGroup.Item
                      id="fav-item"
                      action
                      onClick={() => {
                        // navigate("/dataView");
                        // handleClose();
                      }}
                    >
                      <h6 id="menu-header">Home</h6>
                    </ListGroup.Item>
                    <ListGroup.Item
                      id="fav-item"
                      action
                      onClick={() => {
                        // navigate("/dataView");
                        // handleClose();
                      }}
                    >
                      <h6 id="menu-header">Home</h6>
                    </ListGroup.Item>
                    <ListGroup.Item
                      id="fav-item"
                      action
                      onClick={() => {
                        // navigate("/dataView");
                        // handleClose();
                      }}
                    >
                      <h6 id="menu-header">Home</h6>
                    </ListGroup.Item>
                    <ListGroup.Item
                      id="fav-item"
                      action
                      onClick={() => {
                        // navigate("/dataView");
                        // handleClose();
                      }}
                    >
                      <h6 id="menu-header">Home</h6>
                    </ListGroup.Item>
                  </Stack>
                </ListGroup>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
          <Col sm={4} className="text-center" style={{ paddingTop: "5px" }}>
            <h4 id="page-title">Home</h4>
          </Col>
          <Col sm={4} className="text-end" style={{ padding: "5px" }}>
            {/* <ButtonGroup>
            <OverlayTrigger
              placement="left"
              delay={{ show: 200, hide: 250 }}
              overlay={renderFavTooltip}
            >
              <Button
                action
                variant="secondary"
                onClick={() => {
                  handleFavorite();
                }}
              >
                {isFavorite ? <StarIcon /> : <StarBorderIcon />}
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="left"
              delay={{ show: 200, hide: 250 }}
              overlay={renderPageOptTooltip}
            >
              <Button
                style={{ padding: "0px", width: "65px" }}
                variant="primary"
                onClick={() => setHomeOptionsModalShow(true)}
              >
                <MoreHorizIcon />
              </Button>
            </OverlayTrigger>
          </ButtonGroup> */}
            <HomeOptionsModal
              show={homeOptionsModalShow}
              onHide={() => setHomeOptionsModalShow(false)}
            />
          </Col>
        </Row>
        <Row id="card-collection" style={{ paddingTop: "5px" }}>
          <Col id="left-col" lg={6} style={{ padding: "5px" }}>
            <Stack gap={3} className="d-grid gap-2">
              <Card className="card-style">
                <Card.Body>
                  <Row>
                    <Col>
                      <Card className="inner-card-style">
                        <Card.Body>
                          <Card.Title>PRAMS Portal News</Card.Title>
                          <div id="portal-news-text">
                            <PortalNews />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer id="largeCard-header">
                  <Row>
                    {/* <Col className="text-start">Mini-Cards</Col> */}
                    <Col className="text-end">
                      <ButtonGroup>
                        <Button
                          variant="secondary"
                          style={{ height: "30px", width: " 30px" }}
                        >
                          <AutorenewIcon
                            style={{
                              height: "20px",
                              width: " 20px",
                              marginLeft: "-7px",
                              marginTop: "-10px",
                            }}
                          />
                        </Button>
                        <Button style={{ height: "30px", width: " 30px" }}>
                          <AddIcon
                            style={{
                              height: "20px",
                              width: " 20px",
                              marginLeft: "-7px",
                              marginTop: "-10px",
                            }}
                          />
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Stack>
          </Col>
          <Col id="right-col" lg={6} style={{ padding: "5px" }}>
            <Card id="miniCard-master">
              <Card.Body className="card-style">
                {reload ? <MiniCards reload={handleReload} /> : <>Loading...</>}
              </Card.Body>
              <Card.Footer id="miniCard-header">
                <Row>
                  <Col className="text-end">
                    <ButtonGroup>
                      <Button
                        id="reload-miniCards-btn"
                        variant="secondary"
                        style={{ height: "30px", width: " 30px" }}
                        onClick={() => {
                          handleReload();
                        }}
                      >
                        <AutorenewIcon
                          style={{
                            height: "20px",
                            width: " 20px",
                            marginLeft: "-7px",
                            marginTop: "-10px",
                          }}
                        />
                      </Button>
                      <Button
                        id="add-miniCards-btn"
                        style={{ height: "30px", width: " 30px" }}
                        onClick={() => setMiniCardModalShow(true)}
                      >
                        <AddIcon
                          style={{
                            height: "20px",
                            width: " 20px",
                            marginLeft: "-7px",
                            marginTop: "-10px",
                          }}
                        />
                      </Button>
                    </ButtonGroup>
                    <AddMiniCardModal
                      show={miniCardModalShow}
                      setcard={(cardType) => handleSelectedMiniCard(cardType)}
                      handleadd={() => {
                        handleAddMiniCard();
                      }}
                      onHide={() => setMiniCardModalShow(false)}
                    />
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LandingPage;
