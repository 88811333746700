import React, { useEffect, useState, useRef } from "react";
import { useAuthContext } from "../utils/authProvider";
import "../components/login/login.css";

function Login(props) {
  const { login, authError, clearError, refreshCheck, logout } = useAuthContext();

  const [companyName, setCompanyName] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [rememberUserName, setRememberUserName] = useState(0);
  const [rememberPassword, setRememberPassword] = useState(0);

  useEffect(() => {
    runRefreshCheck();
    
    const rememberUser = localStorage.getItem("rememberUser");
    const rememberPass = localStorage.getItem("rememberPass");
    setRememberUserName(rememberUser);
    setRememberPassword(rememberPass);
    if (rememberUser == 1 && rememberPass == 0) {
      // console.log("rememberUser checked");
      setCompanyName(localStorage.getItem("companyName"));
      setUserName(localStorage.getItem("userName"));
    } else if (rememberUser == 1 && rememberPass == 1) {
      // console.log("rememberUser and pass checked");
      setCompanyName(localStorage.getItem("companyName"));
      setUserName(localStorage.getItem("userName"));
      setPassword(localStorage.getItem("password"));
    }
  }, []);

  const runRefreshCheck = async () => {
    const validToken = await refreshCheck("login");
    console.log("validToken on login", validToken);
    if (validToken === true) {
      return;
    } 
    console.log("on login failed logging out");
    logout();
  };
  const handleInputChange = (event) => {
    //* Getting the value and name of the input which triggered the change
    const { target } = event;
    const inputType = target.name;
    const inputValue = target.value;
    //* Based on the input type, we set the state of that type
    if (inputType === "companyName") {
      setCompanyName(inputValue);
    } else if (inputType === "userName") {
      setUserName(inputValue);
    } else {
      setPassword(inputValue);
    }
  };
  const handleRememberUser = () => {
    setRememberUserName(!rememberUserName);
    const rememberUser = localStorage.getItem("rememberUser");
    if (rememberUser === null) {
      localStorage.setItem("rememberUser", 1);
    }
    if (rememberUser == 1) {
      localStorage.setItem("rememberUser", 0);
    } else if (rememberUser == 0) {
      localStorage.setItem("rememberUser", 1);
    }
  };
  const handleRememberPass = () => {
    setRememberPassword(!rememberPassword);
    const rememberPass = localStorage.getItem("rememberPass");
    if (rememberPass === null) {
      localStorage.setItem("rememberPass", 1);
    }
    if (rememberPass == 1) {
      localStorage.setItem("rememberPass", 0);
    } else if (rememberPass == 0) {
      localStorage.setItem("rememberPass", 1);
    }
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    login(companyName, userName, password, rememberUserName, rememberPassword);
  };
  const authMessage = authError && (
    <div className="card" style={{ marginBottom: "10px" }}>
      <div className="card-header bg-warning">
        <strong>Warning</strong>
      </div>
      <div className="card-body">
        <h5 className="card-title">{authError}</h5>
        <p className="card-text">
          Try again or contact Prams for assistance: 303-904-6855.
        </p>
        <div className="btn btn-primary" onClick={clearError}>
          Close
        </div>
      </div>
    </div>
  );
  return (
    <>
      <div className="form-signIn text-center">
        <form
          className="loginForm"
          style={{
            backgroundColor: "#E9E9E9",
            padding: "5px",
            borderRadius: "10px",
            marginTop: "60px",
            boxShadow: "0 8px 16px 0 rgba(0,0,0,1)",
          }}
        >
          <img
            className="mb-4"
            src="/images/PP_logo_Working no background.png"
            alt="Prams Logo"
            width="150"
            height="150"
          />
          <h1 className="mb-3 fw-normal title">
            <span className="titleFirst">PRAMS</span>
            <span className="titleLast">Portal</span>
          </h1>
          {/* --- Company Name --- */}
          <div className="form-floating">
            <input
              className="form-control ediName"
              value={companyName}
              name="companyName"
              onChange={handleInputChange}
              type="text"
              id="floatingInput"
              placeholder="Company EDI Name"
            />
            <label htmlFor="floatingInput">Company EDI Name</label>
          </div>
          {/* --- UserName --- */}
          <div className="form-floating">
            <input
              className="form-control username"
              value={userName}
              name="userName"
              onChange={handleInputChange}
              type="text"
              id="floatingInput"
              placeholder="Username"
            />
            <label htmlFor="floatingInput">Username</label>
          </div>
          {/* --- Password --- */}
          <div className="form-floating">
            <input
              className="form-control password"
              value={password}
              name="password"
              onChange={handleInputChange}
              type="password"
              id="floatingPassword"
              placeholder="Password"
            />
            <label htmlFor="floatingPassword">Password</label>
          </div>
          {authMessage}
          {/* --- Login Submit Button--- */}
          <button
            className="w-100 btn btn-lg btn-primary"
            type="button"
            onClick={handleFormSubmit}
          >
            Log In
          </button>
          {/* --- Button trigger modal --- */}
          <button
            type="button"
            className="btn btn-primary help-modal-button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModalCenter"
          >
            Need Help?
          </button>
          <a href="https://www.goto.com/meeting/join">
            <img className="gotoLogo" src="iconGT.png" alt="Goto Logo" />
          </a>
          <br />
          {/* --- Remember Me checkbox --- */}
          <div>
            <label style={{ width: "175px" }}>
              <input
                type="checkbox"
                checked={rememberUserName}
                onChange={handleRememberUser}
                style={{ marginRight: "8px", marginTop: "10px" }}
              />
              Remember Username
            </label>
          </div>
          <div>
            <label style={{ width: "175px" }}>
              <input
                type="checkbox"
                checked={rememberPassword}
                onChange={handleRememberPass}
                style={{ marginRight: "11px", marginTop: "10px" }}
              />
              Remember Password{" "}
            </label>
            {rememberPassword && (
              <div style={{ color: "red" }}>
                * Not Recommended for public PCs{" "}
              </div>
            )}
          </div>
          {/* --- Modal--- */}
          <div
            className="modal fade"
            id="exampleModalCenter"
            tabIndex="-1"
            role="dialog"
            aria-hidden={1}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Portal Help
                  </h5>
                </div>
                <div className="modal-body">
                  Call Prams: 303-904-6855 Option 1
                  <br />
                  Email: Support@pramsplus.com
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* --- End of Modal --- */}

          <p className="mt-5 mb-3 text-muted">&copy; Prams Plus 2023</p>
        </form>
        <div className="background-image">
          <img
            id="background-image"
            src="pipeline2.png"
            alt="Prams Logo Background"
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              zIndex: "-10",
              // height: "1200px",
              width: "auto",
            }}
          />
        </div>
      </div>
    </>
  );
}
export default Login;

//NOTES--------------------------------------------------------------------------
// Use 20-React -- 16--ReactForms for help
// Fetching data in 20-React -- 17 ins_fetching_data
// GET SET data to local storage -- 04-web-api -- 21-local-storage
