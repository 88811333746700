import axios from "axios";

class AuthService {
  state = {
    cookieArrayHolder: [],
  };

  parseCookie = (str) =>
    str
      ? str
          .split(";")
          .map((v) => v.split("="))
          .reduce((acc, v) => {
            acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(
              v[1].trim()
            );
            return acc;
          }, {})
      : [];

  getAuth() {
    if (this.state.cookieArrayHolder.length === 0) {
      this.state.cookieArrayHolder.push(this.parseCookie(document.cookie));
    }
    return this.state.cookieArrayHolder[0].tokenA;
  }

  //* Checks the refresh token
  isValid() {
    this.state.cookieArrayHolder.push(this.parseCookie(document.cookie));
    let tokenRCheck = this.state.cookieArrayHolder[0].tokenR;

    return axios
      .post("https://api.pramsplus.com/refresh", {
        refreshToken: tokenRCheck,
      })
      .then((res) => {
        let validStatus = res.data.valid;
        // console.log(`The Refresh Status is ${validStatus}`);
        // this.state.cookieArrayHolder = [];
        let tokenR = res.data.refreshToken;
        let tokenA = res.data.accessToken;
        // let userId = res.data.userId;
        document.cookie = `tokenR=${tokenR}`;
        document.cookie = `tokenA=${tokenA}`;
        sessionStorage.setItem("tA", tokenA);
        // document.cookie = `userId=${userId}`;
        return validStatus;
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
        // console.log(this.state.cookieArrayHolder);
        //! May need to build a better looking page for this that auto directs them to login after x seconds.
        // alert("Your session expired, returning to login page.");
        window.location.assign("/login");
      });
  }

  authChecker() {
    let authT = this.getAuth();
    let sess = sessionStorage.getItem("tA");
    // console.log(`Session is ${sess}`);
    if (authT === sess && sess) {
      // console.log(`Auth Checker says: true`);
      return true;
    } else {
      // console.log(`Auth Checker says: False, checking the RToken`);
      let valid = this.isValid();
      if (valid) {
        return true;
      } else {
        // console.log(`Auth Checker says: No A or R Token going to login`);
        return false;
      }
    }
  }

  redirectLogin() {
    window.location.assign("/login");
  }

  logout() {
    document.cookie = `tokenR=`;
    document.cookie = `tokenA=`;
    document.cookie = `userId=`;
    sessionStorage.setItem("tA", "");
    this.state.cookieArrayHolder = [];
    this.redirectLogin();
  }
}

export default new AuthService();




// getAuth() {
  // console.log(
  //   `The cookie array length is: ${this.state.cookieArrayHolder.length}`
  // );
  // console.log(
  //   `The cookie array is: ${this.state.cookieArrayHolder}`
  // );
  // if (this.state.cookieArrayHolder.length === 0) {
    // console.log(`The array was 0`);
    // this.state.cookieArrayHolder.push(this.parseCookie(document.cookie));
  // }
  // console.log(this.state.cookieArrayHolder);
  // return this.state.cookieArrayHolder[0].tokenA;

  // if ((this.state.cookieArrayHolder.length === 0)) {
  //   this.state.cookieArrayHolder.push(this.parseCookie(document.cookie));
  //   console.log(this.state.cookieArrayHolder);
  //   console.log(
  //     `This parsed the cookie ${this.state.cookieArrayHolder[0].tokenA}`
  //   );
  //   return this.state.cookieArrayHolder[0].tokenA;
  // } else {
  //   console.log(
  //     `This already had the cookie ${this.state.cookieArrayHolder[0].tokenA}`
  //   );
  //   return this.state.cookieArrayHolder[0].tokenA;
  // }
// }