import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuthContext } from "../../../utils/authProvider";
import {
  Container,
  Card,
  Row,
  Col,
  InputGroup,
  Form,
  Button,
  Modal,
  ListGroup,
  Alert,
} from "react-bootstrap";
import dayjs from "dayjs";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SaveIcon from "@mui/icons-material/Save";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import CheckboxTree from "react-checkbox-tree";
// import { sites as nodes } from "../../../components/Testing/common";

import "react-checkbox-tree/lib/react-checkbox-tree.css";
import TopNav from "../../navbars/Top_NavBar/topNav";

const Reports = () => {
  const { getAuth } = useAuthContext();

  const [reports, setReports] = useState([]);
  const [variants, setVariants] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [allButtonSelect, setAllButtonSelect] = useState("secondary");
  const [staticButtonSelect, setStaticButtonSelect] = useState("light");
  const [nodes, setNodes] = useState([]);

  //* For run_report endpoint
  const [selectedReport, setSelectedReport] = useState("");
  const [selectedReportName, setSelectedReportName] = useState("");
  const [selectedVariant, setSelectedVariant] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [prevDay, setPrevDay] = useState(false);
  const [asPDF, setAsPDF] = useState(false);
  const [fileType, setFileType] = useState("xls");
  const [sidList, setSidList] = useState([]);
  const [optionOne, setOptionOne] = useState("");
  const [optionTwo, setOptionTwo] = useState("");
  const [optionThree, setOptionThree] = useState("");
  const [optionFour, setOptionFour] = useState("");

  //* For Save,Load,Delete Template
  const [templateModal, setTemplateModal] = useState(false);
  const [userReportName, setUserReportName] = useState("");
  const [templateDate, setTemplateDate] = useState(false);
  const [savedTemplate, setSaveTemplate] = useState(false);
  const [deletedTemplate, setDeleteTemplate] = useState(false);
  const [loadedTemplate, setLoadedTemplate] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [saveVariantV, setSaveVariantValid] = useState(false);
  const [saveReportV, setSaveReportValid] = useState(false);

  //* For Tree
  useEffect(() => {
    getReports();
    getSiteList();
  }, []);

  useEffect(() => {
    getVariants(selectedReport);
    // console.log("Selected Report", selectedReport);
    // console.log("Selected Report Name", selectedReportName);
  }, [selectedReport]);

  useEffect(() => {
    // console.log("prevDay", prevDay);
  }, [prevDay]);

  useEffect(() => {
    // console.log("fileType", fileType);
    if (fileType === "xls") {
      setAsPDF(false);
    } else {
      setAsPDF(true);
    }
  }, [fileType]);
  useEffect(() => {
    // console.log("asPDF", asPDF);
  }, [asPDF]);

  useEffect(() => {
    setTimeout(() => setLoadedTemplate(false), 1 * 60 * 1000);
  }, [loadedTemplate]);

  const getReports = async () => {
    const authT = await getAuth();
    const options = {
      method: "POST",
      url: "https://api.pramsplus.com/reports",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authT}`,
      },
    };
    axios
      .request(options)
      .then(function (response) {
        console.log("reports", response.data);
        setReports(response.data.items);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  const getVariants = (selectedReport) => {
    setVariants([]);
    const foundObject = reports.find(
      (item) => item.fileName === selectedReport
    );
    if (foundObject) {
      setVariants(foundObject.variants);
    }
    return;
  };
  const getSiteList = async () => {
    const authT = await getAuth();
    const options = {
      method: "POST",
      url: "https://api.pramsplus.com/sites_tree",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authT}`,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log("sites", response.data);
        setNodes(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  const handleSelectChange = (e) => {
    setSelectedReport(e.target.value);
    setSelectedReportName(e.target.selectedOptions[0].id);
    setSaveReportValid(true);
  };
  const handleVariantChange = (e) => {
    setSelectedVariant(e.target.value);
    setSaveVariantValid(true);
  };
  const handleFileType = (e) => {
    setFileType(e.target.value);
  };
  const handleDate = (e) => {
    setSelectedDate(e.target.value);
  };
  const staticSelect = () => {
    setAllButtonSelect("light");
    setStaticButtonSelect("secondary");
    setModalShow(true);
  };
  const templateSelect = () => {
    setTemplateModal(!templateModal);
  };
  const allSelect = () => {
    setAllButtonSelect("secondary");
    setStaticButtonSelect("light");
    setSidList([]);
  };
  const SiteModal = (props) => {
    const [checked, setChecked] = useState([]);
    const [clicked, setClicked] = useState({});
    const [expanded, setExpanded] = useState([]);
    const [filterText, setFilterText] = useState("");
    const [filteredNodes, setFilteredNodes] = useState(nodes);

    useEffect(() => {
      filterTree();
    }, [filterText]);

    const onCheck = (checked) => {
      setChecked(checked);
    };

    const onExpand = (expanded) => {
      setExpanded(expanded);
    };

    const onClick = (value) => {
      setClicked(value);
    };

    const onFilterChange = (event) => {
      setFilterText(event.target.value);
    };

    const filterTree = () => {
      if (!filterText) {
        // console.log("no filter text");
        setFilteredNodes(nodes);
        return;
      }
      setFilteredNodes(nodes.reduce(filterNodes, []));
    };

    const filterNodes = (filtered, node) => {
      const children = (node.children || []).reduce(filterNodes, []);
      console.log("children", children);
      if (
        // * Node's label matches the search string
        node.label.toLocaleLowerCase().indexOf(filterText.toLowerCase()) > -1 ||
        // * Or a children has a matching node
        children.length
      ) {
        filtered.push({ ...node, children });
      }
      return filtered;
    };

    const saveSelection = () => {
      setSidList(checked);
      setModalShow(false);
    };

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Site Selection
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "800px" }}>
          <Container fluid>
            <Card style={{ border: "none" }}>
              <Card.Body style={{ maxHeight: "700px", overflowY: "scroll" }}>
                <div className="filter-container">
                  <Row align="center">
                    <Col>
                      <input
                        className="filter-text"
                        placeholder="Search..."
                        type="text"
                        value={filterText}
                        onChange={onFilterChange}
                        style={{ borderRadius: "5px", padding: "7px" }}
                      />
                    </Col>
                    <Col>
                      <h6>Sites Selected: {checked.length}</h6>
                    </Col>
                    <Col>
                      <Button
                        style={{ width: "200px" }}
                        onClick={() => saveSelection()}
                      >
                        <h6>Save</h6>
                      </Button>
                    </Col>
                  </Row>
                  <br />
                  <CheckboxTree
                    checked={checked}
                    expanded={expanded}
                    nodes={filteredNodes}
                    showExpandAll
                    expandOnClick
                    onCheck={onCheck}
                    onClick={onClick}
                    onExpand={onExpand}
                  />
                </div>
              </Card.Body>
            </Card>
          </Container>
        </Modal.Body>
        {/* <Modal.Footer>
          <Row>
            <Col>
              <h6>Sites Selected: 0</h6>
            </Col>
            <Col>
              <Button size="lg" onClick={props.onHide}>
                <Button size="lg" onClick={props.onHide}> 
                Save
              </Button>
            </Col>
          </Row>
        </Modal.Footer> */}
      </Modal>
    );
  };
  const TemplateModal = (props) => {
    useEffect(() => {
      getTemplates();
    }, []);

    const [selectedReportID, setSelectedReportID] = useState("");
    const [templates, setTemplates] = useState([]);

    const getTemplates = async () => {
      const authT = await getAuth();
      const options = {
        method: "GET",
        url: "https://api.pramsplus.com/report-templates",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authT}`,
        },
      };

      axios
        .request(options)
        .then(function (response) {
          // ! Pulling over and over when state changes, may need to refactor
          // console.log("templates", response.data);
          setTemplates(response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    const loadTemplateButton = (item) => {
      console.log("selectedReportID", item.id);
      setSelectedReport(item.report);
      setSelectedReportName(item.name);
      setSelectedVariant(item.variant);
      setTemplateDate(!templateDate);
      setSelectedDate(item.date);
      setPrevDay(item.usePreviousDayData);
      setAsPDF(item.asPDF);
      setSidList(item.siteIds);
      setUserReportName(item.name);
      setSelectedReportID(item.id);
      setLoadedTemplate(true);
      props.onHide();
    };

    const deleteTemplateButton = (id) => {
      console.log("selectedReportID", id);
      deleteTemplate(id);
      props.onHide();
    };

    const deleteTemplate = async (id) => {
      const auth = await getAuth();
      const options = {
        method: "DELETE",
        url: `https://api.pramsplus.com/report-templates/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      };

      axios
        .request(options)
        .then(function (response) {
          console.log("delete template", response);
          setDeleteTemplate(true);
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Load Report Template
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "800px" }}>
          <Container fluid>
            <Card style={{ border: "none" }}>
              <Card.Body style={{ maxHeight: "700px", overflowY: "scroll" }}>
                <div className="filter-container">
                  <Row align="center">
                    <Col>
                      <h5 style={{ paddingTop: "5px" }}>
                        Select a template to load:
                      </h5>
                    </Col>
                    <Col></Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <ListGroup>
                        {templates ? (
                          templates.map((item, index) => (
                            <ListGroup.Item
                              key={index}
                              // onClick={() => {
                              //   setSelectedReport(item.report);
                              //   setSelectedReportName(item.name);
                              //   setSelectedVariant(item.variant);
                              //   setTemplateDate(!templateDate);
                              //   setSelectedDate(item.date);
                              //   setPrevDay(item.usePreviousDayData);
                              //   setAsPDF(item.asPDF);
                              //   setSidList(item.siteIds);
                              //   setUserReportName(item.name);
                              //   setSelectedReportID(item.id);
                              //   props.onHide();
                              // }}
                            >
                              <Row>
                                <Col>
                                  <h6 style={{ paddingTop: "10px" }}>
                                    {item.name}
                                  </h6>
                                </Col>
                                <Col align="right" xs={2}>
                                  <Button
                                    variant="outline-primary"
                                    style={{ width: "100px" }}
                                    onClick={() => loadTemplateButton(item)}
                                  >
                                    <h6>Load</h6>
                                  </Button>
                                </Col>
                                <Col align="right" xs={2}>
                                  <Button
                                    variant="outline-danger"
                                    style={{ width: "100px" }}
                                    id={item.id}
                                    onClick={() =>
                                      deleteTemplateButton(item.id)
                                    }
                                  >
                                    <h6>Remove</h6>
                                  </Button>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          ))
                        ) : (
                          <ListGroup.Item action>
                            No Templates Available
                          </ListGroup.Item>
                        )}
                      </ListGroup>
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Container>
        </Modal.Body>
        {/* <Modal.Footer>
          <Row>
            <Col>
              <h6>Sites Selected: 0</h6>
            </Col>
            <Col>
              <Button size="lg" onClick={props.onHide}>
                <Button size="lg" onClick={props.onHide}> 
                Save
              </Button>
            </Col>
          </Row>
        </Modal.Footer> */}
      </Modal>
    );
  };
  const runReport = async () => {
    const authT = await getAuth();
    const options = {
      method: "POST",
      url: "https://api.pramsplus.com/run_report",
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authT}`,
      },
      data: {
        report: selectedReport,
        variant: selectedVariant,
        date: selectedDate,
        usePreviousDayData: prevDay,
        asPDF: asPDF,
        siteIds: sidList,
        option1: "",
        option2: "",
        option3: "",
        option4: "",
      },
    };
    console.log("options", options);
    axios
      .request(options)
      .then(function (response) {
        // console.log("run report", response.data);
        console.log("success");
        const blob = response.data;
        if (fileType === "pdf") {
          const blobUrl = window.URL.createObjectURL(new Blob([blob]));
          // setPdfUrl(blobUrl);
          const a = document.createElement("a");
          a.href = blobUrl;
          a.download = `${selectedReportName}.${fileType}`;
          a.click();
          window.URL.revokeObjectURL(blobUrl);
        } else {
          const blobUrl = window.URL.createObjectURL(new Blob([blob]));
          const a = document.createElement("a");
          a.href = blobUrl;
          a.download = `${selectedReportName}.${fileType}`;
          a.click();
          window.URL.revokeObjectURL(blobUrl);
        }
      })

      .catch(function (error) {
        console.error(error);
      });
  };
  const saveTemplate = async () => {
    const auth = await getAuth();
    const options = {
      method: "POST",
      url: "https://api.pramsplus.com/report-templates",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
      data: {
        name: userReportName,
        report: selectedReport,
        variant: selectedVariant,
        date: selectedDate,
        usePreviousDayData: prevDay,
        asPDF: asPDF,
        siteIds: sidList,
        option1: "",
        option2: "",
        option3: "",
        option4: "",
      },
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log("save template", response);
        setSaveTemplate(true);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  const handleSaveModalClose = () => {
    saveTemplate();
    setSaveModal(false);
  };
  const handleInputUserTemplate = (e) => {
    setUserReportName(e.target.value);
  };

  return (
    <>
      <TopNav />

      <Container style={{ width: "40%" }}>
        <Card style={{ marginTop: "15px", boxShadow: "3px 5px 10px black" }}>
          <Card.Footer>
            <Row
              className="justify-content-center"
              style={{ paddingTop: "15px" }}
            >
              <Col align="center">
                <Button
                  style={{ minWidth: "165px" }}
                  variant="outline-primary"
                  size="lg"
                  onClick={() => templateSelect()}
                >
                  Load Template{" "}
                  <DriveFolderUploadIcon
                    style={{ fontSize: "30px", marginBottom: "5px" }}
                  />
                </Button>
                <TemplateModal
                  show={templateModal}
                  onHide={() => setTemplateModal(false)}
                />
              </Col>
              <Col align="center">
                <Button
                  style={{ minWidth: "165px" }}
                  variant="outline-primary"
                  size="lg"
                  onClick={() => setSaveModal(true)}
                  disabled={saveVariantV && saveReportV ? false : true}
                >
                  Save Template{" "}
                  <SaveIcon style={{ fontSize: "30px", marginBottom: "5px" }} />
                </Button>

                <Modal show={saveModal} onHide={handleSaveModalClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Save Template</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Please enter a name for your template:
                    <Form.Control
                      type="text"
                      placeholder="Normal text"
                      onChange={handleInputUserTemplate}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    {userReportName ? (
                      <Button variant="primary" onClick={handleSaveModalClose}>
                        Save Changes
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Modal.Footer>
                </Modal>
              </Col>
            </Row>
            <br />
            {savedTemplate && (
              <Alert
                key="success"
                variant="success"
                onClose={() => setSaveTemplate(false)}
                dismissible
              >
                Template Saved!
              </Alert>
            )}
            {deletedTemplate && (
              <Alert
                key="warning"
                variant="warning"
                onClose={() => setDeleteTemplate(false)}
                dismissible
              >
                Template Deleted!
              </Alert>
            )}
            {loadedTemplate && (
              <Alert
                key="success"
                variant="success"
                onClose={() => setLoadedTemplate(false)}
                dismissible
              >
                <h6>Template Loaded!</h6>
              </Alert>
            )}
          </Card.Footer>
          <Card.Body>
            <Row>
              <Form style={{ fontSize: "18px" }}>
                <Form.Group>
                  <Form.Label>
                    <h2>Type</h2>
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1">Report</InputGroup.Text>
                    <Form.Select
                      onChange={handleSelectChange}
                      size="lg"
                      aria-label=""
                    >
                      <option value="0">
                        {selectedReport
                          ? selectedReportName
                          : "Select a Report"}
                      </option>
                      {reports ? (
                        reports.map((item, index) => (
                          <option
                            key={index}
                            value={item.fileName}
                            id={item.displayName}
                          >
                            {item.displayName}
                          </option>
                        ))
                      ) : (
                        <option value="0">No tasks Available</option>
                      )}
                    </Form.Select>
                  </InputGroup>
                  <br />
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1">Variant</InputGroup.Text>
                    <Form.Select
                      onChange={handleVariantChange}
                      size="lg"
                      aria-label=""
                    >
                      <option value="0">
                        {selectedVariant ? selectedVariant : "Select a Variant"}
                      </option>
                      {variants ? (
                        variants.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))
                      ) : (
                        <option value="0">No variants Available</option>
                      )}
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
                <br />
                <Form.Group>
                  <Form.Label>
                    <h2>Options</h2>
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1">
                      Entry Date
                    </InputGroup.Text>
                    <Form.Control
                      type="date"
                      placeholder="mm/dd/yyyy"
                      onChange={handleDate}
                    />
                    <br />
                    {templateDate && (
                      <Row>
                        <Col align="center">
                          <h6>
                            Selected Template Date:{" "}
                            {dayjs(selectedDate).format("MM/DD/YYYY")}{" "}
                          </h6>
                        </Col>
                      </Row>
                    )}
                  </InputGroup>
                  <br />
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1">
                      File Type
                    </InputGroup.Text>
                    <Form.Select
                      size="lg"
                      aria-label=""
                      onChange={handleFileType}
                    >
                      {asPDF ? (
                        <option value="pdf">PDF</option>
                      ) : (
                        <option value="xls">XLS</option>
                      )}
                    </Form.Select>
                  </InputGroup>
                  <br />
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1">
                      Use Previous Day's Data
                    </InputGroup.Text>
                    <Row>
                      <Col className="align-self-center">
                        <Form.Check
                          inline
                          type="checkbox"
                          onClick={() => setPrevDay(!prevDay)}
                        />
                      </Col>
                    </Row>
                  </InputGroup>
                  <br />
                  {nodes < 1 ? (
                    <h6>Loading Sites...</h6>
                  ) : (
                    <>
                      <InputGroup>
                        <InputGroup.Text id="basic-addon1">
                          Site Selection
                        </InputGroup.Text>
                        <Row>
                          <Button
                            variant={allButtonSelect}
                            onClick={() => allSelect()}
                          >
                            All Sites
                          </Button>
                          <Button
                            variant={staticButtonSelect}
                            onClick={() => staticSelect()}
                          >
                            Static Selection
                          </Button>
                        </Row>
                      </InputGroup>
                      <br />
                      <Row>
                        <Col align="center">
                          Sites Selected: {sidList.length}
                        </Col>
                      </Row>
                    </>
                  )}
                </Form.Group>
              </Form>
              <br />
              <br />
              <SiteModal show={modalShow} onHide={() => setModalShow(false)} />
            </Row>
            {/* {pdfUrl && (
            <iframe
              title="PDF Viewer"
              width="100%"
              height="600px"
              src={pdfUrl}
            />
          )} */}
          </Card.Body>
          <Card.Footer>
            <Col align="center">
              <Button
                style={{ minWidth: "165px" }}
                variant="outline-primary"
                size="lg"
                onClick={() => runReport()}
              >
                Run Report{" "}
                <PlayArrowIcon
                  style={{ fontSize: "30px", marginBottom: "5px" }}
                />
              </Button>
            </Col>
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
};
export default Reports;

//* Website for tree
//* https://jakezatecky.github.io/react-checkbox-tree/
