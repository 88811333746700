import axios from "axios";

const DynamicApiCalls = async (endpoint, auth) => {
  const getArea = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/area",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("area error");
      throw error;
    }
  };
  const getApi = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/api",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("api error");
      throw error;
    }
  };
  const getCounty = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/county",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("county error");
      throw error;
    }
  };
  const getDistrict = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/district",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("district error");
      throw error;
    }
  };
  const getDistrictCode = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/district",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("district error");
      throw error;
    }
  };
  const getDistrictName = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/district",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("district error");
      throw error;
    }
  };
  const getSiteType = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/site-type",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("site-type error");
      throw error;
    }
  };
  const getFieldOffice = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/field-office",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("field-office error");
      throw error;
    }
  };
  const getForeman = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/foreman",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("foreman error");
      throw error;
    }
  };
  const getLease = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/lease",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("lease error");
      throw error;
    }
  };
  const getName = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/name",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("name error");
      throw error;
    }
  };
  const getNumber = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/number",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("number error");
      throw error;
    }
  };
  const getPad = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/participating-area",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("participating-area error");
      throw error;
    }
  };
  const getParticipatingArea = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/participating-area",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("participating-area error");
      throw error;
    }
  };
  const getProspect = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/participating-area",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("participating-area error");
      throw error;
    }
  };
  const getStateId = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/state-id",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("state-id error");
      throw error;
    }
  };
  const getStatePermit = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/state-permit",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("state-permit error");
      throw error;
    }
  };
  const getUnit = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/unit",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("unit error");
      throw error;
    }
  };
  const getUserRef = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/user-ref",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("user-ref error");
      throw error;
    }
  };
  const getFedStart = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/fed-start",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("fed-start error");
      throw error;
    }
  };
  const getFedEnd = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/fed-end",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("fed-end error");
      throw error;
    }
  };
  const getInternalStart = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/internal-start",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("internal-start error");
      throw error;
    }
  };
  const getInternalEnd = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/internal-end",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("internal-end error");
      throw error;
    }
  };
  const getStateStart = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/state-start",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("state-start error");
      throw error;
    }
  };
  const getStateEnd = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/state-end",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("state-end error");
      throw error;
    }
  };
  const getAnalyst = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/analyst",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("analyst error");
      throw error;
    }
  };
  const getEngineer = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/engineer",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("engineer error");
      throw error;
    }
  };
  const getEngineerAssistant = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/engineer-assistant",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("engineer-assistant error");
      throw error;
    }
  };
  const getField = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/field",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("field error");
      throw error;
    }
  };
  const getFormation = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/formation",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("formation error");
      throw error;
    }
  };
  const getOperator = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/operator",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("operator error");
      throw error;
    }
  };
  const getRoute = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/route",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("route error");
      throw error;
    }
  };
  const getState = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/state",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("state error");
      throw error;
    }
  };
  const getStatus = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/status",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("status error");
      throw error;
    }
  };
  const getAcct = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/acct",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("acct error");
      throw error;
    }
  };
  const getEcon = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/econ",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("econ error");
      throw error;
    }
  };
  const getMisc1 = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/misc_1",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("misc_1 error");
      throw error;
    }
  };
  const getMisc2 = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/misc_2",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("misc_2 error");
      throw error;
    }
  };
  const getMktg = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/mktg",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("mktg error");
      throw error;
    }
  };
  const getOtherOp = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/other-op",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("other-op error");
      throw error;
    }
  };
  const getUser1 = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/user1",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("user1 error");
      throw error;
    }
  };
  const getUser2 = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/user2",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("user2 error");
      throw error;
    }
  };
  const getUser3 = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/user3",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("user3 error");
      throw error;
    }
  };
  const getUser4 = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/user4",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("user4 error");
      throw error;
    }
  };
  const getUser5 = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/user5",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("user5 error");
      throw error;
    }
  };
  const getUser6 = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/user6",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("user6 error");
      throw error;
    }
  };
  const getUser7 = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/user7",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("user7 error");
      throw error;
    }
  };
  const getUser8 = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/user8",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("user8 error");
      throw error;
    }
  };
  const getUser9 = async () => {
    const options = {
      method: "GET",
      url: "https://api.pramsplus.com/dynamic-values/user9",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
      console.log("user9 error");
      throw error;
    }
  };

  switch (endpoint) {
    case "area":
    case "s.area":
      try {
        const res = await getArea();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "api":
    case "s.api":
      try {
        const res = await getApi();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "county":
    case "s.county":
      try {
        const res = await getCounty();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "district":
    case "s.district":
      try {
        const res = await getDistrict();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "districtCode":
    case "s.district_code":
      try {
        const res = await getDistrictCode();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
        return null;
      }
    case "districtName":
    case "s.district_name":
      try {
        const res = await getDistrictName();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
        return null;
      }
    case "site_type":
    case "s.site_type":
      try {
        const res = await getSiteType();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "site_class":
    case "s.[class]":
      try {
        const res = await getSiteType();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "field_office":
    case "s.field_office":
      try {
        const res = await getFieldOffice();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "foreman":
    case "s.foreman":
      try {
        const res = await getForeman();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "lease":
    case "s.lease":
      try {
        const res = await getLease();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "name":
      try {
        const res = await getName();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "number":
      try {
        const res = await getNumber();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "participating_area":
      try {
        const res = await getParticipatingArea();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "prospect":
    case "s.prospect":
      try {
        const res = await getProspect();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "state_id":
      try {
        const res = await getStateId();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "state_permit":
      try {
        const res = await getStatePermit();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "unit":
      try {
        const res = await getUnit();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "user_ref":
    case "s.user_ref":
      try {
        const res = await getUserRef();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "fed_start":
    case "s.fed_start":
      try {
        const res = await getFedStart();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "fed_end":
    case "s.fed_end":
      try {
        const res = await getFedEnd();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "internal_start":
    case "s.internal_start":
      try {
        const res = await getInternalStart();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "internal_end":
    case "s.internal_end":
      try {
        const res = await getInternalEnd();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "state_start":
    case "s.state_start":
      try {
        const res = await getStateStart();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "state_end":
    case "s.state_end":
      try {
        const res = await getStateEnd();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "analyst_id":
    case "u2.username":
      try {
        const res = await getAnalyst();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "engineer_id":
    case "u.username":
      try {
        const res = await getEngineer();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "engineer_asst":
      try {
        const res = await getEngineerAssistant();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "pad":
    case "s.pad_name":
      try {
        const res = await getPad();
        return res;
      } catch (error) {
        console.log("Error in Pad switcher", error);
      }
      break;
    case "formation_id":
    case "m.name":
      try {
        const res = await getFormation();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "field_id":
    case "f.name":
      try {
        const res = await getField();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "formation_id":
    case "m.name":
      try {
        const res = await getFormation();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "operator_id":
    case "o.name":
      try {
        const res = await getOperator();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "route_id":
    case "r.name":
      try {
        const res = await getRoute();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "state":
    case "s.state":
      try {
        const res = await getState();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "status":
    case "s.status":
      try {
        const res = await getStatus();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "acct":
    case "s.acct":
      try {
        const res = await getAcct();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "econ":
    case "s.econ":
      try {
        const res = await getEcon();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "misc_1":
    case "x.misc_1":
      try {
        const res = await getMisc1();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "misc_2":
    case "x.misc_2":
      try {
        const res = await getMisc2();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "mktg":
      try {
        const res = await getMktg();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "other_op":
      try {
        const res = await getOtherOp();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "user_1":
    case "x.user_1":
      try {
        const res = await getUser1();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "user_2":
    case "x.user_2":
      try {
        const res = await getUser2();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "user_3":
    case "x.user_3":
      try {
        const res = await getUser3();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "user_4":
    case "x.user_4":
      try {
        const res = await getUser4();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "user_5":
    case "x.user_5":
      try {
        const res = await getUser5();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "user_6":
    case "x.user_6":
      try {
        const res = await getUser6();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "user_7":
    case "x.user_7":
      try {
        const res = await getUser7();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "user_8":
    case "x.user_8":
      try {
        const res = await getUser8();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    case "user_9":
    case "x.user_9":
      try {
        const res = await getUser9();
        return res;
      } catch (error) {
        console.log("Error in switcher", error);
      }
      break;
    default:
      console.log("Error: default hit in switcher");
      break;
  }
};

export default DynamicApiCalls;
