import React, { useEffect, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import axios from "axios";
import { useAuthContext } from "./authProvider";
import { Container, Card, Row, Col, Button, Spinner } from "react-bootstrap";
import "react-checkbox-tree/lib/react-checkbox-tree.css";

const StaticSiteSearchTool = (props) => {
  const { getAuth, refreshCheck } = useAuthContext();

  const [nodes, setNodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filteredNodes, setFilteredNodes] = useState(nodes);
  const [sidList, setSidList] = useState([]);

  useEffect(() => {
    // console.log("props", props);
    setChecked(props.siteIdsOptions);
    setSidList(props.siteIdsOptions);
  }, []);
  useEffect(() => {
    if (sidList.length > 0) {
      // console.log("sid list greater than 0");
      // console.log("sidList", sidList);
      setChecked(sidList);
      getSiteList();
    } else {
      getSiteList();
    }
  }, [sidList]);
  useEffect(() => {
    filterTree();
  }, [nodes]);
  useEffect(() => {
    filterTree();
  }, [filterText]);
  useEffect(() => {
    // console.log("checked", checked);
    setSidList(checked);
  }, [checked]);
  // useEffect(() => {
  //   console.log("expanded", expanded);
  //   localStorage.setItem("expanded", JSON.stringify(expanded));
  // }, [expanded]);

  const onCheck = (checked) => {
    setChecked(checked);
  };
  const onExpand = (expanded) => {
    setExpanded(expanded);
  };
  const onFilterChange = (event) => {
    setFilterText(event.target.value);
  };
  const filterTree = () => {
    if (!filterText) {
      setFilteredNodes(nodes);
      return;
    }
    setFilteredNodes(nodes.reduce(filterNodes, []));
  };
  const filterNodes = (filtered, node) => {
    const children = (node.children || []).reduce(filterNodes, []);
    if (
      // * Node's label matches the search string
      node.label.toLocaleLowerCase().indexOf(filterText.toLowerCase()) > -1 ||
      // * Or a children has a matching node
      children.length
    ) {
      filtered.push({ ...node, children });
    }
    return filtered;
  };
  const getSiteList = async () => {
    // console.log("getSiteList");
    const getNodes = async () => {
      setLoading(true);
      const authT = await getAuth();
      const options = {
        method: "POST",
        url: "https://api.pramsplus.com/sites_tree",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authT}`,
        },
      };
      axios
        .request(options)
        .then(function (response) {
          console.log("sites done");
          setLoading(false);
          localStorage.setItem("allSites", JSON.stringify(response.data));
          setNodes(response.data);
        })
        .catch(function (error) {
          console.error(error);
          refreshCheck();
        });
    };
    const savedList = localStorage.getItem("allSites");
    // console.log("savedList", savedList);
    if (savedList === '') {
      console.log("savedList null");
      getNodes();
    } else if (savedList !== '') {
      // console.log("savedList not null");
      const siteNodes = JSON.parse(localStorage.getItem("allSites"));
      // console.log("siteNodes", siteNodes);
      if (siteNodes === null) {
        getNodes();
      } else if (siteNodes !== null) {
        setNodes(siteNodes);
      }
    }

  };
  // const getSiteList = async () => {
  //   // console.log("getSiteList");
  //   const rawSiteNodes = localStorage.getItem("allSites");
  //   const siteNodes = JSON.parse(rawSiteNodes);
  //   // console.log("siteNodes", siteNodes);

  //   if (siteNodes === null || siteNodes === undefined || siteNodes === "") {
  //     setLoading(true);
  //     const authT = await getAuth();
  //     const options = {
  //       method: "POST",
  //       url: "https://api.pramsplus.com/sites_tree",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${authT}`,
  //       },
  //     };
  //     axios
  //       .request(options)
  //       .then(function (response) {
  //         console.log("sites done");
  //         setLoading(false);
  //         localStorage.setItem("allSites", JSON.stringify(response.data));
  //         setNodes(response.data);
  //       })
  //       .catch(function (error) {
  //         console.error(error);
  //         refreshCheck();
  //       });
  //   } else if (siteNodes !== null) {
  //     setNodes(siteNodes);
  //   }
  // };
  return (
    <>
      {loading ? (
        <>
          <Row className="text-center" style={{ marginTop: "15px" }}>
            <Col>
              <Spinner animation="grow" variant="dark" />
              <Spinner animation="grow" variant="primary" />
              <Spinner animation="grow" variant="info" />
              <Spinner animation="grow" variant="secondary" />
            </Col>
          </Row>
          <Row
            className="text-center"
            style={{ marginTop: "15px", marginBottom: "5px" }}
          >
            <h4>Loading Site List</h4>
          </Row>
        </>
      ) : (
        <Container fluid>
          <Card style={{ border: "none" }}>
            <Card.Body style={{ maxHeight: "500px", overflowY: "scroll" }}>
              <div className="filter-container">
                <Row align="center">
                  <Col>
                    <input
                      className="filter-text"
                      placeholder="Search..."
                      type="text"
                      value={filterText}
                      onChange={onFilterChange}
                      style={{ borderRadius: "5px", padding: "7px" }}
                    />
                  </Col>
                  <Col>
                    <h6>Sites Selected: {checked.length}</h6>
                  </Col>
                </Row>
                <br />
                <CheckboxTree
                  checked={checked}
                  expanded={expanded}
                  nodes={filteredNodes}
                  showExpandAll
                  expandOnClick
                  onCheck={onCheck}
                  onExpand={onExpand}
                />
              </div>
            </Card.Body>
            <Row className="text end">
              <Col>
                <Button
                  style={{ float: "right" }}
                  variant="danger"
                  onClick={() => {
                    setChecked([]);
                  }}
                >
                  Clear All
                </Button>
                <Button
                  style={{ float: "right" }}
                  variant="primary"
                  onClick={() => {
                    // updateStaticOptionsTest();
                    props.saveSitesTree(checked);
                    return;
                  }}
                >
                  Save Changes
                </Button>
              </Col>
            </Row>
          </Card>
        </Container>
      )}
    </>
  );
};
export default StaticSiteSearchTool;
