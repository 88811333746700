import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../utils/authProvider";
import {
  Dropdown,
  Card,
  Col,
  Row,
  Offcanvas,
  Button,
  Spinner,
} from "react-bootstrap";
import SiteListTree from "../../treeView/siteListTree";
// import Chart from "../chartTest/chartTest";
import GroupContainer from "../Groups/groupsNew";
import axios from "axios";
import MRT from "../../MRT/MRT";
// import { siteIds } from "../../treeView/fancyTreeTest";
import DdChart from "../chart/dataViewEchartDD";
import TopNav from "../../navbars/Top_NavBar/topNav";
import "../Staging/stageStyle.css";

export function DataViewStage() {
  const dayjs = require("dayjs");
  const navigate = useNavigate();
  const { getAuth, refreshCheck, authCheck } = useAuthContext();

  const [groupName, setGroupName] = useState("Select a Group");
  const [lastKnownGroupID, setLastKnownGroup] = useState(0);
  const [siteArray, setSiteArray] = useState([]);
  const [lastKnownSiteArray, setLastKnownSiteArray] = useState([]);
  const [ddInfo, setDDInfo] = useState([]);
  const [noDataDisplay, setNoDataDisplay] = useState("Select a group and at least one site to display data.");
  const [daysBack, setDaysBack] = useState(30);
  const [thisDay, setThisDay] = useState(dayjs().format("YYYY-MM-DD"));
  const [fromDate, setFromDate] = useState(
    dayjs().subtract(30, "day").format("YYYY-MM-DD")
  );
  const [chartDataLoaded, setChartDataLoaded] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    const lastSiteArray = localStorage.getItem("LastSiteArray_DataView");
    console.log("last site array UE:", lastSiteArray);
    if (lastSiteArray !== null) {
      const updatedArray = lastSiteArray.split(",");
      console.log("updatedArray UE", updatedArray);
      setLastKnownSiteArray(updatedArray);
    }
    const lastGroupID = localStorage.getItem("LastGroupID_DataView");
    const lastKnownGroupName = localStorage.getItem("LastGroupName_DataView");
    if (lastGroupID) {
      setLastKnownGroup(lastGroupID);
    }
    if (lastKnownGroupName) {
      setGroupName(lastKnownGroupName);
    }
  }, []);
  useEffect(() => {
    handleSumSites();
  }, [lastKnownSiteArray]);
  useEffect(() => {
    console.log("dataLoading", dataLoading);
  }, [dataLoading]);
  useEffect(() => {
    if (siteArray.length > 0) {
      // console.log("siteArray", siteArray);
      handleSumSites();
    }
  }, [fromDate]);
  useEffect(() => {
    console.log("site array", siteArray);
    if (siteArray.length < 0) {
      // alert("No sites found for selection.");
      return;
    } else {
      handleSumSites();
    }
  }, [siteArray]);
  useEffect(() => {
    console.log("ddinfo", ddInfo);

    if (ddInfo.length > 0) {
      setChartDataLoaded(true);
    } else {
      setNoDataDisplay("No data found for selected sites.");
      setChartDataLoaded(false);
    }
  }, [ddInfo]);

  useEffect(() => {
    if (daysBack == 30) {
      const day30 = dayjs().subtract(30, "day").format("YYYY-MM-DD");
      updateFromDate(day30);
    } else if (daysBack == 60) {
      const day60 = dayjs().subtract(60, "day").format("YYYY-MM-DD");
      updateFromDate(day60);
    } else if (daysBack == 90) {
      const day90 = dayjs().subtract(90, "day").format("YYYY-MM-DD");
      updateFromDate(day90);
    }
    // else if (daysBack == 365) {
    //   const day365 = dayjs().subtract(365, "day").format("YYYY-MM-DD");
    //   updateFromDate(day365);
    // }
  }, [daysBack]);
  const updateStageGroupName = (dataChild) => {
    localStorage.setItem("LastGroupName_DataView", dataChild);
    setGroupName(dataChild);
  };
  const updateLastKnownGroupID = (gid) => {
    localStorage.setItem("LastGroupID_DataView", gid);
    setLastKnownGroup(gid);
  };
  // const updateSiteArrayState = (array) => {
  //   setSiteArray(array);
  // };
  // const clearSiteArray = (event) => {
  //   event.preventDefault();
  //   updateSiteArrayState([]);
  //   document.querySelectorAll(".form-check-input").forEach((item) => {
  //     item.checked = false;
  //   });
  // };
  // const selectAllSites = (event) => {
  //   event.preventDefault();
  //   document.querySelectorAll(".form-check-input").forEach((item) => {
  //     item.checked = true;
  //     setSiteArray((prevState) => [...prevState, item.id]);
  //   });
  // };
  const updateFromDate = (date) => {
    setFromDate(date);
  };
  const handleSumSites = () => {
    console.log("DsPull Start");
    setDataLoading(true);
    if (siteArray.length === 0) {
      if (lastKnownSiteArray.length > 0) {
        DsPull(lastKnownSiteArray);
      }
      console.log("Please select at least one site.");
      return;
    }
    DsPull(siteArray);
  };
  const handleDaysBack = (event) => {
    event.preventDefault();
    console.log("the value is", event.target.dataset.value);
    setDaysBack(event.target.dataset.value);
  };
  const DsPull = async (siteArray) => {
    const authT = await getAuth();
    console.log("DsPull", daysBack);
    console.log("siteArray", siteArray);
    console.log("siteArray", siteArray.length);
    if (siteArray[0] === "" || siteArray.length === 0) {
      console.log("No sites selected in ds pull");
      // alert("No sites found for selection.");
      setDataLoading(false);
      return;
    }

    const siteArrayData = {
      siteIds: siteArray,
      from: fromDate,
      to: thisDay,
    };
    // console.log(siteArrayData);
    const handleMultipleSites = (res) => {
      // * Get the summed sites count
      const uniqueValueCount = [...new Set(res.map((item) => item.siteId))]
        .length;

      // * Add the values together
      function calcItemSumByDate(data) {
        setChartDataLoaded(false);
        const uniqueDates = [...new Set(data.map((item) => item.date))];
        // console.log(uniqueDates);

        let arrayHolder = [];
        for (let i = 0; i < uniqueDates.length; i++) {
          const filteredData = data.filter(
            (item) => item.date === uniqueDates[i]
          );
          // console.log(filteredData);
          const hrsOnSum = filteredData.reduce(
            (acc, item) => acc + item.hoursOnline / 60,
            0
          );
          const hrsDownSum = filteredData.reduce(
            (acc, item) => acc + item.hoursDown / 60,
            0
          );
          const grsOilSum = filteredData.reduce(
            (acc, item) => acc + item.grossOil,
            0
          );
          const grsOilFix = grsOilSum.toFixed(2);
          const grsGasSum = filteredData.reduce(
            (acc, item) => acc + item.grossGas,
            0
          );
          const grsGasFix = grsGasSum.toFixed(2);
          const grsWtrSum = filteredData.reduce(
            (acc, item) => acc + item.grossWater,
            0
          );
          const grsWtrFix = grsWtrSum.toFixed(2);
          const injSum = filteredData.reduce(
            (acc, item) => acc + item.injectedVolume,
            0
          );
          const injFix = injSum.toFixed(2);
          const oilSalesSum = filteredData.reduce(
            (acc, item) => acc + item.oilSales,
            0
          );
          const oilSalesFix = oilSalesSum.toFixed(2);
          const gasSalesSum = filteredData.reduce(
            (acc, item) => acc + item.gasSales,
            0
          );
          const gasSalesFix = gasSalesSum.toFixed(2);
          const gasLiftSum = filteredData.reduce(
            (acc, item) => acc + item.gasLift,
            0
          );
          const gasLiftFix = gasLiftSum.toFixed(2);
          const luSum = filteredData.reduce(
            (acc, item) => acc + item.leaseUse,
            0
          );
          const luFix = luSum.toFixed(2);
          const flareSum = filteredData.reduce(
            (acc, item) => acc + item.flare,
            0
          );
          const flareFix = flareSum.toFixed(2);

          let objectHolder = {
            date: uniqueDates[i],
            hoursOnline: hrsOnSum,
            hoursDown: hrsDownSum,
            grossOil: grsOilFix,
            grossGas: grsGasFix,
            grossWater: grsWtrFix,
            injectedVolume: injFix,
            oilSales: oilSalesFix,
            gasSales: gasSalesFix,
            gasLift: gasLiftFix,
            leaseUse: luFix,
            flare: flareFix,
          };
          arrayHolder.push(objectHolder);
        }
        return arrayHolder;
      }

      const summedValues = calcItemSumByDate(res);

      const multiData = summedValues.map((item) => {
        return {
          siteId: `-`,
          date: dayjs(item.date).format("MM/DD/YYYY"),
          userRef: `${uniqueValueCount} Sites `,
          siteName: `-`,
          hoursOnline: `-`,
          hoursDown: `-`,
          downCode: `-`,
          downcodeDescription: `-`,
          grossOil: item.grossOil,
          grossGas: item.grossGas,
          grossWater: item.grossWater,
          netOil: `-`,
          netGas: `-`,
          netWater: `-`,
          injectedVolume: item.injectedVolume,
          forecastOil: `-`,
          forecastGas: `-`,
          forecastWater: `-`,
          boe: `-`,
          netBOE: `-`,
          oilSales: item.oilSales,
          gasSales: item.gasSales,
          gasLift: item.gasLift,
          leaseUse: item.leaseUse,
          flare: item.flare,
          casingPressure: `-`,
          tubingPressure: `-`,
          linePressure: `-`,
          pumpInletPressure: `-`,
          gasLiftPressure: `-`,
          bradenheadPressure: `-`,
          choke: `-`,
          casingChoke: `-`,
          fluidLevel: `-`,
          gor: `-`,
          wor: `-`,
          mcfbbl: `-`,
          mmcfbbl: `-`,
          waterCut: `-`,
          oilCut: `-`,
          chartNote: `-`,
          remarks: `-`,
          userData1: `-`,
          userData2: `-`,
          userData3: `-`,
          userData4: `-`,
          userData5: `-`,
          userData6: `-`,
          userData7: `-`,
          userData8: `-`,
          userData9: `-`,
          nri: `-`,
        };
      });
      setDDInfo(multiData);
    };

    const options = {
      method: "POST",
      url: "https://api.pramsplus.com/daily_site",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authT}`,
      },
      data: siteArrayData,
    };

    try {
      const res = await axios.request(options);
      console.log(res.data.items);
      setDataLoading(false);
      setChartDataLoaded(true);
      const lastSiteArray = siteArray.map((item) => item.siteId);
      const dupeRemoved = [...new Set(lastSiteArray)];
      console.log("dupeRemoved", dupeRemoved);
      localStorage.setItem("LastSiteArray_DataView", dupeRemoved);
      setDDInfo([]);
      if (siteArray.length > 1) {
        console.log(`Length more than 1`);
        handleMultipleSites(res.data.items);
      } else {
        console.log(`Length = 1`);
        setDDInfo(res.data.items);
      }
    } catch (err) {
      console.log(err.response.status);
      if (err.response.status === 401) {
        const valid = await refreshCheck("DsPull");
        if (valid === true) {
          console.log("Retrying DsPull api call");
          DsPull(siteArray);
        } else if (valid === false) {
          console.log("all retries failed DsPull");
          navigate("/login");
        }
      } else {
        console.log("Redirecting to login something went wrong in DsPull");
        navigate("/login");
      }
      return;
    }

    // await axios
    //   .request(options)
    //   .then(function (response) {
    //     // console.log(response);
    //     console.log(response.data.items);
    //     setDataLoading(false);
    //     setChartDataLoaded(true);
    //     const res = response.data.items;
    //     const lastSiteArray = res.map((item) => item.siteId);
    //     const dupeRemoved = [...new Set(lastSiteArray)];
    //     console.log("dupeRemoved", dupeRemoved);
    //     localStorage.setItem("LastSiteArray_DataView", dupeRemoved);
    //     setDDInfo([]);
    //     if (siteArray.length > 1) {
    //       console.log(`Length more than 1`);
    //       handleMultipleSites(response.data.items);
    //     } else {
    //       console.log(`Length = 1`);
    //       setDDInfo(response.data.items);
    //     }
    //   })
    //   .catch(function (error) {});
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <TopNav />
      <Card id="Stage-Card">
        <Card.Body>
          <Row id="Main-Row">
            <Col id="Left-Col" xs={2}>
              <Button
                variant="primary"
                className="d-xl-none"
                onClick={handleShow}
              >
                Show Site Selection
              </Button>
              <Offcanvas
                id="OffCanvas"
                show={show}
                onHide={handleClose}
                responsive="xl"
              >
                <Offcanvas.Header
                  style={{
                    hight: "20px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    paddingTop: "5px",
                    paddingBottom: "0px",
                  }}
                  closeButton
                >
                  <Offcanvas.Title>Site Selection</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Row>
                    <Row id="group_button" style={{ marginBottom: "5px" }}>
                      <Dropdown>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          style={{
                            width: "285px",
                          }}
                        >
                          {groupName}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{
                            width: "275px",
                          }}
                        >
                          <GroupContainer
                            updateStageGroupName={updateStageGroupName}
                            updateLastKnownGroupID={updateLastKnownGroupID}
                            getAuth={getAuth}
                            refreshCheck={refreshCheck}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Row>
                    <Row>
                      <Col style={{ width: "120px" }}>
                        <div className="btn-group dropup">
                          <button
                            type="button"
                            className="btn btn-primary dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              marginBottom: "2px",
                              width: "142px",
                            }}
                          >
                            Days Back - {daysBack}
                          </button>
                          <ul
                            className="dropdown-menu dropdown-menu-dark"
                            style={{
                              width: "120px",
                            }}
                          >
                            <li style={{ textAlign: "center" }}>
                              <a
                                className="dropdown-item"
                                onClick={handleDaysBack}
                                data-value="30"
                              >
                                30
                              </a>
                            </li>
                            <li
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <a
                                className="dropdown-item"
                                onClick={handleDaysBack}
                                data-value="60"
                              >
                                60
                              </a>
                            </li>
                            <li
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <a
                                className="dropdown-item"
                                onClick={handleDaysBack}
                                data-value="90"
                              >
                                90
                              </a>
                            </li>
                            {/* <li
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <a
                                className="dropdown-item"
                                onClick={handleDaysBack}
                                data-value="365"
                              >
                                365
                              </a>
                            </li> */}
                          </ul>
                        </div>
                      </Col>
                      <Col style={{ width: "120px" }}>
                        <div className="btn-group dropup">
                          <h6
                            style={{ paddingLeft: "25px", paddingTop: "7px" }}
                          >
                            Selected - {siteArray.length}{" "}
                          </h6>
                          {/* <button
                            type="button"
                            className="btn btn-primary "
                            // dropdown-toggle
                            // data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              marginBottom: "2px",
                              width: "140px",
                            }}
                          >
                            Selected - {siteArray.length}
                          </button> */}
                          {/* <ul
                            className="dropdown-menu dropdown-menu-dark"
                            style={{
                              width: "120px",
                            }}
                          >
                            <li
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <a
                                className="dropdown-item"
                                onClick={clearSiteArray}
                              >
                                Clear
                              </a>
                            </li>
                            <li
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <a
                                className="dropdown-item"
                                onClick={selectAllSites}
                              >
                                Select All
                              </a>
                            </li>
                          </ul> */}
                        </div>
                      </Col>
                      {/* <div>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            handleSumSites();
                          }}
                          style={{
                            width: "285px",
                            position: "relative",
                          }}
                        >
                          Display Data{" "}
                          {dataLoading ? (
                            <Spinner
                              animation="border"
                              style={{ width: "13px", height: "13px" }}
                            />
                          ) : null}
                        </button>
                      </div> */}
                    </Row>
                    {dataLoading ? (
                      <Row className="text-center">
                        <Col>
                          <Spinner animation="border" variant="dark" />
                          <Spinner animation="border" variant="info" />
                          <Spinner animation="border" variant="primary" />
                          <Spinner animation="border" variant="secondary" />
                        </Col>
                      </Row>
                    ) : null}

                    <Row
                      id="SiteListHolder"
                      style={{
                        marginLeft: "0px",
                        minHeight: "725px",
                        maxHeight: "725px",
                      }}
                    >
                      {lastKnownGroupID ? (
                        <SiteListTree
                          getAuth={getAuth}
                          refreshCheck={refreshCheck}
                          groupID={lastKnownGroupID}
                          setSiteArray={setSiteArray}
                          lastChecked={lastKnownSiteArray}
                        />
                      ) : (
                        <h6
                          style={{
                            color: "Black",
                          }}
                        >
                          Waiting on Group Selection...
                        </h6>
                      )}
                    </Row>
                  </Row>
                </Offcanvas.Body>
              </Offcanvas>
            </Col>
            <Col id="Right-Col" xs={10}>
              <Row id="ChartHolder">
                <Col>
                  {/* <Chart ddInfo={ddInfo} /> */}
                  {chartDataLoaded ? (
                    <DdChart ddInfo={ddInfo} />
                  ) : (
                    <>
                      <br />
                      <h6
                        style={{
                          color: "Black",
                        }}
                      >
                        {noDataDisplay}
                      </h6>
                      <br />
                    </>
                  )}
                </Col>
              </Row>
              <Row id="MRTHolder">
                <Col>
                  <MRT ddInfo={ddInfo} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

// <Card
//         id="Stage-Card"
//         // style={{ width: "100%", height: "890px" }}
//       >
//         <Card.Body>
//           <Row
//             id="Main-Row"
//             // style={{
//             //   flexWrap: "nowrap",
//             //   width: "100%",
//             //   margin: "0px",
//             // }}
//           >
//             <Col
//               id="Left-Col"
//               xs={3}
//               // style={{
//               //   maxWidth: "275px",
//               //   marginRight: "15px",
//               //   height: "100%",
//               //   padding: "0px",
//               // }}
//             >
//               <Button
//                 variant="primary"
//                 className="d-xl-none"
//                 onClick={handleShow}
//               >
//                 Show Site Selection
//               </Button>
//               <Offcanvas
//                 id="OffCanvas"
//                 show={show}
//                 onHide={handleClose}
//                 responsive="xl"
//                 // style={{ maxWidth: "350px" }}
//               >
//                 <Offcanvas.Header closeButton>
//                   <Offcanvas.Title>Site Selection</Offcanvas.Title>
//                 </Offcanvas.Header>
//                 <Offcanvas.Body>
//                   <Row>
//                     <Row
//                       style={{
//                         width: "275px",
//                       }}
//                     >
//                       <Dropdown>
//                         <Dropdown.Toggle
//                           id="dropdown-basic"
//                           style={{
//                             width: "285px",
//                           }}
//                         >
//                           {groupName}
//                         </Dropdown.Toggle>
//                         <Dropdown.Menu
//                           style={{
//                             width: "275px",
//                           }}
//                         >
//                           <GroupContainer
//                             updateStageGroupName={updateStageGroupName}
//                             updateLastKnownGroupID={updateLastKnownGroupID}
//                             getAuth={getAuth}
//                             refreshCheck={refreshCheck}
//                           />
//                         </Dropdown.Menu>
//                       </Dropdown>
//                     </Row>
//                     <Row
//                       id="SiteListHolder"
//                       style={{
//                         marginLeft: "1px",
//                         // width: "275px",
//                         minHeight: "745px",
//                         maxHeight: "745px",
//                         overflowY: "scroll",
//                       }}
//                     >
//                       {lastKnownGroupID ? (
//                         <SiteListTree
//                           getAuth={getAuth}
//                           refreshCheck={refreshCheck}
//                           groupID={lastKnownGroupID}
//                           setSiteArray={setSiteArray}
//                         />
//                       ) : (
//                         <h6
//                           style={{
//                             color: "Black",
//                           }}
//                         >
//                           Waiting on Group...
//                         </h6>
//                       )}
//                     </Row>
//                     <Row>
//                       <Col style={{ width: "120px" }}>
//                         <div className="btn-group dropup">
//                           <button
//                             type="button"
//                             className="btn btn-primary dropdown-toggle"
//                             data-bs-toggle="dropdown"
//                             aria-expanded="false"
//                             style={{
//                               marginBottom: "2px",
//                               width: "142px",
//                             }}
//                           >
//                             Days Back - {daysBack}
//                           </button>
//                           <ul
//                             className="dropdown-menu dropdown-menu-dark"
//                             style={{
//                               width: "120px",
//                             }}
//                           >
//                             <li style={{ textAlign: "center" }}>
//                               <a
//                                 className="dropdown-item"
//                                 onClick={handleDaysBack}
//                                 data-value="30"
//                               >
//                                 30
//                               </a>
//                             </li>
//                             <li
//                               style={{
//                                 textAlign: "center",
//                               }}
//                             >
//                               <a
//                                 className="dropdown-item"
//                                 onClick={handleDaysBack}
//                                 data-value="60"
//                               >
//                                 60
//                               </a>
//                             </li>
//                             <li
//                               style={{
//                                 textAlign: "center",
//                               }}
//                             >
//                               <a
//                                 className="dropdown-item"
//                                 onClick={handleDaysBack}
//                                 data-value="90"
//                               >
//                                 90
//                               </a>
//                             </li>
//                             <li
//                               style={{
//                                 textAlign: "center",
//                               }}
//                             >
//                               <a
//                                 className="dropdown-item"
//                                 onClick={handleDaysBack}
//                                 data-value="365"
//                               >
//                                 365
//                               </a>
//                             </li>
//                           </ul>
//                         </div>
//                       </Col>
//                       <Col style={{ width: "120px" }}>
//                         <div className="btn-group dropup">
//                           <button
//                             type="button"
//                             className="btn btn-primary dropdown-toggle"
//                             data-bs-toggle="dropdown"
//                             aria-expanded="false"
//                             style={{
//                               marginBottom: "2px",
//                               width: "140px",
//                             }}
//                           >
//                             Selected - {siteArray.length}
//                           </button>
//                           <ul
//                             className="dropdown-menu dropdown-menu-dark"
//                             style={{
//                               width: "120px",
//                             }}
//                           >
//                             <li
//                               style={{
//                                 textAlign: "center",
//                               }}
//                             >
//                               <a
//                                 className="dropdown-item"
//                                 onClick={clearSiteArray}
//                               >
//                                 Clear
//                               </a>
//                             </li>
//                             <li
//                               style={{
//                                 textAlign: "center",
//                               }}
//                             >
//                               <a
//                                 className="dropdown-item"
//                                 onClick={selectAllSites}
//                               >
//                                 Select All
//                               </a>
//                             </li>
//                           </ul>
//                         </div>
//                       </Col>
//                       <div>
//                         <button
//                           type="button"
//                           className="btn btn-primary"
//                           onClick={handleSumSites}
//                           style={{
//                             width: "285px",
//                             position: "relative",
//                           }}
//                         >
//                           Display Data
//                         </button>
//                       </div>
//                     </Row>
//                   </Row>
//                 </Offcanvas.Body>
//               </Offcanvas>
//             </Col>
//             <Col id="Right-Col" xs={9}>
//               <Row id="ChartHolder">
//                 <Col>
//                   <Chart ddInfo={ddInfo} />
//                 </Col>
//               </Row>
//               <Row id="MRTHolder">
//                 <Col>
//                   <MRT ddInfo={ddInfo} />
//                 </Col>
//               </Row>
//             </Col>
//           </Row>
//         </Card.Body>
//       </Card>
