import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import axios from "axios";

const GroupContainer = ({
  updateStageGroupName,
  updateLastKnownGroupID,
  getAuth,
  refreshCheck
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [groupArray, setGroupArray] = useState([]);

  useEffect(() => {
    callGroups();
  }, []);

  const callGroups = async () => {
    setIsLoading(true);
    const authT = await getAuth();
    if (authT !== false) {
      try {
        const res = await axios.get("https://api.pramsplus.com/groups", {
          headers: { Authorization: `Bearer ${authT}` },
        });
        console.log("groups api call success");
        setIsLoading(false);
        setGroupArray(GroupDisplay(res.data));
        return res.data;
      } catch (err) {
        console.log(err.response.status);
        if (err.response.status === 401) {
          const valid = await refreshCheck("callGroups");
          if (valid === true) {
            console.log("Retrying callGroups api call");
            callGroups();
          } else if (valid === false) {
            console.log("all retries failed callGroups");
            navigate("/login");
          }
        } else {
          console.log("Redirecting to login something went wrong in callGroups");
          navigate("/login");
        }
        return;
      }
    }
    return;
  };

  const GroupDisplay = (res) => {
    const groups = res.map((item, index) => (
      <Dropdown.Item
        key={index}
        id={item.id}
        onClick={() => {
          // console.log("Clicked", item.id);
          updateStageGroupName(item.name);
          updateLastKnownGroupID(item.id);
        }}
      >
        {item.name}
      </Dropdown.Item>
    ));
    console.log("groups", groups);
    return groups;
  };

  if (isLoading) {
    return <Dropdown.Item>Loading...</Dropdown.Item>;
  }
  return groupArray
};
export default GroupContainer;
